import Cardimg from 'Images/Book.png'
import { Link } from 'react-router-dom'
import { getImage, NOVEL } from 'utils'
import Image from '../Image'
import { FC } from 'react'
import { ContentDetail } from 'types/Content'

const ContentCardBig: FC<{
  item: ContentDetail
}> = ({ item }) => {
  const link = getImage(item.cover?.url, 'm', NOVEL)

  return (
    <Link to={'/detail/' + item.isA + '/' + item._id}>
      <div className="text-center ContentCardBig">
        <Image
          srcs={link}
          fallback={Cardimg}
          className="ContentCardImage"
          alt={item.name}
        />
        <p className="ContentCardTitle mt-2 mb-0">{item.name}</p>
        <p className="ContentCardAuthor">{item.author.name}</p>
      </div>
    </Link>
  )
}

export default ContentCardBig
