const ProStatCard = ({
  cardTitle,
  cardImg,
  cardValue
}: {
  cardTitle: string
  cardImg: string
  cardValue: string
}) => (
  <div className="proStatCardParent">
    <div className="proStatCard">
      <p className="cardTitle">{cardTitle}: </p>
      <div className="numAndImg">
        <p className="cardValue mb-0">{cardValue}</p>
        <img className="cardImg" src={cardImg} alt={cardTitle} />
      </div>
    </div>
  </div>
)

export default ProStatCard
