import SideSignUp from 'Images/open-dooodle.svg'
import SideLogin from 'Images/open-doodle-login.svg'
import FadeIn from 'react-fade-in'

const SideImage = ({
  page: { page }
}: {
  page: {
    page: string
  }
}) => {
  switch (page) {
    case 'login': {
      return (
        <FadeIn>
          <img
            style={{ height: '100vh' }}
            src={SideLogin}
            alt="React Logo"
            height="100%"
            width="100%"
          />
        </FadeIn>
      )
    }
    case 'signUp': {
      return <SignImg />
    }
    default: {
      return (
        <FadeIn>
          <img
            style={{ height: '100vh' }}
            src={SideLogin}
            alt="React Logo"
            height="100%"
            width="100%"
          />
        </FadeIn>
      )
    }
  }
}

const SignImg = () => (
  <FadeIn>
    <img
      style={{ height: '100vh' }}
      src={SideSignUp}
      alt="React Logo"
      height="100%"
      width="100%"
    />
  </FadeIn>
)

export default SideImage
