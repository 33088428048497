import { useState, useEffect, FC } from 'react'
import Icon from 'Images/LogoU.svg'
import Notif from 'Icons/notifN.svg'
import Gravatar from 'react-gravatar'
import { Link, NavLink } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import account from 'Icons/account.svg'
import library from 'Icons/library.svg'
import logoutImg from 'Icons/logout.svg'
import { Fragment } from 'react'
import {
  getUserByToken,
  logoutUser
} from 'NetworkCall/RepoHandler/userDataService'
import 'Style/mobileNavTop.scss'
import { AxiosResponse } from 'axios'
import { UserDetails } from 'types/User'

const profileSection = (user: UserDetails | null, logoutAction: () => void) => {
  if (user) {
    return (
      <Fragment>
        <Link to="/notifications">
          <img src={Notif} className="pe-3 display-inline" alt="logo" />
        </Link>
        <Gravatar
          className="rounded-circle profile"
          email={user.email}
          id="profileDropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        />
        <div
          className="dropdown-menu"
          aria-labelledby="profileDropdown"
          id="profileDropdown"
        >
          <Link className="dropdown-item" to="/dashboard">
            <img alt="dashboard" src={library} />
            Dashboard
          </Link>
          <Link className="dropdown-item" to="#">
            <img alt="profile" src={account} />
            Profile
          </Link>
          <button className="dropdown-item" onClick={logoutAction}>
            <img alt="logout" src={logoutImg} />
            Logout
          </button>
        </div>
      </Fragment>
    )
  } else {
    return (
      <Link to="/user?page=login" className="upanyas-primary">
        Login
      </Link>
    )
  }
}

const MobileNavTop: FC<{
  title: string
}> = ({ title }) => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    const userToken = localStorage.getItem('auth-token')
    if (userToken) {
      const request = getUserByToken()
        .then((res: AxiosResponse) => {
          setUser(res.data)
        })
        .catch((error: any) => {
          console.log(error)
          request.cancel()
        })
    }
  }, [])

  const logout = () => {
    localStorage.removeItem('auth-token')
    localStorage.removeItem('RfToken')
    setUser(null)
    const request = logoutUser()
      .then(() =>
        window.open(
          window.location.protocol + '//' + window.location.host + '/',
          '_self'
        )
      )
      .catch((error: any) => {
        request.cancel()
        console.log(error)
      })
  }
  return (
    <Fragment>
      <div className="container-fluid NavMain fixed-top mobileNavTop">
        <div className="NavRow display-flex">
          <div className="NavLogo pe-0 text-left">
            <Link to="/">
              <img src={Icon} className="mobileNavIcon" alt="logo" />
            </Link>
            <span className="mobileNavHeading ms-2">{title}</span>
          </div>
          <div className="ms-auto">
            <div className="m-auto NavRow">
              <div className="align-items-center d-flex false float-right h-100">
                {profileSection(user, logout)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid NavMain fixed-bottom mobileNavTop">
        <Row className="NavRow">
          <Col>
            <NavLink
              activeClassName="active"
              className="mobileBottomIcon"
              to="/"
              exact={true}
            >
              <span className="homeIcon"></span>
            </NavLink>
          </Col>
          <Col>
            <NavLink
              activeClassName="active"
              className="mobileBottomIcon"
              to="/writer"
            >
              <span className="pencilIcon"></span>
            </NavLink>
          </Col>
          <Col>
            <NavLink
              activeClassName="active"
              className=" mobileBottomIcon"
              to="/searchbar"
            >
              <span className="searchIcon"></span>
            </NavLink>
          </Col>
          <Col>
            <NavLink
              activeClassName="active"
              className=" mobileBottomIcon"
              to="/dashboard"
            >
              <span className="sideDashboard"></span>
            </NavLink>
          </Col>
          <Col>
            <NavLink
              activeClassName="active"
              className=" mobileBottomIcon"
              to="/library"
            >
              <span className="bookIcon"></span>
            </NavLink>
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}

export default MobileNavTop
