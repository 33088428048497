import { ChangeEvent, useState } from 'react'
import { toast } from 'react-toastify'
import { userLogin as userLoginService } from 'NetworkCall/RepoHandler/userDataService'
import { Button, Input } from 'mtrd'
import FadeIn from 'react-fade-in'
import PropTypes from 'prop-types'
import GoogleButton from '../SocialButton/GoogleButton'
import FacebookButtonLogin from '../SocialButton/FacebookLogin'

const fields = ['email', 'password']

const LoginForm = ({
  urlParam,
  forgotPasswordAction,
  signAction
}: {
  urlParam?: string
  forgotPasswordAction: () => void
  signAction: () => void
}) => {
  const [state, setState] = useState({
    email: '',
    password: '',
    isDisabled: false
  })

  const emailValidation = (email: string) => {
    if (email === '') {
      return false
    }
    const lastAtPos = email.lastIndexOf('@')
    const lastDotPos = email.lastIndexOf('.')
    if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        email.indexOf('@@') === -1 &&
        lastDotPos > 2 &&
        email.length - lastDotPos > 2
      )
    ) {
      return false
    } else {
      return true
    }
  }

  const handleInputChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement
    const value = target.value
    const name = target.name
    setState({
      ...state,
      [name]: value
    })
  }

  const userLogin = (res: any) => {
    localStorage.setItem('auth-token', res.data.token)
    localStorage.setItem('RfToken', res.data.refreshToken)
    if (urlParam) {
      window.close()
    } else {
      window.open(
        window.location.protocol + '//' + window.location.host + '/',
        '_self'
      )
    }
  }

  const onSubmit = () => {
    setState({ ...state, isDisabled: true })
    const { email, password } = state
    if (!emailValidation(email)) {
      toast.warn('Enter a valid email', {
        position: toast.POSITION.TOP_RIGHT
      })
      setState({ ...state, isDisabled: false })
      return
    } else if (password.length < 6) {
      toast.warn('Password is of minmum 6 character', {
        position: toast.POSITION.TOP_RIGHT
      })
      setState({ ...state, isDisabled: false })
      return
    }
    localStorage.clear()
    userLoginService(email, password)
      .then((res: any) => {
        userLogin(res)
        setState({ ...state, isDisabled: false })
      })
      .catch((error: any) => {
        console.log(error)
        toast.error(error.message, {
          position: 'top-right'
        })
        setState({ ...state, isDisabled: false })
        // messageDisplay(error.message,'error');
      })
  }

  const isDisabled = state.isDisabled

  return (
    <FadeIn>
      <div className="text mb-5"> Log in </div>
      <div className="form">
        {fields.map(value => (
          <Input
            className="mb-3"
            key={value}
            name={value}
            type={value}
            id={value}
            placeholder={'Enter ' + value}
            onChange={handleInputChange}
          />
        ))}
        <div className="forget">
          <button className="btn" onClick={forgotPasswordAction}>
            Forget Password?
          </button>
        </div>
        <div>
          <Button
            className="w-100"
            disabled={isDisabled}
            onClick={onSubmit}
            isLoading={isDisabled}
          >
            Log In
          </Button>
        </div>
        <div className="login">
          Don&apos;t have an Account?
          <button
            className="border-0 bg-transparent ms-1"
            style={{ color: '#fa7925', fontWeight: 'bold' }}
            onClick={signAction}
          >
            {' '}
            Sign up
          </button>
        </div>
        <h5 className="text-center text-black-50 mt-2 mb-2">or</h5>
        <div className="d-flex">
          <GoogleButton action="Log in" urlParam={urlParam} />
          <FacebookButtonLogin action="Log in" urlParam={urlParam} />
        </div>
      </div>
    </FadeIn>
  )
}

LoginForm.defaultProps = {
  urlParam: null
}

LoginForm.propTypes = {
  urlParam: PropTypes.string,
  forgotPasswordAction: PropTypes.func,
  signAction: PropTypes.func
}

export default LoginForm
