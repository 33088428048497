import { Link } from 'react-router-dom'
import Ranking from 'Icons/ranking.svg'
import Reads from 'Icons/reads.svg'
import Image from '../Image'
import * as Icons from 'react-feather'
import Cardimg from 'Images/Book.png'
import Language from 'Icons/language.svg'
import { Button } from 'mtrd'
import MediaQuery from 'react-responsive'

const HeroSection = ({
  type,
  state,
  link,
  id,
  libraryAction,
  likedAction
}: {
  type: string
  state: any
  link: any
  id: string
  libraryAction: () => void
  likedAction: () => void
}) => {
  const { name, ranking, readCount, likeCount, author, genre, description } =
    state.data
  return (
    <>
      <div className="NovelContainer">
        <div className="novelType">
          <span>{type}</span>
        </div>
        <div
          className={`d-flex  ${
            window.innerWidth < 992
              ? 'flex-column align-items-center'
              : 'justify-content-between'
          }`}
        >
          <div
            className={`d-flex justify-content-between w-100 ${
              type === 'Series' ? 'flex-row-reverse' : ''
            }`}
          >
            <div className="col-lg-3 col-12 imgcc">
              <div className="ImgContainer">
                <Image
                  srcs={link}
                  fallback={Cardimg}
                  className="NovelImgView"
                  key={link}
                />
              </div>
            </div>
            <MediaQuery minDeviceWidth={992}>
              <div className="NovelContent col-lg-8 col-11 mx-4">
                <p className="languageTag">
                  <img src={Language} alt="language" /> English{' '}
                </p>
                <p className="NovelNameHeading">
                  {name} <span className="ageGroup">PG-13</span>
                </p>
                <div className="NovelAuthorDetail">
                  {author?.name && (
                    <Link to="/" className="NovelAuthorName">
                      {author.name}
                    </Link>
                  )}
                </div>
                <div className="NovelCategoryParent">
                  {genre &&
                    genre.map((value: { name: string }, index: number) => (
                      <span
                        key={index}
                        id={index.toString()}
                        className="NovelCategory"
                      >
                        {value.name}
                      </span>
                    ))}
                </div>
                <div className="statCardsParent">
                  {ranking && (
                    <div className="statCard ranking">
                      <div className="imgHolder">
                        <img src={Ranking} alt="Ranking" />
                      </div>
                      <div className="statTitle">{ranking} Ranking</div>
                    </div>
                  )}
                  <div className="statCard reads">
                    <div className="imgHolder">
                      <img src={Reads} alt="Reads" />
                    </div>
                    <div className="statTitle">{readCount} Reads</div>
                  </div>
                  <div className="statCard likes">
                    <div className="imgHolder">
                      <Icons.Heart fill="#4F4F4F" color="#4F4F4F" />
                    </div>
                    <div className="statTitle">{likeCount} Likes</div>
                  </div>
                </div>
                {type === 'Series' && (
                  <div className="aboutNovel">
                    <p>{description}</p>
                  </div>
                )}

                <div className="buttonContainer">
                  <Button
                    className="NovelStart"
                    href={'/reader/' + type + '/' + id}
                  >
                    Start Reading
                  </Button>
                  {state.isUser && (
                    <Button
                      themeType="outlined"
                      onClick={libraryAction}
                      disabled={state.loading}
                      isLoading={state.loading}
                      className="NovelAdd ms-4"
                    >
                      {state.library.in ? 'Remove from' : 'Add to'} Library
                    </Button>
                  )}
                </div>
              </div>
            </MediaQuery>
          </div>
          <div
            className={`actionBar ${window.innerWidth < 992 ? 'mt-5' : 'me-5'}`}
          >
            {window.innerWidth > 991 && (
              <Image
                srcs={[
                  'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/action-thriller-book-cover-design-template-3675ae3e3ac7ee095fc793ab61b812cc.jpg?ts=1637008457'
                ]}
                fallback={''}
                alt="author"
                className="smallRound"
              />
            )}
            <Button
              themeType="transparent"
              onClick={likedAction}
              disabled={state.loading}
              isLoading={state.loading}
              className="actionBtn"
            >
              <Icons.Heart color="#D34949" />
            </Button>
            <Button
              themeType="transparent"
              onClick={() => {}}
              disabled={state.loading}
              isLoading={state.loading}
              className="actionBtn"
            >
              <Icons.Star color="#F8AC30" />
            </Button>
            <Button
              themeType="transparent"
              disabled={state.loading}
              isLoading={state.loading}
              className="actionBtn"
            >
              <Icons.MessageCircle color="#495FD3" />
            </Button>
            <Button
              themeType="transparent"
              disabled={state.loading}
              isLoading={state.loading}
              className="actionBtn"
            >
              <Icons.Share2 color="#D34949" />
            </Button>
          </div>
        </div>
      </div>
      <MediaQuery minDeviceWidth={0} maxDeviceWidth={991}>
        <div className="NovelContent col-lg-8 col-11 mx-2">
          <p className="languageTag">
            <img src={Language} alt="language" /> English{' '}
          </p>
          <p className="NovelNameHeading">
            {name} <span className="ageGroup">PG-13</span>
          </p>
          <div className="NovelAuthorDetail">
            {author?.name && (
              <Link to="/" className="NovelAuthorName">
                {author.name}
              </Link>
            )}
          </div>
          <div className="NovelCategoryParent">
            {genre &&
              genre.map((value: { name: string }, index: number) => (
                <span
                  key={index}
                  id={index.toString()}
                  className="NovelCategory"
                >
                  {value.name}
                </span>
              ))}
          </div>
          <div className="statCardsParent">
            {ranking && (
              <div className="statCard ranking">
                <div className="imgHolder">
                  <img src={Ranking} alt="Ranking" />
                </div>
                <div className="statTitle">{ranking} Ranking</div>
              </div>
            )}
            <div className="statCard reads">
              <div className="imgHolder">
                <img src={Reads} alt="Reads" />
              </div>
              <div className="statTitle">{readCount} Reads</div>
            </div>
            <div className="statCard likes">
              <div className="imgHolder">
                <Icons.Heart fill="#4F4F4F" color="#4F4F4F" />
              </div>
              <div className="statTitle">{likeCount} Likes</div>
            </div>
          </div>
          {type === 'Series' && (
            <div className="aboutNovel">
              <p>{description}</p>
            </div>
          )}
        </div>
      </MediaQuery>
    </>
  )
}

export default HeroSection
