import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  getLibrary,
  lastread
} from 'NetworkCall/RepoHandler/LibraryDataService'
import { Row, Col } from 'reactstrap'
import Cardimg from 'Images/Book.png'
import Quicklibary from '../QuickLibrary'
import ProgressBar from '../Common/ProgressBar'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { getImage, NOVEL } from 'utils'
import Image from '../Image'
import 'Style/customComp.scss'

const GoupingBinding = ({
  compoent,
  section,
  index
}: {
  compoent: (sec: any, index: number) => any
  section: any
  index: number
}) => {
  const [lastRead, setLastRead] = useState<any>([])
  const [books, setBooks] = useState([])
  const [imageCover, setImageCover] = useState([Cardimg])

  useEffect(() => {
    if (localStorage.getItem('auth-token')) {
      const request = getLibrary()
        .then((librarybook: any) =>
          lastread()
            .then((res: any) => {
              const data = mapingDataSet(librarybook)
              const value = mapingLastRead(res)
              if (data.length > 0) {
                setBooks(data)
                setLastRead(value)
              }
            })
            .catch(console.log)
        )
        .catch(console.log)
      return () => request.cancel()
    }
  }, [])

  const mapingDataSet = ({ data }: { data: any }) =>
    data
      .filter(({ id }: { id: any }) => !isEmpty(id))
      .map(({ id }: { id: any }) => id)

  const mapingLastRead = ({ data }: { data: any }) =>
    data
      .filter(({ id }: { id: any }) => !isEmpty(id))
      .map(({ currentProgress, id }: { currentProgress: any; id: any }) => ({
        Progress: currentProgress,
        value: id
      }))

  useEffect(() => {
    if (!isEmpty(lastRead) && lastRead?.[0].value?.cover) {
      const cover = getImage(
        lastRead[0].value?.cover?.url || lastRead[0].value?.cover,
        's',
        NOVEL
      )
      setImageCover(cover)
    }
  }, [lastRead])

  return (
    <div className="container m-auto w-100 h-100">
      <Row>
        <Col xs="12" lg={books.length > 0 ? '6' : '12'}>
          {compoent(section, index)}
        </Col>
        {books.length <= 0 ? (
          <></>
        ) : (
          <Col xs="12" lg="6">
            {lastRead.length > 0 ? (
              <Row style={{ padding: '15px' }}>
                <Col xs="12">
                  <h3
                    className="font-weight-bold"
                    style={{ marginTop: '24px' }}
                  >
                    Continue Reading
                  </h3>
                </Col>
                <Col xs="12" style={{ width: '100%', marginTop: '10px' }}>
                  <Col className="mb-4">
                    <div className="LibCard d-flex h-100 align-items-stretch">
                      <div
                        className="cover-holder me-3"
                        style={{ position: 'relative' }}
                      >
                        <Image
                          srcs={imageCover}
                          fallback={Cardimg}
                          className="align-self-center LibImg"
                        />
                      </div>
                      <div className="media-body w-100 d-flex flex-column justify-content-between">
                        <div>
                          <p className="LibTitle">{lastRead[0].value.name}</p>
                          <p className="LibAuthor">
                            {lastRead[0].value.author.name}
                          </p>
                        </div>

                        <div>
                          <div className="display-flex mb-2">
                            <ProgressBar
                              completed={Math.ceil(lastRead[0].Progress)}
                            />
                            <div className="RowBar">
                              {`${Math.ceil(lastRead[0].Progress)}%`}
                            </div>
                          </div>
                          <div className="button-container d-flex">
                            <Link
                              to={
                                'reader/' +
                                lastRead[0].value.isA +
                                '/' +
                                lastRead[0].value._id
                              }
                              className="upanyas-secondary"
                            >
                              Resume Reading
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row className="libraryMobile">
              <Col xs="12">
                <h3
                  className="font-weight-bold"
                  style={{ marginTop: '10px', marginLeft: '10px' }}
                >
                  My Library
                </h3>
              </Col>
              <Col
                className="homepagelibboxparent"
                xs="12"
                style={{ marginTop: '10px', marginRight: '35px' }}
              >
                <Quicklibary books={books} />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  )
}

GoupingBinding.propTypes = {
  compoent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  section: PropTypes.object,
  index: PropTypes.number
}

export default GoupingBinding
