import { Route, Redirect, RouteComponentProps } from 'react-router-dom'

const PublicRoute = ({
  component: Component,
  ...rest
}: {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
}) => {
  const isLoggedIn = () => localStorage.getItem('auth-token')

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn() ? <Component {...props} /> : <Redirect to="/user" />
      }
    />
  )
}

export default PublicRoute
