import React from 'react'
import Icon from 'Images/logoWtext.svg'
import { Link, useLocation } from 'react-router-dom'
import LoginForm from 'components/Auth/LoginForm'
import ForgotForm from 'components/Auth/ForgotPassword'
import SignUpForm from 'components/Auth/SignUpForm'
import SideImage from 'components/Auth/SideImage'
import { Col } from 'reactstrap'
import PropTypes from 'prop-types'
function UserPage(Component) {
  return function WrappedComponent(props) {
    const query = new URLSearchParams(useLocation().search)
    return <Component {...props} query={query} />
  }
}

const Forms = ({
  page,
  loginAction,
  signAction,
  ForgotPasswordPage,
  urlParam
}) => {
  switch (page) {
    case 'login': {
      return (
        <LoginForm
          signAction={signAction}
          urlParam={urlParam}
          forgotPasswordAction={ForgotPasswordPage}
        />
      )
    }
    case 'signup': {
      return <SignUpForm navigtionAction={loginAction} urlParam={urlParam} />
    }
    default: {
      return <ForgotForm navigtionAction={signAction} />
    }
  }
}
const ImageLoading = page => {
  return <SideImage page={page} />
}

class Login extends React.Component {
  constructor(props) {
    super(props)
    let query = this.props.query
    let Page = query.get('page')
    if (Page !== null) {
      // console.log(Page);
      this.state = {
        PageType: Page
      }
    } else {
      this.state = {
        PageType: 'login'
      }
    }
    this.openSignUpPage = this.openSignUpPage.bind(this)
    this.openLoginPage = this.openLoginPage.bind(this)
    this.openForgotPasswordPage = this.openForgotPasswordPage.bind(this)
    this.urlParam = this.getURLParam()
  }

  getURLParam() {
    var url_string = window.location.href
    var url = new URL(url_string)
    return url.searchParams.get('win')
  }

  openSignUpPage() {
    this.setState({ PageType: 'signup' })
  }
  openLoginPage() {
    this.setState({ PageType: 'login' })
  }
  openForgotPasswordPage() {
    this.setState({ PageType: 'Forgot' })
  }

  render() {
    const { PageType } = this.state

    return (
      <div className="row">
        <div
          className="col-xs-10 col-12 col-lg-7 left"
          style={{ overflow: 'hidden' }}
        >
          <div className="logo">
            <Link to="/">
              <img src={Icon} alt="" />
            </Link>
          </div>

          <div className="row">
            <Col xs="12" xl="7" lg="10" className="m-auto formcol">
              <Forms
                page={PageType}
                urlParam={this.urlParam}
                loginAction={this.openLoginPage}
                signAction={this.openSignUpPage}
                ForgotPasswordPage={this.openForgotPasswordPage}
              />
            </Col>
          </div>
        </div>
        <div className="col-xs-10 col-sm-5">
          <div className="SideLogo">
            <ImageLoading page={this.state.PageType} />
          </div>
        </div>
      </div>
    )
  }
}

Login.propTypes = {
  query: PropTypes.object
}

Forms.propTypes = {
  page: PropTypes.string,
  loginAction: PropTypes.func,
  signAction: PropTypes.func,
  urlParam: PropTypes.string,
  query: PropTypes.object,
  ForgotPasswordPage: PropTypes.func
}

export default UserPage(Login)
