import './SeriesComponent.scss'
import Image from '../Image'
import * as Icon from 'react-feather'
import Reads from 'Icons/reads.svg'
import { Button } from 'mtrd'

const Episode = () => (
  <div className="d-flex p-5 episode-container">
    <div className="me-4">
      <Image
        srcs={[
          'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/action-thriller-book-cover-design-template-3675ae3e3ac7ee095fc793ab61b812cc.jpg?ts=1637008457'
        ]}
        fallback={''}
        alt="novelImage"
        className="SeriesCardImg"
      />
    </div>
    <div className="col-9 d-flex flex-column justify-content-around">
      <div className="episode-num">Episode 1</div>
      <h3 className="episode-heading">Thick as Theives</h3>
      <p className="episode-description">
        Harry Potter is a fantasy novel written by British author J. K. Rowling.
        The first novel in the Harry Potter series and Rowling&apos;s debut
        novel, it follows Harry Potter, a young wizard who discovers his magical
        heritage on his eleventh birthday, when he receives a letter of
        acceptance to Hogwarts School of Witchcraft and Wizardry.
      </p>
      <div className="stats">
        <p>
          <img src={Reads} alt="Reads" /> &nbsp; &nbsp;{50} Reads
        </p>
        <p>
          <Icon.Star fill="#A4A4A4" color="#A4A4A4" size={15} /> &nbsp; &nbsp;
          {4} Ratings
        </p>
      </div>
      <div className="buttonContainer">
        <Button className="ReadBtn" href={'/'}>
          Read
        </Button>
        <Button
          themeType="secondary"
          onClick={() => {}}
          disabled={false}
          isLoading={false}
          className="ms-4"
        >
          Add to Library
        </Button>
      </div>
    </div>
  </div>
)

const SeriesComponent = () => (
  <div className="mt-5">
    <h3 className="bigHeading">In This Series</h3>
    <div>
      <Episode />
      <Episode />
      <Episode />
    </div>
    <div className="d-flex justify-content-center mt-5">
      <Button
        themeType="secondary"
        onClick={() => {}}
        disabled={false}
        isLoading={false}
        className="showMoreBtn"
      >
        Show more
      </Button>
    </div>
  </div>
)

export default SeriesComponent
