import { Fragment } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Row, Col } from 'reactstrap'

const LoaderComponet = () => {
  const dumpList = [1, 2, 3, 4, 5, 6, 7, 8]
  return (
    <Fragment>
      <div
        className="container-fluid"
        style={{
          marginBottom: '12rem',
          paddingLeft: '1rem',
          paddingRight: '1rem'
        }}
      >
        <Row>
          <Col xs={9} sm={9}>
            <Skeleton height={25} width={170} />
          </Col>
        </Row>
        <Row className="skeletion">
          {dumpList.map((_, index) => (
            <Col
              xl={3}
              lg={3}
              md={6}
              xs={12}
              style={{ marginTop: '2rem' }}
              key={index}
            >
              <Row>
                <Skeleton height={190} width={150} />
              </Row>
              <Row>
                <Skeleton height={20} width={150} />
              </Row>
              <Row style={{ paddingLeft: '0.4rem' }}>
                <Skeleton height={20} width={80} />
              </Row>
            </Col>
          ))}
        </Row>
      </div>
    </Fragment>
  )
}

export default LoaderComponet
