import RequestHandler from '../Connetion/RequestBulider'
import endpoint from '../Connetion/endpoints'
import { Promise } from 'bluebird'
import { rejectResolveNetwork } from './commonDataService'

export const getLibrary = () => {
  let request = RequestHandler.getRequest(
    endpoint.getLibrary,
    null,
    RequestHandler.getMethod.get,
    true
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const lastread = () => {
  let request = RequestHandler.getRequest(
    endpoint.getLastRead,
    null,
    RequestHandler.getMethod.get,
    true
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const addTolibary = (id, type) => {
  var data = {
    isA: type
  }
  data = { ...data, contentID: id }
  let request = RequestHandler.postRequest(
    endpoint.addToLibrary,
    data,
    RequestHandler.postMethod.post,
    false
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const removeToLibary = id => {
  let request = RequestHandler.getRequestById(
    endpoint.removeFromLibrary,
    id,
    null,
    RequestHandler.getMethod.delete,
    true
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const updateProgress = (id, currentProgrss, overall) => {
  let data = {
    currentProgress: currentProgrss,
    overallProgress: overall
  }
  let request = RequestHandler.PostRequestById(
    endpoint.updateProgerss,
    id,
    data,
    RequestHandler.postMethod.patch,
    null
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}
