import { Row, Col, Container } from 'reactstrap'
import ContentCardBig from '../Common/ContentCardBig'
import ContentCard from '../Common/ContentCard'
import ShowMore from '../Common/ShowMore'

const CardGroup = ({
  backgroundColor,
  heading,
  list,
  more
}: {
  backgroundColor: string
  heading: string
  list: any[]
  more: any
}) => {
  if (list.length !== 0) {
    const newList = list.filter((_, index) => index !== 0 && index < 9)
    return (
      <div className="container-fluid" style={{ padding: '2rem 0' }}>
        <Container>
          <ShowMore heading={heading} link={more?.link} text={more?.text} />
        </Container>
        <div style={{ backgroundColor }}>
          <Container className="m-auto">
            <Row>
              <Col sm="12" lg="4" md="6" className="ContentCardBigParent">
                <ContentCardBig item={list[0]} />
              </Col>
              <Col
                sm="12"
                lg="8"
                md="6"
                style={{
                  paddingTop: '2rem',
                  paddingLeft: '2rem',
                  paddingRight: '2rem'
                }}
              >
                <Row>
                  {newList.map((item, index) => (
                    <Col
                      className="mb-4 ContentCardCol"
                      lg="4"
                      xl="3"
                      md="6"
                      sm="4"
                      xs="6"
                      key={index}
                    >
                      <ContentCard item={item} />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  }
  return <h3 className="text">No Data Found</h3>
}

export default CardGroup
