import { linkbulder } from './ImageLinkBuilder'
import defaultCategoryImage from 'Images/CAMPING.jpg'
import defaultNovelImage from 'Images/Book.png'
import { isEmpty, upperFirst } from 'lodash'
import moment from 'moment'
import { toast } from 'react-toastify'
import { nanoid } from 'nanoid'

export const getImage = (url, size, type, format) => {
  const defaultImage = {
    category: defaultCategoryImage,
    novel: defaultNovelImage
  }

  if (url && url !== 'nil') {
    const imagesWithFormats = linkbulder(size, url)

    if (format) {
      return imagesWithFormats.find(image => image.split('.').pop() === format)
    }
    return imagesWithFormats
  }
  return defaultImage[type]
}

export const DEFAULT_DATETIME = 'MMMM Do YYYY, h:mm:ss a'

export const NOVEL = 'novel'

export const STORY = 'story'

export const POEM = 'poem'

export const contentTypes = [NOVEL, STORY, POEM]

export const stripHTML = string => string.replace(/<[^>]*>?/gm, '')

export const writeupId = (result, wtType) => {
  switch (wtType) {
    case STORY:
      return result.storyID
    case POEM:
      return result.poemID
    case NOVEL:
      return result.novelID
    default:
      break
  }
}

export const getNewName = type =>
  `${upperFirst(type)}-${moment().format(DEFAULT_DATETIME)}`

export const toastWarn = (message, position = 'top-right') =>
  toast.warn(message, { position })

export const isContentEmpty = content =>
  isEmpty(content) || isEmpty(stripHTML(content).trim())

export const plural = type => {
  switch (type) {
    case STORY:
      return 'Stories'
    case POEM:
      return 'Poems'
    case NOVEL:
      return 'Novels'
    default:
      break
  }
}

export const typeName = type => {
  switch (type) {
    case POEM:
      return 'Poem'
    case NOVEL:
      return 'Novel'
    case STORY:
      return 'Story'
    default:
      window.history.push('/writer')
  }
}

export const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ''

export const getActiveChapterContent = (chapters, activeChapterID) =>
  chapters.find(({ chapter: { chapterID } }) => chapterID === activeChapterID)
    .chapter.content

export const newChapter = index => ({
  chapter: {
    chapterID: `new_${nanoid()}`,
    name: 'Untitled Chapter',
    content: '',
    wordCount: 0,
    isPublished: false,
    createdAt: moment().format(),
    updatedAt: moment().format()
  },
  index,
  addedAt: moment().format()
})

export const newNovelData = () => ({
  name: getNewName(NOVEL),
  chapters: [{ ...newChapter(1) }],
  chapterCount: 1,
  createdAt: moment().format(),
  updatedAt: moment().format(),
  isPublished: false
})

export const toolbarConfig = {
  display: [
    'BLOCK_TYPE_DROPDOWN',
    'INLINE_STYLE_BUTTONS',
    'BLOCK_ALIGNMENT_BUTTONS'
  ],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' }
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' }
  ],
  BLOCK_ALIGNMENT_BUTTONS: [
    { label: 'Align Left', style: 'ALIGN_LEFT' },
    { label: 'Align Center', style: 'ALIGN_CENTER' },
    { label: 'Align Right', style: 'ALIGN_RIGHT' },
    { label: 'Align Justify', style: 'ALIGN_JUSTIFY' }
  ]
}

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
