import { useEffect, useReducer } from 'react'
import Icon from 'Images/logoWtext.svg'
import SideLogo from 'Images/open-doodle-login.svg'
import { useHistory, useParams } from 'react-router-dom'
import {
  restPassword,
  verifyRestPasswordToken
} from 'NetworkCall/RepoHandler/userDataService'
import { toast } from 'react-toastify'
import { Button, Input } from 'mtrd'

function reducer(state, action) {
  switch (action.type) {
    case 'setPassword':
      return { ...state, password: action.value }
    case 'setConfirmPassword':
      return { ...state, confirmPassword: action.value }
    case 'isDisabled':
      return { ...state, isDisabled: action.value }
    default:
      throw new Error(`${action.type} is not a valid action`)
  }
}

const RestPassword = () => {
  let history = useHistory()
  let { id } = useParams()
  const [{ password, confirmPassword, isDisabled }, dispatch] = useReducer(
    reducer,
    { password: '', Confirmpassword: '', isDisabled: false }
  )

  useEffect(() => {
    verifyRestPasswordToken(id)
      .then(result => {
        let verify = result.error
        if (verify) {
          toast.error(result.data.message, toast.POSITION.TOP_RIGHT)
          history.push('/')
        }
      })
      .catch(error => {
        toast.error(error.message, toast.POSITION.TOP_RIGHT)
        history.push('/')
      })
  })

  const OnSubmit = () => {
    if (password.length >= 6) {
      if (password === confirmPassword) {
        dataDidfetch()
      } else {
        toast.warn(
          "password and confirm password didn't match",
          toast.POSITION.TOP_RIGHT
        )
      }
    } else {
      toast.warn(
        'enter the password of at least of 6 char',
        toast.POSITION.TOP_RIGHT
      )
    }
  }

  const dataDidfetch = () => {
    dispatch({ type: 'isDisabled', value: true })
    restPassword(id, password)
      .then(() => {
        toast.success('Password succefully changed', toast.POSITION.TOP_RIGHT)
        dispatch({ type: 'isDisabled', value: false })
        setInterval(openPage('/user?page=login'), 1000)
      })
      .catch(error => {
        toast.error(error.message, toast.POSITION.TOP_RIGHT)
        dispatch({ type: 'isDisabled', value: false })
        setInterval(openPage('/user?page=forgot'), 1000)
      })
  }

  const openPage = path => {
    history.push(path)
  }

  return (
    <div className="row no-gutters">
      <div className="col-xs-10 col-sm-7 left">
        <div className="logo">
          <img src={Icon} alt="" />
        </div>
        <div className="block">
          <div className="text">Enter New Password</div>
          <div className="form">
            <div>
              <Input
                key={'password'}
                name={'Password'}
                type={'password'}
                id={'password'}
                placeholder={'New Password'}
                onChange={e => {
                  dispatch({ type: 'setPassword', value: e.target.value })
                }}
              />
              <Input
                key={'confirmPassword'}
                name={'confirmPassword'}
                type={'password'}
                id={'confirmPassword'}
                placeholder={'Confirm Password'}
                onChange={e => {
                  dispatch({
                    type: 'setConfirmPassword',
                    value: e.target.value
                  })
                }}
              />
              <Button
                className="w-100"
                isLoading={isDisabled}
                onClick={OnSubmit}
              >
                Change Password
              </Button>
            </div>
            <p style={{ marginTop: '20px' }}>
              Guess What, You can change password from User Settings anytime if
              you want
            </p>
          </div>
        </div>
      </div>
      <div className="col-xs-10 col-sm-5">
        <div className="SideLogo">
          <img src={SideLogo} alt="React Logo" height="100%" width="100%" />
        </div>
      </div>
    </div>
  )
}

export default RestPassword
