import { createContext, FC, useEffect, useState } from 'react'
import { getUserByToken } from 'NetworkCall/RepoHandler/userDataService'
import { UserDetails } from 'types/User'

export const UserContext = createContext(null)

export const UserDetailsContext: FC = ({ children }) => {
  const [user, setUser] = useState<any>(null)
  useEffect(() => {
    const loadUserDetails = () => {
      if (localStorage.getItem('auth-token')) {
        getUserByToken()
          .then(({ data }: { data: UserDetails }) => setUser(data))
          .catch(console.log)
      } else setUser(null)
    }

    if (!user) loadUserDetails()
  }, [user])

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}
