/** @jsxImportSource @emotion/react */
import { Dispatch, FC, SetStateAction } from 'react'
import { css } from '@emotion/react'
import { findIndex } from 'lodash'

interface TabsProps {
  tabs: {
    id: string
    label: string
    value: string
  }[]
  tabClassName: string
  width: number
  activeTab: { id: string; label: string; value: string }
  setActiveTab: Dispatch<
    SetStateAction<{ id: string; label: string; value: string }>
  >
}

const Tabs: FC<TabsProps> = ({
  tabs,
  tabClassName,
  width = 500,
  activeTab,
  setActiveTab
}) => (
  <div
    className={`mtrd-tabs ${tabClassName}`}
    css={css`
      &:after {
        left: calc(
          ${findIndex(tabs, { id: activeTab.id }) *
            ((width - 16) / tabs.length)}px + 8px
        );
        width: ${(width - 16) / tabs.length}px;
      }
    `}
    style={{
      gridTemplateColumns: `repeat(${tabs.length}, 1fr)`,
      maxWidth: `${width}px`
    }}
  >
    {tabs.map(tab => (
      <div
        key={tab.id}
        className={`tab-item${tab.id === activeTab.id ? ' active' : ''}`}
        onClick={() => setActiveTab(tab)}
      >
        {tab.label}
      </div>
    ))}
  </div>
)

export default Tabs
