import {
  ComponentProps,
  Fragment,
  FunctionComponent,
  useEffect,
  useRef,
  useState
} from 'react'

interface SelectProps extends ComponentProps<'select'> {
  label: string
  name: string
  options: {
    label: string
    value: string
    type?: 'option' | 'heading'
  }[]
  value: string
  maxHeight?: string
  setFieldValue: (value: any) => void
}

const Select: FunctionComponent<SelectProps> = ({
  label,
  value,
  name,
  placeholder,
  options,
  className,
  maxHeight,
  setFieldValue
}) => {
  const [selected, setSelected] = useState<string>(value)
  const [isOpen, setIsOpen] = useState(false)
  const selectItemsRef = useRef<HTMLDivElement>(null)
  const selectRef = useRef<HTMLDivElement>(null)

  const getSelectedLabel = (selectedOption: string) =>
    options.find(
      ({ value }) => value.toLowerCase() === selectedOption.toLowerCase()
    )?.label

  useEffect(() => {
    if (value) setSelected(value)
  }, [value])

  useEffect(() => {
    const documentHeight = document.documentElement.scrollHeight

    if (isOpen) {
      if (selectItemsRef.current) {
        const scrollTop =
          window.scrollY + selectItemsRef.current.getBoundingClientRect().top
        const selectItemsHeight = selectItemsRef.current.clientHeight
        const selectItemsBottomDistance = selectItemsHeight + scrollTop
        if (selectItemsBottomDistance > documentHeight - 20) {
          if (selectItemsRef.current.clientHeight <= scrollTop) {
            selectItemsRef.current.style.top = '-480px'
          } else {
            selectItemsRef.current.style.maxHeight = `${
              scrollTop - selectItemsRef.current.clientHeight - 20
            }px`
          }
        }
      }
    }
  }, [isOpen])

  useEffect(() => {
    document.addEventListener('click', e => {
      if (
        selectItemsRef.current &&
        !selectItemsRef.current.contains(e.target as Node) &&
        selectRef.current &&
        !selectRef.current.contains(e.target as Node)
      ) {
        setIsOpen(false)
      }
    })
  }, [])

  return (
    <Fragment>
      <div className={`mtrd-select ${className}`} ref={selectRef}>
        {label && (
          <label className="mtrd-input-label mb-2" htmlFor={name}>
            {label}
          </label>
        )}
        <div
          className={`mtrd-select-placeholder mtrd-input d-flex align-items-center ${
            isOpen ? 'open' : 'close'
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {value ? getSelectedLabel(value) : placeholder}
        </div>

        <div
          ref={selectItemsRef}
          className={`mtrd-select-items ${isOpen ? 'show' : 'hide'}`}
          style={{
            maxHeight: `${maxHeight || '500'}px`
          }}
        >
          {options.map(({ label, value, type }) =>
            type === 'heading' ? (
              <div className="mtrd-select-option-heading">{label}</div>
            ) : (
              <div
                key={value}
                className={`mtrd-select-item${
                  selected === value ? ' selected' : ''
                }`}
                onClick={() => {
                  setSelected(value)
                  setFieldValue(value)
                  setIsOpen(false)
                }}
              >
                {label}
              </div>
            )
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default Select
