import { FC } from 'react'
import { Link } from 'react-router-dom'
import Keep from 'Icons/footer.svg'
import 'Style/footer.scss'

const Footer: FC = () => (
  <div className="main">
    <div className="Footer container m-auto">
      <div className="fimg">
        <img src={Keep} alt="footer text" style={{ maxWidth: '320px' }} />
      </div>
      <div className="menu">
        <li className="list1">
          <Link className="Link1" to="/">
            Blog
          </Link>
          <Link className="Link1" to="/">
            Contact
          </Link>
          <Link className="Link1" to="/">
            About
          </Link>
          <Link className="Link1" to="/">
            Team
          </Link>
        </li>
      </div>
    </div>
    <div className="year">© 2020 Upayas</div>
  </div>
)

export default Footer
