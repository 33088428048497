import endpoint from '../Connetion/endpoints'
import axios from 'axios'
import HostName from './config'
const hostUrl = HostName.API_URL
var maxretry = 4

const postMethod = {
  post: 'post',
  patch: 'patch',
  put: 'put'
}

const getMethod = {
  get: 'get',
  delete: 'delete'
}

const PostRequestById = (path, id, data, method, form) => {
  let newEndpoint = path.replace('{id}', id)
  return postRequest(newEndpoint, data, method, form)
}

const postRequest = (path, data, method, form) => {
  const authToken = localStorage.getItem('auth-token')
  var headers = { 'Access-Control-Allow-Origin': '*', 'auth-token': authToken }
  if (authToken) {
    if (form) headers = { ...headers, 'Content-Type': 'multipart/form-data' }
    else {
      headers = { ...headers, 'Content-Type': 'application/json' }
      data = JSON.stringify(data)
    }
  }
  const HttpPost = {
    method,
    url: hostUrl + path,
    headers,
    data
  }
  return HttpPost
}

const getRequestById = (path, id, query, method, auth) => {
  let newEndpoint = path.replace('{id}', id)
  return getRequest(newEndpoint, query, method, auth)
}

const getRequest = (path, query, method, auth) => {
  let headers = {
    'Content-Type': 'application/json'
  }
  const authToken = localStorage.getItem('auth-token')
  var url = hostUrl + path
  if (auth) {
    headers = {
      'auth-token': authToken,
      'Content-Type': 'application/json'
    }
  }
  if (query) {
    url = url + '?'
    var queryPart = ''
    query.forEach(element => {
      if (queryPart === '') queryPart = element[0] + '=' + element[1]
      else queryPart += '&' + element[0] + '=' + element[1]
    })
    url = url + queryPart
  }
  const HttpGet = {
    method,
    url,
    headers
  }
  return HttpGet
}

if (localStorage.getItem('auth-token')) {
  axios.interceptors.response.use(
    respones => {
      return respones
    },
    function (error) {
      const originalRequest = error.config
      let refreshToken = localStorage.getItem('RfToken')

      if (
        refreshToken &&
        error.response.status === 401 &&
        !originalRequest._retry &&
        maxretry
      ) {
        maxretry -= 1
        originalRequest._retry = true
        localStorage.removeItem('auth-token')
        return axios(
          postRequest(
            endpoint.refreshToken,
            { refreshToken },
            postMethod.post,
            false
          )
        )
          .then(res => {
            console.log(res.data)
            if (res.status === 200) {
              localStorage.setItem('auth-token', res.data.data.accessToken)
              console.log('Access token refreshed!')
              maxretry = 4
              return axios(originalRequest)
            }
          })
          .catch(error => {
            maxretry = 4
            return Promise.reject(error)
          })
      }
      return Promise.reject(error)
    }
  )
}

const RequestBuilder = {
  postRequest,
  getRequest,
  PostRequestById,
  getRequestById,
  postMethod,
  getMethod
}

export default RequestBuilder
