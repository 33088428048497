import { FC } from 'react'
import Gravatar from 'react-gravatar'
import { Link } from 'react-router-dom'
import { ChevronDown, ChevronRight } from 'react-feather'
import { UserDetails } from 'types/User'

interface ProfileDropdownProps {
  value: UserDetails
  logoutAction: () => void
}

const ProfileDropdown: FC<ProfileDropdownProps> = ({ value, logoutAction }) => {
  return (
    <>
      <div
        className="nav-profile-dropdown d-flex align-items-center ms-4"
        id="profileDropdown"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <Gravatar className="profile rounded-circle" email={value.email} />
        <p className="mb-0 ms-2 fw-500">{value.firstName}</p>
        <ChevronDown size={18} className="ms-3" />
      </div>
      <div
        className="dropdown-menu p-0 nav-dropdown mt-2"
        aria-labelledby="profileDropdown"
      >
        <Link to={'/profile'}>
          <div className="d-flex align-items-center justify-content-between navbar-dropdown m-2 p-2">
            <div className="user-name-and-mail d-flex align-items-center">
              <Gravatar
                className="profile rounded-circle me-3"
                email={value.email}
              />
              <div>
                <div className="user-name fw-bold">
                  {value.firstName} {value.lastName}
                </div>
                <div className="user-email">{value.email}</div>
              </div>
            </div>
            <ChevronRight size={'20px'} />
          </div>
        </Link>
        <Link className="dropdown-item fw-500" to="/dashboard">
          Dashboard
        </Link>
        <Link className="dropdown-item fw-500" to="/writer">
          Writer
        </Link>
        <Link className="dropdown-item fw-500" to="/library">
          Library
        </Link>
        <Link className="dropdown-item fw-500" to="/">
          Favourites
        </Link>
        <Link className="dropdown-item fw-500" to="/settings">
          Settings
        </Link>
        <button
          className="dropdown-item text-uppercase mt-3 fw-bold w-100 text-start logout-item border-0 py-3"
          onClick={logoutAction}
        >
          Logout
        </button>
      </div>
    </>
  )
}

export default ProfileDropdown
