import { FC, Fragment } from 'react'
import { Link } from 'react-router-dom'
import Cardimg from 'Images/Book.png'
import Image from '../Image'
import UpanEx from 'Icons/upan-ex.svg'
import UpanExNo from 'Images/upanexno.png'
import { getImage, NOVEL } from 'utils'
import { ContentDetail } from 'types/Content'
import 'Style/ContentCard.scss'

const ContentCard: FC<{
  item: ContentDetail
}> = ({ item }) => {
  const link = getImage(item.cover?.url, 's', NOVEL)
  item.exclusive = 0

  return (
    <Fragment>
      <Link className="d-block" to={'/detail/' + item.isA + '/' + item._id}>
        <div className="w-100 ContentCard">
          <div
            className="m-auto"
            style={{
              maxWidth: '170px'
            }}
          >
            {item.exclusive ? (
              <div
                className="upanExParent"
                style={{
                  display: 'block'
                }}
              >
                <img src={UpanEx} alt="U" />
                <span className="upanExTag">EXCLUSIVE</span>
              </div>
            ) : (
              <div className="upanExParent">
                <img src={UpanExNo} height="24px" alt="U" />
              </div>
            )}
            <Image
              srcs={link}
              fallback={Cardimg}
              className="ContentCardImage"
              alt={item.name}
              style={{ backgroundColor: 'white' }}
            />
            <p className="ContentCardTitle mt-2 mb-0" title={item.name}>
              {item.name}
            </p>
            <p className="ContentCardAuthor" title={item.author.firstName}>
              {item.author.firstName + ' ' + item.author.lastName}
            </p>
          </div>
        </div>
      </Link>
    </Fragment>
  )
}

export default ContentCard
