import { useRef } from 'react'
import { Button } from 'mtrd'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Image from '../Image'
import * as Icon from 'react-feather'
import { useHistory } from 'react-router-dom'
import { ReviewSectionProps } from 'types/ReviewSection'

const ReviewSection = ({
  userToken,
  state,
  handleInputChange,
  addComment,
  comments,
  placeholder
}: ReviewSectionProps) => {
  const commentInput = useRef<HTMLTextAreaElement>(null)
  const history = useHistory()
  return (
    <Fragment>
      <div className="NovelReview">
        <h2>Write a Review</h2>
      </div>
      <div className="commentContainer mt-3 mb-5">
        <div className="commentInputContainer">
          <textarea
            className="commentInput"
            name="comment"
            value={state.comment || ''}
            placeholder="Write a Review..."
            onChange={handleInputChange}
            autoComplete="off"
            onKeyDown={e => e.key === 'Enter' && addComment()}
            ref={commentInput}
          ></textarea>
          <Button
            onClick={addComment}
            disabled={state.commentloading}
            isLoading={state.commentloading}
            themeType={'primary'}
            className="commentBtn"
          >
            <Icon.Send />
          </Button>
          {userToken === null && (
            <div className="LoginToWrite d-flex align-items-center justify-content-center">
              <h3>
                <Link to="/user?page=login">Login</Link> to Write a Review
              </h3>
            </div>
          )}
        </div>
      </div>
      {comments && comments.length > 0 ? (
        <div className="NovelReviewSection">
          {comments &&
            comments.map((comment: any, index: number) => (
              <div key={index} className="NovelReviewUser align-items-center">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <Image
                      srcs={[
                        comment?.commentedBy?.profileImage?.url + '_s.jpeg'
                      ]}
                      fallback={placeholder}
                      alt="author"
                      className="NovelAuthor me-3"
                    />
                    <div>
                      <h5 className="font-weight-bold mt-0">
                        {comment.commentedBy.name}
                      </h5>
                      <p className="mb-0">@Username</p>
                    </div>
                  </div>
                  <div>
                    <Button
                      disabled={state.commentloading}
                      isLoading={state.commentloading}
                      themeType={'transparent'}
                      className="moreBtn"
                    >
                      <Icon.MoreVertical />
                    </Button>
                  </div>
                </div>
                <div className="comment-description">
                  <p>{comment.comment}</p>
                </div>
              </div>
            ))}
          <hr />
        </div>
      ) : (
        <div className="m-auto NoComment d-flex flex-column align-items-center justify-content-center">
          <h3>No Comment Yet</h3>
          <Button
            disabled={state.commentloading}
            isLoading={state.commentloading}
            themeType="primary"
            onClick={() => {
              if (!userToken) {
                history.push('/user?page=login')
                return
              }
              // focus on the textarea
              if (commentInput?.current) {
                commentInput.current.focus()
              }
            }}
          >
            Write a Review
          </Button>
        </div>
      )}
    </Fragment>
  )
}

export default ReviewSection
