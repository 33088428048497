const cacheKey = 'upanyas.query.cache'
export const getCacheItem = query => {
  var result = null
  try {
    const dataSet = localStorage.getItem(cacheKey)
    if (dataSet) {
      let responesCollection = JSON.parse(dataSet)
      result = responesCollection[query].data
    }
  } catch (e) {
    console.error(e.message)
  }
  return result
}

const cacheClenaUp = data => {
  if (data.lenght >= 5) {
    var minDate = Date.now()
    var index = Object.keys(data)[0]
    for (var key in data) {
      if (minDate >= data[key].expiry) {
        minDate = data[key].expiry
        index = key
      }
    }
    delete data[index]
    return data
  }
  return data
}

let addToStoreAge = data => {
  try {
    localStorage.setItem(cacheKey, JSON.stringify(data))
  } catch (e) {
    console.error(e.message)
    localStorage.removeItem(cacheKey)
  }
}

export const setCacheItem = (query, value) => {
  let setResponse = {
    data: value,
    expiry: new Date().getTime()
  }
  try {
    let dataSet = localStorage.getItem(cacheKey)
    if (dataSet) {
      let hashMap = JSON.parse(dataSet)
      hashMap = cacheClenaUp(hashMap)
      hashMap[query] = setResponse
      addToStoreAge(hashMap)
    } else {
      let map = {}
      map[query] = setResponse
      addToStoreAge(map)
    }
  } catch (e) {
    console.error(e.message)
  }
}
