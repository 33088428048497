import { Link } from 'react-router-dom'
// import Image from "../Image";
import Cardimg from 'Images/Book.png'
import { Star } from 'react-feather'
import Reads from 'Icons/reads.svg'
import Image from 'components/Image'

const ContentDetailsCard = ({
  item
}: {
  item: {
    link: string
    cover: string
    name: string
    author: string
    info: string
    reads: {
      length: number
    }
    ratings: number
    type: string
  }
}) => {
  return (
    <Link to={`/detail${item.link}`}>
      <div className="d-flex bigNovelCard" data-type={item.type}>
        <div className="me-3">
          <Image
            srcs={[`${item.cover}_m.png`]}
            fallback={Cardimg}
            alt="author"
            className="novelImage"
          />
        </div>
        <div className="novelCardDetail d-flex flex-column justify-content-between">
          <div>
            <p className="novelTitle mb-1">{item.name}</p>
            <p className="novelAuthor">{item.author}</p>
            <p className="description">{item.info.substring(0, 115)}...</p>
          </div>
          <div className="stats">
            <p className="mb-0">
              <img src={Reads} alt="Reads" /> &nbsp; &nbsp;{item.reads.length}{' '}
              Reads
            </p>
            <p className="mb-0">
              <Star fill="#A4A4A4" color="#A4A4A4" size={15} /> &nbsp; &nbsp;
              {item.ratings} Ratings
            </p>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ContentDetailsCard
