import { getImage, NOVEL } from 'utils'
import Image from '../Image'
import Language from 'Icons/language.svg'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { ContentDetail } from 'types/Content'

const DetailsSection = ({
  details,
  contentInfo
}: {
  details: ContentDetail
  contentInfo: {
    name: string
  }
}) => {
  const { language, contentWarning, description, tags, chapters } = {
    ...details
  }
  const image = getImage(details?.cover?.url, 's', NOVEL)

  return (
    <div className="details-section">
      <div className="content-image mx-auto mt-3 mb-5">
        <Image srcs={image} />
      </div>
      <div className="separator mx-auto border" />
      <div className="mx-3 mt-4">
        {language && (
          <div className="language d-flex">
            <img src={Language} alt="" width={18} />{' '}
            <span className="ms-2">English</span>
          </div>
        )}
        <div className="title-and-explicit mt-2 d-flex justify-content-between align-items-start">
          <p className="content-title">{contentInfo.name}</p>
          {contentWarning && (
            <p className="explicit-tag bg-dark text-white px-2 py-1 rounded ms-3 w-100 text-center">
              {contentWarning === 'implicit' ? 'PG-13' : '18+'}
            </p>
          )}
        </div>
        {description && (
          <div className="content-description">
            <p>{description}</p>
          </div>
        )}
        {!isEmpty(tags) && (
          <div className="content-tags d-flex flex-wrap">
            {tags.map(tag => (
              <span key={tag} className="tag me-1 px-3 mb-2 py-1">
                {tag}
              </span>
            ))}
          </div>
        )}
      </div>
      {!isEmpty(chapters) && (
        <div className="table-of-content mt-4">
          <p className="heading mx-3">Table Of Content</p>
          <div className="chapters">
            {chapters.map(
              ({ chapter: { id, name, updatedAt, isPublished } }) => (
                <div key={id} className="chapter px-3 py-2">
                  <p className="chapter-name mb-0">{name}</p>
                  <p className="published-date mb-0">
                    {isPublished ? (
                      <>Updated - {moment(updatedAt).fromNow()}</>
                    ) : (
                      <>Not Published</>
                    )}
                  </p>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default DetailsSection
