import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, last } from 'lodash'
import moment from 'moment'
import Drag from 'Icons/drag.svg'
import { getContentById } from 'NetworkCall/RepoHandler/contentDataService'
import Typewriter from './Typewriter'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Fragment } from 'react'
import { SaveContent } from './SaveContent'
import {
  contentTypes,
  DEFAULT_DATETIME,
  getActiveChapterContent,
  getNewName,
  isContentEmpty,
  newNovelData,
  NOVEL,
  typeName,
  writeupId,
  newChapter,
  toastWarn
} from '../../utils'
import './Typewriter.scss'
import { Button, Loader } from 'mtrd'
import {
  Card,
  CardBody,
  Collapse,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover
} from 'reactstrap'
import { ChevronDown, ChevronUp, Trash } from 'react-feather'
import {
  addChapters,
  createNovel,
  removeChapter,
  updateChapter,
  updateChapterOrder
} from '../../NetworkCall/RepoHandler/novelDataService'
import RichTextEditor, {
  getTextAlignBlockMetadata,
  getTextAlignStyles
} from 'react-rte'
import { useBeforeunload } from 'react-beforeunload'

const reorderChapterList = (list, startIndex, endIndex) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const Editor = ({
  match: {
    params: { type, id }
  }
}) => {
  const history = useHistory()
  const [chapters, setChapters] = useState(null)
  const [chapterToggle, setChapterToggle] = useState(false)
  const [novelData, setNovelData] = useState(null)
  const [isAddingChapter, setIsAddingChapter] = useState(false)
  const [activeChapterID, setActiveChapterID] = useState(null)
  const [isSavingContent, setIsSavingContent] = useState(false)
  const [details, setDetails] = useState(null)

  const [contentInfo, setContentInfo] = useState({
    name: getNewName(type),
    writeupId: null,
    lastSaved: 'Never Saved'
  })
  const [forLoadEditable, setForLoadEditable] = useState(true)
  const [editorContent, setEditorContent] = useState(
    RichTextEditor.createValueFromString('', 'html', {
      customBlockFn: getTextAlignBlockMetadata
    })
  )
  const editorContentAsHTML = editorContent.toString('html', {
    blockStyleFn: getTextAlignStyles
  })

  const setRteEditorContent = content => {
    if (typeof content === 'string' || content instanceof String)
      setEditorContent(
        RichTextEditor.createValueFromString(content, 'html', {
          customBlockFn: getTextAlignBlockMetadata
        })
      )
    else setEditorContent(content)
  }

  const contentToHTML = (content = editorContent) =>
    content.toString('html', { blockStyleFn: getTextAlignStyles })

  const setPageContentInfo = (name, data, updatedAt) => {
    setContentInfo({
      name,
      writeupId: writeupId(data, type),
      lastSaved: 'Last Saved: ' + moment(updatedAt).format(DEFAULT_DATETIME)
    })
  }

  const setPageContent = ({ data }) => {
    setPageContentInfo(data.name, data, data.contentUpdatedAt || data.updatedAt)
    setDetails(data)
    if (type !== NOVEL) setRteEditorContent(data.content)
    else setNovelData(data)
  }

  const LoadEditable = async () => {
    if (contentTypes.includes(type)) {
      getContentById(id, type)
        .then(setPageContent)
        .catch(error => {
          console.log(error)
          history.push('/writer')
        })
    }
    setForLoadEditable(false)
  }

  const saveDraft = async (callback, isAutoSave = false) => {
    if (type !== NOVEL) {
      if (isContentEmpty(contentToHTML())) {
        return toastWarn('Content cannot be empty!', 'bottom-left')
      } else if (isEmpty(contentInfo.name)) {
        return toastWarn('Name cannot be empty!', 'bottom-left')
      }
    }
    let toastMsg = null
    if (!isAutoSave) {
      toastMsg = toast.loading(
        `Hang on while we save your amazing ${typeName(type)}...`,
        {
          position: 'bottom-center'
        }
      )
    }
    if (type !== NOVEL) {
      await SaveContent(
        contentInfo,
        type,
        setContentInfo,
        contentToHTML(),
        callback
      )
    } else {
      if (contentInfo.writeupId) {
        await saveChapterContent()
      } else {
        const { data } = await createNovel({
          ...novelData,
          chapters: chapters.map(chapter => ({
            name: chapter.chapter.name,
            content: contentToHTML(chapter.chapter.content),
            index: chapter.index
          }))
        })
        setNovelData(data)
        setContentInfo({
          name: data.name,
          writeupId: writeupId(data, type),
          lastSaved:
            'Last Saved: ' + moment(data.updatedAt).format(DEFAULT_DATETIME)
        })
      }
      if (callback && novelData.novelID) callback(novelData.novelID)
    }

    if (!isAutoSave) {
      toast.update(toastMsg, {
        render: `${typeName(type)} saved successfully!`,
        type: 'success',
        isLoading: false,
        position: 'bottom-center',
        autoClose: 4000,
        closeOnClick: true
      })
    }
  }

  const publishDraft = () => {
    saveDraft(id => {
      history.push({
        pathname: `/publishform/${type}/${id}`
      })
    })
  }

  const loginPopup = () => {
    toast.error('Please login to save!', {
      position: toast.POSITION.TOP_CENTER
    })
    setTimeout(() => {
      window.open(
        window.location.protocol +
          '//' +
          window.location.host +
          '/user?win=true',
        '_blank',
        'location=yes,height=700,width=520,scrollbars=yes,status=yes'
      )
    }, 2000)
  }

  const saveDraftBtn = () => {
    if (localStorage.getItem('auth-token') !== null) return saveDraft()
    loginPopup()
  }

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }
    const items = reorderChapterList(
      chapters,
      result.source.index,
      result.destination.index
    )

    for (var i = 0; i < items.length; i++) {
      items[i].index = `${i + 1}`
    }
    // setChapterToggle(false);
    setChapters(items)
  }

  const closeDeletePopover = id =>
    document.querySelector(`#deletePop${id}`).click()

  const addChapterToNovel = async () => {
    setIsAddingChapter(true)
    try {
      let chaptersCopy
      if (novelData.novelID || id) {
        const novelID = id || novelData.novelID
        const { data } = await addChapters(novelID)
        chaptersCopy = [...chapters]
        const newChapter = { ...data.chapters.pop() }
        newChapter.index = `${newChapter.index}`
        chaptersCopy.push(newChapter)
      } else {
        chaptersCopy = [...chapters]
        chaptersCopy.push({
          ...newChapter(`${parseInt(last(chapters).index) + 1}`)
        })
      }
      setChapters(chaptersCopy)
      setIsAddingChapter(false)
    } catch (error) {
      console.log(error)
      setIsAddingChapter(false)
    }
  }

  const addChapter = () => {
    toast.promise(
      addChapterToNovel,
      {
        pending: 'Adding Chapter...',
        success: 'Chapter Added!',
        error: 'Some Error Occurred :('
      },
      {
        position: 'bottom-center',
        autoClose: 1500
      }
    )
  }

  const setChapterContentToEditor = id => {
    const content = getActiveChapterContent(chapters, id)
    setRteEditorContent(content)
    setActiveChapterID(id)
  }

  const updateChapterData = async (
    value,
    id,
    setSubmitting,
    setRenameChapter
  ) => {
    try {
      let data
      if (!id.includes('new_')) {
        ;({ data } = await updateChapter(value, id))
      }
      const updatedChapters = chapters.map(chapter => {
        if (id === chapter.chapter.chapterID) {
          return {
            ...chapter,
            chapter: id.includes('new_')
              ? {
                  ...chapter.chapter,
                  name: value.name
                }
              : {
                  ...data,
                  content: chapter.chapter.content
                }
          }
        }
        return { ...chapter }
      })
      setChapters(updatedChapters)
    } catch (error) {
      console.log(error)
    } finally {
      setSubmitting(false)
      setRenameChapter()
    }
  }

  const updateChapterArrangement = async () => {
    const novelID = novelData.novelID
    try {
      const chapterOrder = chapters.map(chapter => ({
        id: chapter.chapter.chapterID,
        index: parseInt(chapter.index)
      }))
      if (novelID) await updateChapterOrder({ chapters: chapterOrder }, novelID)
    } catch (error) {
      console.log(error)
    }
  }

  const removeChapterFromNovel = async chapterID => {
    try {
      let updatedChapters
      if (novelData.novelID) {
        const { data } = await removeChapter(chapterID, novelData.novelID)
        updatedChapters = data.chapters.map(chapter => {
          const chap = chapters.find(
            item => item.chapter.chapterID === chapter.chapter.chapterID
          )
          return {
            ...chapter,
            index: chapter.index.toString(),
            chapter: {
              ...chapter.chapter,
              content: chap.chapter.content
            }
          }
        })
        setContentInfo({
          name: data.name,
          writeupId: writeupId(data, type),
          lastSaved:
            'Last Saved: ' +
            moment(data.contentUpdatedAt || data.updatedAt).format(
              DEFAULT_DATETIME
            )
        })
      } else {
        console.log(chapters)
        updatedChapters = chapters.filter(
          chapter => chapter.chapter.chapterID !== chapterID
        )
      }
      if (chapterID === activeChapterID) {
        setActiveChapterID(updatedChapters[0].chapter.chapterID)
      }
      setChapters(updatedChapters)
    } catch (error) {
      console.log(error)
    }
  }

  const saveChapterContent = async () => {
    setIsSavingContent(true)
    Promise.all(
      chapters.map(async chapter => {
        return await updateChapter(
          {
            name: chapter.chapter.name,
            content: contentToHTML(chapter.chapter.content)
          },
          chapter.chapter.chapterID
        )
      })
    ).then(async result => {
      console.log(result)
      let novelID = id
      if (!id) {
        novelID = result[0].data.novelID
      }
      const { data } = await getContentById(novelID, type)
      setPageContentInfo(data.name, data, data.updatedAt)
      setIsSavingContent(false)
    })
  }

  useBeforeunload(event => {
    if (
      type !== NOVEL &&
      !isContentEmpty(contentToHTML()) &&
      isEmpty(contentInfo.writeupId)
    ) {
      event.preventDefault()
      return 'Your data is not saved, are you sure you want to leave?'
    } else if (isEmpty(contentInfo.writeupId)) {
      let hasContent = false
      let i = 0
      while (!hasContent && i <= chapters.length) {
        let value = chapters[i].chapter.content
        if (!(typeof value === 'string' || value instanceof String))
          value = contentToHTML(value)

        if (!isContentEmpty(value)) hasContent = true
        i++
      }
      if (hasContent) {
        event.preventDefault()
        return 'Your data is not saved, are you sure you want to leave?'
      }
    }
  })

  useEffect(() => {
    if (!contentTypes.includes(type)) return history.push('/writer')
    if (id !== undefined && forLoadEditable) LoadEditable()
  }, [])

  useEffect(() => {
    const updateContent = setTimeout(() => {
      if (
        !isEmpty(contentInfo.writeupId) &&
        !isContentEmpty(editorContentAsHTML) &&
        !isEmpty(contentInfo.name) &&
        !isSavingContent
      ) {
        saveDraft(null, true)
      }
    }, 8000)

    return () => clearTimeout(updateContent)
  }, [editorContentAsHTML])

  useEffect(() => {
    if (type === NOVEL && !id && isEmpty(novelData)) {
      const data = newNovelData()
      setNovelData(data)
      setContentInfo({
        name: data.name,
        writeupId: writeupId(data, type),
        lastSaved:
          'Last Saved: ' + moment(data.updatedAt).format(DEFAULT_DATETIME)
      })
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(novelData)) {
      const chaptersWithStringIndex = novelData.chapters.map(chapter => ({
        ...chapter,
        index: `${chapter.index}`
      }))
      setChapters(chaptersWithStringIndex)
      let currentActiveChapterID =
        activeChapterID || chaptersWithStringIndex[0].chapter.chapterID
      if (currentActiveChapterID.includes('new_')) {
        currentActiveChapterID = chaptersWithStringIndex[0].chapter.chapterID
      }
      setActiveChapterID(currentActiveChapterID)
      const content = getActiveChapterContent(
        chaptersWithStringIndex,
        currentActiveChapterID
      )
      setRteEditorContent(content)
    }
  }, [novelData])

  const Chapters = () => {
    return (
      <Fragment>
        <DragDropContext
          onBeforeDragStart={() => setChapterToggle(false)}
          onDragEnd={onDragEnd}
        >
          <Droppable droppableId="droppable">
            {(provided, e) => (
              <div
                className="chaptersDraggable"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {chapters.map((item, index) => (
                  <Draggable
                    // isDragDisabled={chapterToggle}
                    key={item.chapter.chapterID}
                    draggableId={item.index}
                    index={index}
                  >
                    {provided => {
                      return (
                        <Fragment>
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            data-key={item.chapter.chapterID}
                            className={
                              item.active +
                              ' chapterName drag-item d-flex justify-content-between align-item-center'
                            }
                            onClick={() =>
                              setChapterToggle(
                                chapterToggle === item.index
                                  ? false
                                  : item.index
                              )
                            }
                          >
                            <div className="d-flex">
                              <img
                                className="dragIcon"
                                src={Drag}
                                title="Drag to reorder"
                                alt="drag"
                                style={{
                                  width: '17px',
                                  marginTop: '-4px',
                                  marginRight: '10px'
                                }}
                              />
                              <span className="number">{item.index}</span>{' '}
                              {item.chapter.name}
                            </div>
                            <div className="chevIcon">
                              {chapterToggle === item.index ? (
                                <ChevronUp color="gray" />
                              ) : (
                                <ChevronDown color="gray" />
                              )}
                            </div>
                          </div>
                          <Collapse
                            style={{
                              // height: `${e.draggingOverWith ? "0px" : "auto"}`,
                              opacity: `${e.draggingOverWith ? 0.2 : 1}`
                            }}
                            isOpen={chapterToggle === item.index}
                          >
                            <Card>
                              <CardBody>
                                <div className="dateInfo">
                                  <div className="lastUpdated">
                                    <p className="font-weight-bold">Updated</p>
                                    <p>
                                      {moment(item.chapter.updatedAt).format(
                                        DEFAULT_DATETIME
                                      )}
                                    </p>
                                  </div>
                                  <div className="created">
                                    <p className="font-weight-bold">Created</p>
                                    <p>
                                      {moment(item.chapter.createdAt).format(
                                        DEFAULT_DATETIME
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex flex-row-reverse">
                                  <button
                                    className="mtrd-button delete"
                                    id={`deletePop${item.chapter.chapterID}`}
                                  >
                                    <Trash /> Delete
                                  </button>
                                </div>
                                <UncontrolledPopover
                                  placement="top"
                                  target={`deletePop${item.chapter.chapterID}`}
                                  trigger="click"
                                >
                                  <PopoverHeader>Warning!</PopoverHeader>
                                  <PopoverBody>
                                    Are you sure you want to delete this
                                    chapter?
                                    <div className="d-flex mt-2 justify-content-end">
                                      <Button
                                        small
                                        themeType="secondary"
                                        onClick={() =>
                                          closeDeletePopover(
                                            item.chapter.chapterID
                                          )
                                        }
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        className="ms-2"
                                        small
                                        onClick={() =>
                                          removeChapterFromNovel(
                                            item.chapter.chapterID
                                          )
                                        }
                                      >
                                        Yes
                                      </Button>
                                    </div>
                                  </PopoverBody>
                                </UncontrolledPopover>
                              </CardBody>
                            </Card>
                          </Collapse>
                        </Fragment>
                      )
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Fragment>
    )
  }

  if (
    (type !== NOVEL && id !== undefined && isContentEmpty(contentToHTML())) ||
    (type === NOVEL && !novelData) ||
    (type === NOVEL && !chapters)
  ) {
    return <Loader />
  }

  return (
    <Typewriter
      setContentInfo={setContentInfo}
      contentInfo={contentInfo}
      editorContent={editorContent}
      saveDraftBtn={saveDraftBtn}
      publishDraft={publishDraft}
      type={type}
      chapters={chapters}
      setEditorContent={setRteEditorContent}
      GiveEditToc={Chapters}
      setData={setChapters}
      addChapterToNovel={addChapter}
      isAddingChapter={isAddingChapter}
      activeChapterID={activeChapterID}
      setChapterContentToEditor={setChapterContentToEditor}
      updateChapterData={updateChapterData}
      updateChapterArrangement={updateChapterArrangement}
      details={details}
    />
  )
}

Editor.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  type: PropTypes.string
}

export default Editor
