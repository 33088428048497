import novelIcon from 'Images/novel-icon.png'
import poemIcon from 'Images/poem-icon.png'
import storyIcon from 'Images/short-story-icon.png'
import { Link } from 'react-router-dom'
import 'Style/writeModal.scss'

const WriteModalInner = ({ height = 'auto' }: { height: string | number }) => {
  return (
    <div className="wtCategory display-flex mt-5 mb-5 justify-content-evenly">
      <div className="ms-auto novelCat">
        <Link to="/typewriter/novel">
          <img src={novelIcon} alt="novelIcon" height={height} />
          <div className="imgCaption mt-3 text-center font-weight-bold">
            Novel
          </div>
        </Link>
      </div>
      <div className="ms-5 me-5 shortCat">
        <Link to="/typewriter/story">
          <img src={storyIcon} alt="storyIcon" height={height} />
          <div className="imgCaption mt-3 text-center font-weight-bold">
            Story
          </div>
        </Link>
      </div>
      <div className="me-auto poemCat">
        <Link to="/typewriter/poem">
          <img src={poemIcon} alt="poemIcon" height={height} />
          <div className="imgCaption mt-3 text-center font-weight-bold">
            Poem
          </div>
        </Link>
      </div>
    </div>
  )
}

export default WriteModalInner
