import { FunctionComponent } from 'react'

interface LoaderProps {
  fullScreen?: boolean
}

const Loader: FunctionComponent<LoaderProps> = ({ fullScreen = true }) => {
  return (
    <div
      className={`w-100 mb-5 h-100 loadingIcon text-center d-flex justify-content-center align-items-center ${
        fullScreen ? ' position-absolute' : ''
      } `}
    >
      <div className="spinner-grow" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  )
}

export default Loader
