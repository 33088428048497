import Image from '../Image'
import * as Icons from 'react-feather'
import { Author } from 'types/Author'

const AuthorCard = ({ author }: { author: Author }) => {
  return (
    <>
      <div className="aboutAuthor">
        <h3>About the Author</h3>
        <div className="authorCard">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <Image
                srcs={[`${author?.profileImage?.url}_s.png`]}
                fallback={
                  'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/action-thriller-book-cover-design-template-3675ae3e3ac7ee095fc793ab61b812cc.jpg?ts=1637008457'
                }
                alt="author"
                className="NovelAuthor me-3"
              />
              <div className="">
                <h5 className="font-weight-normal text-black mt-0">
                  {author?.name}
                </h5>
                <p className="mb-0">@Username</p>
              </div>
            </div>
            <div className="align-self-center viewProfile mx-4">
              View Profile &nbsp; <Icons.ArrowRight />
            </div>
          </div>
          <hr />
          <p>{author?.about}</p>
        </div>
      </div>
      <hr className={window.innerWidth < 992 ? 'mobileSectionDivider' : ''} />
    </>
  )
}

export default AuthorCard
