import RequestHandler from '../Connetion/RequestBulider'
import endpoint from '../Connetion/endpoints'
import { Promise } from 'bluebird'
import { rejectResolveNetwork } from './commonDataService'

Promise.config({ cancellation: true })

export const getNovels = () => {
  let request = RequestHandler.getRequest(
    endpoint.getAllNovel,
    null,
    RequestHandler.getMethod.get,
    false
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const createNovel = data => {
  let request = RequestHandler.postRequest(
    endpoint.CreateNovel,
    data,
    RequestHandler.postMethod.post,
    false
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const uploadCover = path => {
  let data = {
    image: path
  }
  let request = RequestHandler.postRequest(
    endpoint.uploadCoverImage,
    data,
    RequestHandler.postMethod.post,
    false
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const addChapters = novelID => {
  let request = RequestHandler.PostRequestById(
    endpoint.CreateChapter,
    novelID,
    {},
    RequestHandler.postMethod.patch,
    false
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const getChapters = () => {
  let request = RequestHandler.getRequest(
    endpoint.getAllChapter,
    null,
    RequestHandler.getMethod.get,
    false
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const updateChapter = (data, id) => {
  let request = RequestHandler.PostRequestById(
    endpoint.updateChapter,
    id,
    data,
    RequestHandler.postMethod.put,
    false
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const updateChapterOrder = (data, novelID) => {
  let request = RequestHandler.PostRequestById(
    endpoint.updateChapterOrder,
    novelID,
    data,
    RequestHandler.postMethod.patch,
    false
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const removeChapter = (chapterID, novelID) => {
  let request = RequestHandler.PostRequestById(
    endpoint.removeChapter,
    novelID,
    { chapterID },
    RequestHandler.postMethod.patch,
    false
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}
