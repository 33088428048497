import './TableOfContent.scss'

const contents = [
  {
    id: 1,
    title: 'Plot'
  },
  {
    id: 2,
    title: 'The Disaster'
  },
  {
    id: 3,
    title: 'The Courtesy'
  },
  {
    id: 4,
    title: 'The Hammer'
  },
  {
    id: 5,
    title: 'The Window'
  },
  {
    id: 6,
    title: 'The sin of wrath'
  },
  {
    id: 7,
    title: 'The Retalliation'
  },
  {
    id: 8,
    title: 'The Clouds'
  }
]

const Item = ({ title, id }: { title: string; id: string }) => (
  <div className="p-4 content-item row">
    <div className={window.innerWidth < 992 ? 'col-1' : 'col-2'}>{id}.</div>
    <div className="col-10">{title}</div>
  </div>
)

const TableOfContent = () => (
  <div className="mt-5">
    <h3 className="bigHeading my-4">Table Of Content</h3>
    <div className="content-container">
      {contents.map(item => (
        <Item key={item.id} title={item.title} id={item.id.toString()} />
      ))}
    </div>
  </div>
)

export default TableOfContent
