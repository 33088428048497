import RequestHandler from '../Connetion/RequestBulider'
import endpoint from '../Connetion/endpoints'
import Network from '../Connetion/Connection'
import { Promise } from 'bluebird'
import { rejectResolveNetwork } from './commonDataService'

Promise.config({ cancellation: true })

export const userLogin = (email, password) => {
  return new Promise((resolve, reject) => {
    let login = {
      email: email,
      password: password
    }
    let request = RequestHandler.postRequest(
      endpoint.login,
      login,
      RequestHandler.postMethod.post,
      false
    )
    Network(request, (respones, error) => {
      if (error) {
        reject(error)
      }
      resolve(respones)
    })
  })
}

export const userRegister = (name, email, password, username, mobile) => {
  return new Promise((resolve, reject) => {
    var login = {
      fullName: name,
      email: email,
      password: password
    }
    login = mobile ? { ...login, mobile: mobile } : login
    login = username ? { ...login, UserName: username } : login

    let request = RequestHandler.postRequest(
      endpoint.register,
      login,
      RequestHandler.postMethod.post,
      false
    )
    Network(request, (respones, error) => {
      if (error) {
        reject(error)
      }
      resolve(respones)
    })
  })
}

export const ForgotPasword = email => {
  return new Promise((resolve, reject) => {
    let data = {
      email: email
    }
    let request = RequestHandler.postRequest(
      endpoint.forgotPasword,
      data,
      RequestHandler.postMethod.post,
      false
    )
    Network(request, (respones, error) => {
      if (error) {
        reject(error)
      }
      resolve(respones)
    })
  })
}

export const authManager = (token, typeOfAuth) => {
  return new Promise((resolve, reject) => {
    let login = {
      auth: token,
      type: typeOfAuth
    }
    let request = RequestHandler.postRequest(
      endpoint.authManger,
      login,
      RequestHandler.postMethod.post,
      false
    )
    Network(request, (respones, error) => {
      if (error) {
        reject(error)
      }
      resolve(respones)
    })
  })
}

export const restPassword = (token, password) => {
  return new Promise((resolve, reject) => {
    let data = {
      token: token,
      password: password
    }
    let request = RequestHandler.postRequest(
      endpoint.restPassword,
      data,
      RequestHandler.postMethod.post,
      false
    )
    Network(request, (respones, error) => {
      if (error) {
        reject(error)
      }
      resolve(respones)
    })
  })
}

export const verifyRestPasswordToken = token => {
  return new Promise((resolve, reject) => {
    let request = RequestHandler.getRequestById(
      endpoint.restPasswordVerifyToken,
      token,
      null,
      RequestHandler.getMethod.get
    )
    Network(request, (response, error) => {
      if (error) {
        reject(error)
      }
      resolve(response)
    })
  })
}

export const getUserByToken = () => {
  return new Promise((resolve, reject) => {
    let request = RequestHandler.getRequest(
      endpoint.requestUserByToken,
      null,
      RequestHandler.getMethod.get,
      true
    )
    return rejectResolveNetwork(request, reject, resolve)
  })
}

export const logoutUser = () => {
  return new Promise((resolve, reject) => {
    let rfToken = localStorage.getItem('RfToken')
    let data = { refreshToken: rfToken }
    let request = RequestHandler.postRequest(
      endpoint.logout,
      data,
      RequestHandler.postMethod.post,
      false
    )
    Network(request, (response, error) => {
      if (error) {
        reject(error)
      }
      resolve(response)
    })
  })
}

export const commentPost = (type, contentId, comment) => {
  let data = {
    contentID: contentId,
    contentIsA: type,
    comment: comment
  }
  return new Promise((resolve, reject) => {
    let request = RequestHandler.postRequest(
      endpoint.comment,
      data,
      RequestHandler.postMethod.post
    )
    Network(request, (response, error) => {
      if (error) {
        reject(error)
      }
      resolve(response)
    })
  })
}

export const likeContent = (type, id) => {
  let data = { contentID: id, contentIsA: type }
  return new Promise((resolve, reject) => {
    let request = RequestHandler.postRequest(
      endpoint.like,
      data,
      RequestHandler.postMethod.post
    )
    Network(request, (response, error) => {
      if (error) {
        reject(error)
      }
      resolve(response)
    })
  })
}
