import Ads from 'Images/ads.png'
import Image from '../Image'

const Ad = ({ link }: { link: string[] }) => (
  <div style={{ padding: '2rem 2rem' }}>
    <Image srcs={link} className="ads" alt="Some ad" fallback={Ads} />
  </div>
)

export default Ad
