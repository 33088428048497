import { Fragment, useEffect, useState } from 'react'
import { debounce, isEmpty } from 'lodash'
import {
  getDetail as getContentDetail,
  getListing
} from 'NetworkCall/RepoHandler/commonDataService'
import {
  addTolibary,
  getLibrary,
  removeToLibary
} from 'NetworkCall/RepoHandler/LibraryDataService'
import {
  commentPost,
  getUserByToken,
  likeContent
} from 'NetworkCall/RepoHandler/userDataService'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import ContentDetailsPage from './ContentDetailsPage'
import './ContentDetail.scss'
import { getImage, NOVEL } from 'utils'
import { UserDetails } from 'types/User'
import { ContentDetail } from 'types/Content'

const ContentDetails = ({
  match: {
    params: { type, id }
  }
}: {
  match: {
    params: { type: string; id: string }
  }
}) => {
  const [state, setState] = useState<any>({
    isUser: true,
    data: {},
    liked: false,
    library: {
      id: '',
      in: false
    },
    comment: '',
    user: null,
    loading: false,
    commentloading: false,
    link: ''
  })

  const [dataList1, setDataList1] = useState([])
  const [dataList2, setDataList2] = useState([])

  const userToken = localStorage.getItem('auth-token')

  const loadUserDetails = () => {
    getUserByToken()
      .then(({ data }: { data: UserDetails }) => {
        setState((prevState: any) => ({ ...prevState, user: data }))
      })
      .catch(console.log)
  }

  const likedMethod = debounce(() => {
    likeContent(type, id)
      .then(() => {})
      .catch((error: any) => {
        toast.error(error.message, { position: toast.POSITION.BOTTOM_LEFT })
      })
  }, 150)

  const statFunction = () => {
    const statParent = document.querySelector<HTMLElement>('.statCardsParent')
    if (statParent) {
      statParent.classList.remove('overflow')
      const statCards = document.querySelectorAll<HTMLElement>('statCard')
      let totalwidth = 0

      for (let i = 0; i < statCards.length; i++) {
        totalwidth = totalwidth + statCards[i].offsetWidth
      }
      totalwidth = totalwidth + 40

      if (totalwidth >= statParent.offsetWidth) {
        statParent.classList.add('overflow')
      } else {
        statParent.classList.remove('overflow')
      }
    }
  }

  const libraryMapper = (libraryData: any, id: string) => {
    let data
    if (libraryData instanceof Array) {
      data = libraryData
    } else {
      data = libraryData.data
    }
    data.map((res: any) => {
      if (res.id) {
        const value = res.id._id === id ? true : false
        setState((prevState: any) => ({
          ...prevState,
          library: { id: res._id, in: value }
        }))
      }
      return null
    })
  }

  const likedAction = () => {
    if (userToken) {
      const like = state.liked
      const { data } = state
      if (!like) {
        data.likeCount += 1
      } else {
        data.likeCount -= 1
      }
      setState({ ...state, data, liked: !like })
      likedMethod()
    }
  }

  const getDetail = (type: string, id: string) =>
    getContentDetail(type.toLowerCase(), id)
      .then(({ data }: { data: ContentDetail }) =>
        setState((prevState: any) => ({
          ...prevState,
          data,
          liked: data.likedByUser,
          commentloading: false,
          link: getImage(data?.cover?.url, 's', NOVEL)
        }))
      )
      .catch(({ message }: { message: string }) =>
        toast.error(message, { position: toast.POSITION.BOTTOM_LEFT })
      )

  const addComment = () => {
    setState({ ...state, commentloading: true })
    const { comment } = state

    commentPost(type, id, comment)
      .then(() => {
        getDetail(type, id)
        setState({ ...state, comment: '' })
      })
      .catch((error: any) => {
        toast.error(error.message, { position: toast.POSITION.BOTTOM_LEFT })
      })
  }

  const handleInputChange = (event: InputEvent) => {
    const inputElement = event.target as HTMLInputElement
    const { value, name } = inputElement
    setState({ ...state, [name]: value })
  }

  const addTolibaryRequest = () => {
    setState({ ...state, loading: true })
    addTolibary(id, type)
      .then((data: any) => {
        libraryMapper(data.library, id)
        setState({ ...state, library: { id: id, in: true }, loading: false })
      })
      .catch((error: any) => {
        toast.error(error.message, { position: toast.POSITION.BOTTOM_LEFT })
        setState({ ...state, loading: false })
      })
  }

  const removeToLibaryRequest = () => {
    const {
      library: { id }
    } = state
    setState({ ...state, loading: true })

    removeToLibary(id)
      .then(() => {
        setState({ ...state, library: { id: '', in: false }, loading: false })
      })
      .catch((error: any) => {
        toast.error(error.message, { position: toast.POSITION.BOTTOM_LEFT })
        setState({ ...state, loading: false })
      })
  }

  const libraryAction = () => {
    if (state.library?.in) {
      removeToLibaryRequest()
    } else {
      addTolibaryRequest()
    }
  }

  const getListingRequest = async (type: string, num: number) => {
    try {
      const { data } = await getListing(type)
      if (num === 1) {
        setDataList1(data)
      } else {
        setDataList2(data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    window.addEventListener('load', statFunction)
    window.addEventListener('resize', statFunction)
    if (isEmpty(state.data)) getDetail(type, id)
    if (userToken) {
      if (!state.user) loadUserDetails()

      getLibrary()
        .then((data: any) => {
          libraryMapper(data, id)
        })
        .catch((error: any) => {
          toast.error(error.message, {
            position: toast.POSITION.BOTTOM_LEFT
          })
        })
    } else {
      setState((prevState: any) => ({ ...prevState, isUser: false }))
    }
    return () => {
      window.removeEventListener('load', statFunction)
      window.removeEventListener('resize', statFunction)
    }
  }, [id, state.user, state.data, type, userToken])

  useEffect(() => {
    window.scrollTo(0, 0)

    let listing1 = '',
      listing2 = ''
    switch (type.toLowerCase()) {
      case 'series':
        listing1 = 'popular'
        listing2 = 'updated'
        break
      case 'novel':
        listing1 = 'latest'
        listing2 = 'trending'
        break
      case 'story':
        listing1 = 'trending'
        listing2 = 'updated'
        break
      case 'poem':
        listing1 = 'popular'
        listing2 = 'latest'
        break
      default:
        break
    }
    const fetchListings = async () => {
      await getListingRequest(listing1, 1)
      await getListingRequest(listing2, 2)
    }
    fetchListings()
  }, [type])

  return (
    <Fragment>
      <ContentDetailsPage
        link={state.link}
        type={type}
        id={id}
        state={state}
        likedAction={likedAction}
        addComment={addComment}
        libraryAction={libraryAction}
        handleInputChange={handleInputChange}
        userToken={userToken}
        dataList1={dataList1}
        dataList2={dataList2}
      />
    </Fragment>
  )
}

export default withRouter(ContentDetails)
