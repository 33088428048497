import { Fragment } from 'react'
import { Row, Col, Container } from 'reactstrap'
import SubCompoent from './SubComponet'
import ShowMore from '../Common/ShowMore'

const GidView = ({
  subComponet,
  list,
  heading,
  span,
  more,
  index
}: {
  subComponet: string
  list: any[]
  heading?: string
  span: number
  more: any
  index: number
}) => {
  if (list.length !== 0) {
    return (
      <Fragment>
        {index === 0 && <div className="content"></div>}
        <div className="container-fluid" style={{ padding: '2rem 1rem' }}>
          <Row>
            {heading && (
              <Container className="mb-4">
                <ShowMore
                  heading={heading}
                  link={more?.link}
                  text={more?.text}
                />
              </Container>
            )}
            <div className="col-12">
              <Row className=" m-auto">
                {list.map((item, index) => (
                  <Col sm={span} key={index}>
                    <SubCompoent
                      key={index}
                      compoent={subComponet}
                      index={index}
                      item={item}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </Row>
        </div>
      </Fragment>
    )
  }
  return (
    <Fragment>
      <h3 className="text">No Data Found</h3>
    </Fragment>
  )
}

export default GidView
