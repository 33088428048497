import PropTypes from 'prop-types'
import { CSSProperties } from 'react'

const ProgressBar = ({ completed }: { completed: number }) => {
  const containerStyles = {
    height: '5px',
    width: '80%',
    backgroundColor: '#e0e0de',
    borderRadius: 50,
    paddingRight: '10px',
    marginTop: '10px'
  }

  const fillerStyles: CSSProperties = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: '#fa7925',
    borderRadius: 'inherit',
    textAlign: 'center'
  }

  return (
    <div className="customProgress" style={containerStyles}>
      <div style={fillerStyles}></div>
    </div>
  )
}

ProgressBar.propTypes = {
  completed: PropTypes.number
}

export default ProgressBar
