import placeholder from 'Images/CategoryPlaceholder.svg'
import Image from '../Image'
import { Link } from 'react-router-dom'
import { getImage } from 'utils'
import { CategoryDetail } from 'types/Category'
import './Category.scss'

const Category = ({ item }: { item: CategoryDetail }) => {
  const image = getImage(item.backgroundImage?.url, 'm', 'category')
  return (
    <Link to={item.link} style={{ width: '100%', margin: '2rem 0' }}>
      <Image fallback={placeholder} srcs={image} className="catgory rounded" />
    </Link>
  )
}
export default Category
