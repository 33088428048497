import MediaQuery from 'react-responsive'
import SildeView from './Slider'
import GridView from './GridView'

const SimpleComponet = ({
  index,
  list,
  layout,
  heading,
  component,
  more
}: {
  index: number
  list: any[]
  layout: {
    desktop: { isHorizontail: boolean; span: number }
    mobile: { isHorizontail: boolean; span: number }
    tablet: { isHorizontail: boolean; span: number }
  }
  heading: string
  component: string
  more: any
}) => {
  const BindingView = (ViewType: { isHorizontail: boolean; span: number }) => {
    const isHorizontail = ViewType.isHorizontail
    if (isHorizontail) {
      return (
        <SildeView
          key={index}
          index={index}
          subComponet={component}
          heading={heading}
          list={list}
          more={more}
        />
      )
    }
    return (
      <GridView
        key={index}
        index={index}
        subComponet={component}
        span={ViewType.span}
        heading={heading}
        list={list}
        more={more}
      />
    )
  }

  const forMobileView = () => {
    const ViewType = layout.mobile
    return BindingView(ViewType)
  }

  const forTabletView = () => {
    const ViewType = layout.tablet
    return BindingView(ViewType)
  }

  const forDesktopView = () => {
    const ViewType = layout.desktop
    return BindingView(ViewType)
  }

  const handleChanges = (matches: any) => {
    console.log(matches)
  }

  return (
    <>
      <MediaQuery maxDeviceWidth={572}> {forMobileView()} </MediaQuery>
      <MediaQuery minDeviceWidth={572} maxDeviceWidth={1040}>
        {' '}
        {forTabletView()}{' '}
      </MediaQuery>
      <MediaQuery minDeviceWidth={1040} onChange={handleChanges}>
        {' '}
        {forDesktopView()}{' '}
      </MediaQuery>
    </>
  )
}

export default SimpleComponet
