import { Link } from 'react-router-dom'
import './GradientButton.scss'

const GradientButton = ({
  heading,
  link,
  color
}: {
  heading: string
  link: string
  color: string
}) => (
  <Link to={link}>
    <div
      className="customButton"
      style={{
        background: `linear-gradient(to right,${color[0]}, ${color[1]})`
      }}
    >
      {heading}
    </div>
  </Link>
)

export default GradientButton
