import facebook from 'Icons/facebook.svg'
import { authManager } from 'NetworkCall/RepoHandler/userDataService'
import FacebookLogin from '@greatsumini/react-facebook-login'
import './SocialButton.scss'

const fbAppId = '312894216819304'

const FacebookButtonLogin = ({
  urlParam,
  action
}: {
  urlParam?: string
  action: string
}) => {
  const userLogin = (res: any) => {
    localStorage.setItem('auth-token', res.token)
    localStorage.setItem('RfToken', res.refreshToken)
    if (urlParam) {
      window.close()
    } else {
      window.open(
        window.location.protocol + '//' + window.location.host + '/',
        '_self'
      )
    }
  }
  const authMangerService = (token: string, type: string) => {
    localStorage.setItem('auth-token', '')
    localStorage.setItem('RfToken', '')
    authManager(token, type)
      .then((res: any) => {
        userLogin(res.data)
      })
      .catch((error: any) => {
        alert('error happend: ' + error)
      })
  }

  const responseFacebook = (
    response:
      | {
          accessToken: string
          expiresIn: string
          reauthorize_required_in: string
          signedRequest: string
          userID: string
        }
      | undefined
  ) => {
    if (response?.accessToken) {
      authMangerService(response.accessToken, 'facebook')
    }
  }

  return (
    <>
      <FacebookLogin
        appId={fbAppId}
        render={(renderProps: any) => (
          <button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            className="Socialf"
          >
            <img className="imagef" alt="" src={facebook} />
            {action} with Facebook
          </button>
        )}
        fields="name,email,picture"
        onSuccess={responseFacebook}
      />
    </>
  )
}

export default FacebookButtonLogin
