import React, {
  ComponentProps,
  Fragment,
  FunctionComponent,
  ReactNode
} from 'react'

interface InputProps extends ComponentProps<'input'> {
  label?: ReactNode
  error?: string
}

const Input: FunctionComponent<InputProps> = ({
  label,
  className,
  error,
  ...props
}) => {
  return (
    <Fragment>
      {label && (
        <label htmlFor={props.name} className={`mtrd-input-label mb-2`}>
          {label}
        </label>
      )}
      <input
        className={`mtrd-input mb-1 ${className}${error ? ' invalid' : ''}`}
        {...props}
      />
      {error && (
        <div className="error-message small text-danger fw-500">{error}</div>
      )}
    </Fragment>
  )
}

Input.defaultProps = {
  type: 'text',
  className: ''
}

export default Input
