import * as yup from 'yup'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Button, Input } from 'mtrd'
import { Form, Formik } from 'formik'

const RenameChapterModal = ({
  renameChapterModalToggle,
  toggle,
  closeBtn,
  renameChapter,
  updateChapterData
}: {
  renameChapterModalToggle: (id?: any, name?: any) => void
  toggle: () => void
  closeBtn: (close: any) => JSX.Element
  renameChapter: {
    open: boolean
    id: null
    name: string
  }
  updateChapterData: (
    value: any,
    id: any,
    setSubmitting: any,
    setRenameChapter: any
  ) => Promise<void>
}) => {
  return (
    <Modal isOpen={renameChapter.open} toggle={toggle} className="mtrd-modal">
      <ModalHeader
        close={closeBtn(() => renameChapterModalToggle())}
        className="pb-2"
      >
        Rename Chapter
      </ModalHeader>
      <ModalBody className="pb-1">
        <Formik
          initialValues={{
            name: renameChapter.name
          }}
          enableReinitialize
          onSubmit={(value, { setSubmitting }) => {
            updateChapterData(
              value,
              renameChapter.id,
              setSubmitting,
              renameChapterModalToggle
            )
          }}
          validationSchema={yup.object().shape({
            name: yup.string().required('Name is required')
          })}
        >
          {formikProps => {
            const {
              values,
              errors,
              handleChange,
              isSubmitting,
              isValid,
              handleSubmit
            } = formikProps
            return (
              <Form
                onSubmit={handleSubmit}
                autoComplete="off"
                className="px-4 px-md-2 px-lg-0"
              >
                <div className="mb-3">
                  <Input
                    name="name"
                    value={values.name}
                    placeholder="Enter the name"
                    error={errors.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3 d-flex justify-content-end">
                  <Button
                    themeType="outlined"
                    className="me-2"
                    small
                    onClick={() => renameChapterModalToggle()}
                  >
                    Discard
                  </Button>
                  <Button
                    disabled={!isValid}
                    type="submit"
                    small
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default RenameChapterModal
