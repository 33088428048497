import { authManager } from 'NetworkCall/RepoHandler/userDataService'
import google from 'Icons/google.png'
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline
} from 'react-google-login'
import './SocialButton.scss'

const GoogleButton = ({
  urlParam = '',
  action
}: {
  urlParam?: string
  action: string
}) => {
  const googleLogin = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    const googleResponse = response as GoogleLoginResponse
    if (googleResponse.accessToken) {
      console.log(response)
      authMangerService(googleResponse.tokenId, 'google')
    }
  }

  const userLogin = (res: any) => {
    localStorage.setItem('auth-token', res.token)
    localStorage.setItem('RfToken', res.refreshToken)
    if (urlParam) {
      window.close()
    } else {
      window.open(
        window.location.protocol + '//' + window.location.host + '/',
        '_self'
      )
    }
  }

  const authMangerService = (token: string, type: string) => {
    localStorage.setItem('auth-token', '')
    localStorage.setItem('RfToken', '')
    authManager(token, type)
      .then((res: any) => {
        userLogin(res.data)
        console.log(res)
      })
      .catch((error: any) => {
        alert('error happend: ' + error)
      })
  }

  const GoogelLoginFailure = (response: any) => {
    alert('Failed to log in google')
    console.log(response)
  }

  return (
    <>
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID || ''}
        render={renderProps => (
          <button
            className="Socialg"
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            <img className="image" alt="" src={google} />
            {action} with Google
          </button>
        )}
        buttonText="Login"
        onSuccess={googleLogin}
        onFailure={GoogelLoginFailure}
        cookiePolicy={'single_host_origin'}
      />
    </>
  )
}
export default GoogleButton
