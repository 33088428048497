import RequestHandler from '../Connetion/RequestBulider'
import endpoint from '../Connetion/endpoints'
import { Promise } from 'bluebird'
import { plural } from '../../utils'
import _ from 'lodash'
import { rejectResolveNetwork } from './commonDataService'

Promise.config({ cancellation: true })

export const createContent = (name, content, type) => {
  let data = {
    name,
    content
  }

  let request = RequestHandler.postRequest(
    endpoint[`create${_.upperFirst(type)}`],
    data,
    RequestHandler.postMethod.post,
    false
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const updateContent = ({ writeupId, name }, content, type) => {
  let data = { content, name }
  let request = RequestHandler.PostRequestById(
    endpoint[`update${_.upperFirst(type)}`],
    writeupId,
    data,
    RequestHandler.postMethod.put,
    false
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const getContentByUser = type => {
  let request = RequestHandler.getRequest(
    endpoint[`get${plural(type)}ByUser`],
    null,
    RequestHandler.getMethod.get,
    true
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const getContentById = (id, type) => {
  let request = RequestHandler.getRequestById(
    endpoint[`get${_.upperFirst(type)}ById`],
    id,
    null,
    RequestHandler.getMethod.get,
    true
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const getPublishedContentById = (id, type) => {
  let request = RequestHandler.getRequestById(
    endpoint[`getPublished${_.upperFirst(type)}ById`],
    id,
    null,
    RequestHandler.getMethod.get,
    false
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const deleteContentByUser = (writeupID, type) => {
  let request = RequestHandler.getRequestById(
    endpoint[`delete${_.upperFirst(type)}ByUser`],
    writeupID,
    null,
    RequestHandler.getMethod.delete,
    true
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const saveContentDetails = (id, formData, type) => {
  let request = RequestHandler.PostRequestById(
    endpoint[`save${_.upperFirst(type)}Details`],
    id,
    formData,
    RequestHandler.postMethod.put,
    false
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const publishContent = (id, type) => {
  let request = RequestHandler.PostRequestById(
    endpoint[`publish${_.upperFirst(type)}`],
    id,
    null,
    RequestHandler.postMethod.patch,
    true
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}
