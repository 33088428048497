import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Button } from 'mtrd'

const ManageChaptersModal = ({
  isOpen,
  toggle,
  closeBtn,
  GiveEditToc,
  cancelDragorder,
  updateChapterArrangement,
  chaptersModalToggle
}: {
  isOpen: boolean
  toggle: () => void
  closeBtn: (close: any) => JSX.Element
  GiveEditToc: () => JSX.Element
  cancelDragorder: () => void
  updateChapterArrangement: () => Promise<void>
  chaptersModalToggle: () => void
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="mtrd-modal chapters-modal"
      size="lg"
      scrollable
    >
      <ModalHeader close={closeBtn} className="pb-2">
        Chapters
      </ModalHeader>
      <ModalBody className="pb-1">
        <p className="small fw-500 text-black-50">Drag to reorder</p>
        <GiveEditToc />
      </ModalBody>
      <ModalFooter className="pt-0">
        <Button onClick={cancelDragorder} themeType="secondary">
          Close
        </Button>
        <Button
          onClick={() => {
            updateChapterArrangement()
            chaptersModalToggle()
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ManageChaptersModal
