import { Fragment, useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap'
import Navbar from 'components/Common/Navbar'
import Footer from 'components/Common/Footer'
import DashboardCard from 'components/Dashboard/DashboardCard'
// import "react-vis/dist/style.css";
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'Style/dashboard.scss'
// import {
//   VerticalGridLines,
//   XAxis,
//   YAxis,
//   LineSeries,
//   FlexibleXYPlot,
//   RadialChart,
// } from "react-vis";
import { DateRange } from 'react-date-range'
import MobileNavTop from 'components/Common/MobileNavTop'

const Dashboard = () => {
  // const data = [
  //   { x: 0, y: 8 },
  //   { x: 1, y: 5 },
  //   { x: 2, y: 4 },
  //   { x: 3, y: 9 },
  //   { x: 4, y: 1 },
  //   { x: 5, y: 7 },
  //   { x: 6, y: 6 },
  //   { x: 7, y: 3 },
  //   { x: 8, y: 2 },
  //   { x: 9, y: 0 },
  // ];
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ])
  // const [pieWidth, setPieWidth] = useState(0);
  // const myData = [
  //   { angle: 1 },
  //   {
  //     angle: 2,
  //     label: "Super Custom label",
  //     subLabel: "With annotation",
  //   },
  //   { angle: 5, label: "Alt Label" },
  //   { angle: 3 },
  //   {
  //     angle: 5,

  //     subLabel: "Sub Label only",
  //     className: "custom-class",
  //   },
  // ];

  useEffect(() => {
    document
      .getElementsByClassName('dateChanging dropdown-menu')[0]
      .addEventListener('click', function (e) {
        e.stopPropagation()
      })

    // setPieWidth(
    //   document.getElementsByClassName("pieParent")[0].clientWidth - 50
    // );
  }, [])

  return (
    <Fragment>
      <Navbar />

      <MobileNavTop title={'Dashboard'} />
      <div className="content"></div>
      <Row className="mt-4 mx-auto">
        <Col>
          <Row className="mainCardRow">
            <DashboardCard
              title={'Reads'}
              active={true}
              numbers={'76,58,941'}
            />
            <DashboardCard title={'Likes'} active={false} numbers={'47,032'} />
            <DashboardCard title={'Shares'} active={false} numbers={'10118'} />
            <DashboardCard
              title={'Insights'}
              active={false}
              numbers={'47,564'}
            />
          </Row>
          <Row className="graphData">
            <Col lg="8">
              <div className="border-container">
                <div className="graphTitle">
                  <span className="h5 ms-3 font-weight-bold">Read</span>
                  <div className="float-right me-3">
                    <button
                      className="btn btn-secondary date-selecting-button"
                      type="button"
                      id="dateChanger"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {state[0].startDate.toLocaleDateString('default', {
                        month: 'short'
                      })}{' '}
                      -{' '}
                      {state[0].endDate.toLocaleDateString('default', {
                        month: 'short'
                      })}
                      <i className="fa fa-calendar-alt ms-2"></i>
                    </button>
                    <div
                      className="dropdown-menu dateChanging"
                      aria-labelledby="dateChanger"
                    >
                      <DateRange
                        editableDateInputs={true}
                        onChange={item => setState([item.selection])}
                        moveRangeOnFirstSelection={false}
                        ranges={state}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="graphParent mt-5"
                  style={{ width: '100%', height: '550px' }}
                ></div>
              </div>
            </Col>
            <Col lg="4">
              <Row>
                <Col xs="7">
                  <div className="perpetual">
                    <span className="h5 ms-3 font-weight-bold">Perpetual</span>
                    <div
                      className="pieParent w-100"
                      style={{ height: '100%', width: '100%' }}
                    ></div>
                  </div>
                </Col>
                <Col xs="5">Info</Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Footer />
    </Fragment>
  )
}

export default Dashboard
