import ContentCard from '../Common/ContentCard'
import Category from '../Category/Category'
import Button from '../Home/GradientButton'

const subComponetSelection = ({
  compoent,
  item,
  index
}: {
  compoent: string
  item: any
  index: any
}) => {
  switch (compoent) {
    case 'textbesideMedia': {
      return <ContentCard key={index} item={item} />
    }
    case 'textCenterMedia': {
      return <Category key={index} item={item} />
    }
    case 'ButtonSection': {
      return <Button heading={item.name} link={item.link} color={item.color} />
    }
    default: {
      return <h6>Sorry no sub compoent found</h6>
    }
  }
}

export default subComponetSelection
