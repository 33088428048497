import { Fragment, useState, useEffect } from 'react'
import { Row, Col, Container } from 'reactstrap'
import Navbar from 'components/Common/Navbar'
import Footer from 'components/Common/Footer'
import { getLibrary } from 'NetworkCall/RepoHandler/LibraryDataService'
import MobileNavTop from 'components/Common/MobileNavTop'
import cover from 'Images/proBg.png'
import profilePic from 'Images/proDp.png'
import clock from 'Icons/clock.svg'
import book from 'Icons/bookO.svg'
import rank from 'Icons/rankO.svg'
import ContentCard from 'components/Common/ContentCard'
import More from 'Icons/leftarrow.svg'
import ProStatCard from 'components/Profile/ProStatCard'
import 'Style/Color.scss'
import 'Style/profile.scss'

const Profile = () => {
  var [data, setData] = useState([])

  useEffect(() => {
    let request = getLibrary()
      .then(librarybook => {
        setData(librarybook)
      })
      .catch(error => {
        console.log(error)
      })
    return request.cancel()
  }, [])

  return (
    <Fragment>
      <Navbar />

      <MobileNavTop title={'Profile'} />
      <div className="content"></div>
      <Container>
        <Row className="mt-4 m-auto">
          <Col>
            <div className="profilePage">
              <div className="profileCover">
                <img className="coverImage" src={cover} alt="Profile Cover" />
                <img src={profilePic} className="dpImage" alt="Profile" />
              </div>
              <div className="profileDetails">
                <div className="container-fluid">
                  <Row>
                    <Col xl="5 nameBlock">
                      <p className="profileRealName">Dayitava Upadhyay</p>
                      <p className="profileUsername">@dayitava</p>
                      <button className="followUserButton upanyas-primary">
                        Follow
                      </button>
                    </Col>
                    <Col xl="7">
                      <div className="profileStatCards">
                        <ProStatCard
                          cardTitle="Reading Time"
                          cardValue="14.5 hrs"
                          cardImg={clock}
                        />
                        <ProStatCard
                          cardTitle="Read Books"
                          cardValue="14"
                          cardImg={book}
                        />
                        <ProStatCard
                          cardTitle="Ranking"
                          cardValue="#312"
                          cardImg={rank}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="aboutUser">
                    <Col xs="12">
                      <p className="aboutTitle">About</p>
                      <p className="aboutData">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.
                        <span role="img" aria-label="emoji">
                          😊
                        </span>
                        <span role="img" aria-label="emoji">
                          😋
                        </span>
                        <span role="img" aria-label="emoji">
                          😎
                        </span>
                        <span role="img" aria-label="emoji">
                          😊
                        </span>
                        <span role="img" aria-label="emoji">
                          🐱‍🚀
                        </span>
                      </p>
                    </Col>
                  </Row>
                  {data.length > 0 ? (
                    <Row className="userWork">
                      <Col xs="12 display-flex">
                        <p className="aboutTitle">My Works</p>
                        <img src={More} alt="more button" className="moreBtn" />
                      </Col>
                      <div className="container-fluid">
                        <Row className="workCards mt-4 mb-4">
                          {data.map(item => (
                            <Col
                              xs="12"
                              sm="6"
                              md="4"
                              lg="3"
                              xl="2"
                              key={item.id}
                            >
                              <Fragment>
                                <ContentCard item={item} />
                              </Fragment>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </Row>
                  ) : (
                    <Fragment></Fragment>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Footer />
    </Fragment>
  )
}

export default Profile
