import { Fragment, useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import LibraryCard from 'components/Library/libraryCard'
import Footer from 'components/Common/Footer'
import {
  getLibrary,
  lastread
} from 'NetworkCall/RepoHandler/LibraryDataService'
import MobileNavTop from 'components/Common/MobileNavTop'
import { Link } from 'react-router-dom'
import 'Style/libraryCard.scss'

const Library = () => {
  const [state, setState] = useState({
    data: [],
    lastRead: []
  })

  useEffect(() => {
    getLibrary()
      .then(librarybook => {
        lastread()
          .then(res => {
            let data = mapingDataSet(librarybook)
            let value = mapingLastRead(res)
            console.log(data)
            if (data.length > 0) {
              setState({ data: data, lastRead: value })
            }
          })
          .catch(console.log)
      })
      .catch(console.log)
  }, [])

  const mapingDataSet = value => value.map(({ id }) => id)

  const mapingLastRead = value =>
    value.map(({ currentProgress, id }) => ({
      Progress: currentProgress,
      value: id
    }))

  let { data } = state
  let list = state.lastRead
  if (data.length <= 0) {
    return (
      <Fragment>
        <MobileNavTop title={'Library'} />
        <div className="content"></div>
        <div className="container-fluid librarydiv">
          <Row className="mt-4">
            <Col>
              <h3 className="mb-4" style={{ fontWeight: '600' }}>
                Library
              </h3>
              <Row>
                <h6 className="mb-4" style={{ fontWeight: '500' }}>
                  No Books Found
                </h6>
              </Row>
            </Col>
          </Row>
        </div>
      </Fragment>
    )
  }
  return (
    <Fragment>
      <MobileNavTop title={'Library'} />
      <div className="content"></div>
      <div className="container-fluid librarydiv">
        <Row className="mt-4 container m-auto">
          <Col>
            {list.length > 0 ? (
              <LibraryCard heading="Continue Reading" lastRead={list} />
            ) : (
              <h1>p</h1>
            )}
            <h3 className="mb-4" style={{ fontWeight: '600' }}>
              Library
            </h3>
            <Row>
              {state.data.map((item, index) => (
                <Col
                  lg="3"
                  md="3"
                  sm="3"
                  xs="6"
                  xl="2"
                  key={index}
                  className="mb-4"
                >
                  <Link to={'/detail/' + item.isA + '/' + item._id}>
                    <div className="libGridCard">
                      <img
                        className="libGridImage"
                        src={item.cover + '_s.png'}
                        alt="nice"
                      />
                      <p className="libGridTitle">{item.name}</p>
                      <p className="libGridAuthor">{item.author.name}</p>
                    </div>
                  </Link>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>

      <Footer />
    </Fragment>
  )
}

export default Library
