import { Link } from 'react-router-dom'
import { Col } from 'reactstrap'
import { getImage, NOVEL } from 'utils'
import Image from '../Image'
import Book from 'Images/Book.png'
import './QuickLibary.scss'

const Quicklibrary = ({ books: list }: { books: any[] }) => (
  <>
    <div className="Box">
      <div className="d-flex">
        {list.map((value, index) => (
          <div key={index} className="homepagelibcard me-3">
            <Link to={`/detail/${value.isA}/${value._id}`}>
              <Image
                className="libImg"
                srcs={getImage(value?.cover?.url, 's', NOVEL)}
                alt="nice"
                fallback={Book}
              />
            </Link>
          </div>
        ))}
        <Col xs="3" className="showMoreParent homepage">
          <span className="float-right show-more">View More</span>
        </Col>
      </div>
    </div>
  </>
)

export default Quicklibrary
