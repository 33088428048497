import { useState, useRef } from 'react'
import Drawer from 'react-bottom-drawer'
import { Button } from 'mtrd'
import { Link } from 'react-router-dom'
import xCross from 'Icons/xCross.svg'
import * as Icons from 'react-feather'
import { useHistory } from 'react-router-dom'
import Image from '../Image'
import { Comment } from 'types/Comment'

const MobileReviewSection = ({
  state,
  comments,
  addComment,
  userToken,
  handleInputChange,
  placeholder
}: {
  state: any
  comments: Comment[]
  addComment: () => void
  userToken: string | null
  handleInputChange: (event: any) => void
  placeholder: any
}) => {
  const commentInput = useRef<HTMLInputElement>(null)
  const history = useHistory()
  const [isVisible, setIsVisible] = useState(false)
  return (
    <>
      <div className="d-flex justify-content-between align-items-center my-4">
        <h3 className="smallHeading">
          {comments?.length === 0 ? (
            'No Comments'
          ) : (
            <>
              {comments?.length === 1
                ? '1 Comment'
                : `${comments?.length} Comments`}
            </>
          )}
        </h3>
        <div className="viewProfile">
          <Button
            themeType="transparent"
            onClick={() => {
              setIsVisible(true)
            }}
            disabled={state.loading}
            isLoading={state.loading}
            className="viewProfile"
          >
            View Comments <Icons.ArrowRight />
          </Button>
        </div>
      </div>
      <Drawer
        duration={250}
        hideScrollbars={true}
        onClose={() => {
          setIsVisible(false)
        }}
        isVisible={isVisible}
      >
        <div className="MobileCommentsContainer">
          <div className="d-flex justify-content-between align-items-center my-4">
            <h3 className="smallHeading">Comments</h3>
            <Button
              onClick={() => {
                setIsVisible(false)
              }}
              disabled={state.commentloading}
              isLoading={state.commentloading}
              themeType={'transparent'}
              className="minw-0"
            >
              <Image srcs={[xCross]} fallback={xCross} />
            </Button>
          </div>
          <div className="commentInputContainer">
            <textarea
              className="commentInput"
              name="comment"
              value={state.comment || ''}
              placeholder="Write a Review..."
              onChange={handleInputChange}
              autoComplete="off"
              onKeyDown={e => e.key === 'Enter' && addComment()}
            ></textarea>
            <Button
              onClick={addComment}
              disabled={state.commentloading}
              isLoading={state.commentloading}
              themeType={'primary'}
              className="commentBtn"
            >
              <Icons.Send />
            </Button>
            {userToken === null && (
              <div className="LoginToWrite d-flex align-items-center justify-content-center">
                <h3>
                  <Link to="/user?page=login">Login</Link> to Write a Review
                </h3>
              </div>
            )}
          </div>
          {comments && comments.length > 0 ? (
            <div className="NovelReviewSection">
              {comments &&
                comments.map((comment: Comment, index: number) => (
                  <div
                    key={index}
                    className="NovelReviewUser align-items-center"
                  >
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <Image
                          srcs={[
                            comment?.commentedBy?.profileImage?.url + '_s.jpeg'
                          ]}
                          fallback={placeholder}
                          alt="author"
                          className="NovelAuthor me-3"
                        />
                        <div>
                          <h5 className="font-weight-bold mt-0">
                            {comment.commentedBy.name}
                          </h5>
                          <p className="mb-0">@Username</p>
                        </div>
                      </div>
                      <div>
                        <Button
                          disabled={state.commentloading}
                          isLoading={state.commentloading}
                          themeType={'transparent'}
                          className="moreBtn"
                        >
                          <Icons.MoreVertical />
                        </Button>
                      </div>
                    </div>
                    <div className="comment-description">
                      <p>{comment.comment}</p>
                    </div>
                  </div>
                ))}
              <hr />
            </div>
          ) : (
            <div className="m-auto NoComment d-flex flex-column align-items-center justify-content-center">
              <h3>No Comment Yet</h3>
              <Button
                disabled={state.commentloading}
                isLoading={state.commentloading}
                themeType="primary"
                onClick={() => {
                  if (!userToken) {
                    history.push('/user?page=login')
                    return
                  }
                  // focus on the textarea
                  if (commentInput?.current) {
                    commentInput.current.focus()
                  }
                }}
              >
                Write a Review
              </Button>
            </div>
          )}
        </div>
      </Drawer>
    </>
  )
}

export default MobileReviewSection
