import { Container } from 'reactstrap'
import SwiperCore, { Navigation } from 'swiper'

// Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react'
import SubCompoent from './SubComponet'
import 'swiper/css'
import { Fragment } from 'react'
import ShowMore from '../Common/ShowMore'
// install Swiper components
SwiperCore.use([Navigation])

const SilderView = ({
  list,
  heading,
  subComponet,
  more,
  index
}: {
  list: any[]
  heading: string
  subComponet: string
  more?: {
    link: string
    text: string
  }
  index?: number
}) => {
  const breakPoint = {
    // when window width is >= 320px
    1200: {
      slidesPerView: 4.4
    },
    700: {
      slidesPerView: 3.4
    },
    500: {
      slidesPerView: 2.4
    },
    320: {
      slidesPerView: 1.3
    }
  }

  if (list.length !== 0) {
    return (
      <Fragment>
        {index === 0 && <div className="content"></div>}
        <div
          className="container-fluid sliderparent"
          style={{ padding: '2rem 1rem' }}
        >
          <Container className="mb-4">
            <ShowMore heading={heading} link={more?.link} text={more?.text} />
          </Container>
          <div className="wrapper container m-auto">
            <Swiper
              className="w-100"
              breakpoints={breakPoint}
              spaceBetween={20}
              freeMode={true}
            >
              {list.map((item, index) => (
                <SwiperSlide key={index}>
                  <SubCompoent
                    key={index}
                    compoent={subComponet}
                    index={index}
                    item={item}
                  />
                </SwiperSlide>
              ))}
              {/* 
            <img className="swiper-button-next" src={Left} alt="right" />
            <img className="swiper-button-prev" src={Left} alt="right" /> */}
            </Swiper>
          </div>
        </div>
      </Fragment>
    )
  }
  return <h3 className="text">No Data Found</h3>
}

export default SilderView
