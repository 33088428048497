import Network from '../Connetion/Connection'

export const wordSuggestions = (word, callback) => {
  const HttpGet = {
    method: 'get',
    url: 'https://api.dictionaryapi.dev/api/v2/entries/en/' + word,
    headers: {
      'Content-Type': 'application/json'
    }
  }
  Network(HttpGet, (respones, error) => {
    if (error) {
      callback(null, error)
    } else {
      callback(respones, null)
    }
  })
}
