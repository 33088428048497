import { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTransition, animated } from 'react-spring'
import { linkbulder } from 'utils/ImageLinkBuilder'
import Loader from 'components/Home/LoaderSkelecton'
import Footer from 'components/Common/Footer'
import Carsoule from 'components/Home/Ad'
import CardGroup from 'components/Layouts/CardGroup'
import { pagesApi } from 'NetworkCall/RepoHandler/PageDataService'
import SimpleComponet from 'components/Layouts/SimpleCompoent'
import HeadingBanner from 'components/Home/JumboSection'
import Grouping from 'components/Layouts/GroupingView'
import CategoryHeading from 'components/Category/CategoryHeading'
import MobileNavTop from 'components/Common/MobileNavTop'
import { getCacheItem, setCacheItem } from 'NetworkCall/RepoHandler/cacheLayer'
const Home = () => {
  let { path, subpath } = useParams()
  const [sections, setSections] = useState([])
  const [isLoading, setLoader] = useState([true])
  const transitions = useTransition(isLoading, null, {
    from: {
      opacity: 0,
      transform: 'translate3d(0,100%,0)'
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0,0%,0)'
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(0,-50%,0)'
    }
  })

  useEffect(() => {
    setLoader(true)
    let query = '/'
    query = path ? path : query
    query = subpath ? query + '/' + subpath : query
    let cacheData = getCacheItem(query)
    if (cacheData) {
      setSections(cacheData)
      setLoader(false)
    }
    const request = pagesApi(query)
      .then(result => {
        setSections(result.data.sections)
        setCacheItem(query, result.data.sections)
        setLoader(false)
      })
      .catch(error => {
        console.log(error)
      })
    return () => {
      request.cancel()
    }
  }, [path, subpath])

  const groupingSection = (section, index) => {
    const groupingValue = section.component.group
    switch (groupingValue) {
      case 'main-heading': {
        return (
          <Grouping
            key={index}
            compoent={(sec, ind) => {
              return sectionMainCompoent(sec, ind)
            }}
            section={section}
            index={index}
          />
        )
      }

      default: {
        return sectionMainCompoent(section, index)
      }
    }
  }

  const sectionMainCompoent = (section, index) => {
    const mainCompoent = section.component.mainComponent
    const subCompoent = section.component.subComponent
    const showMore = section.more
    switch (mainCompoent) {
      case 'simpleComopontModule': {
        return (
          <SimpleComponet
            key={index}
            index={index}
            heading={section.title}
            list={section.items}
            layout={section.component.Layout}
            component={subCompoent}
            more={showMore}
          />
        )
      }
      case 'header-welcome': {
        return (
          <HeadingBanner
            key={index}
            heading={section.title}
            subtitle={section.subtitle}
          />
        )
      }

      case 'jumbotronContentCard': {
        return (
          <CardGroup
            key={index}
            heading={section.title}
            list={section.items}
            backgroundColor={section.backgoundColor}
            more={showMore}
          />
        )
      }

      case 'CategoryHeading': {
        return (
          <CategoryHeading
            key={index}
            heading={section.title}
            image={section.backgoundImage?.url}
          />
        )
      }

      default: {
        let link = null
        if (section.backgoundImage) {
          link = linkbulder('l', section.backgoundImage)
        }
        return <Carsoule key={index} link={link} />
      }
    }
  }

  return (
    <Fragment>
      <MobileNavTop title={'Home'} />
      {transitions.map(({ key, props }) =>
        isLoading ? (
          <animated.div style={props} key={key}>
            {' '}
            <Loader />{' '}
          </animated.div>
        ) : (
          <animated.div style={props} key={key}>
            {' '}
            {sections.map((section, index) => groupingSection(section, index))}
          </animated.div>
        )
      )}
      <Footer />
    </Fragment>
  )
}

export default Home
