import { Fragment, useCallback, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import JK from 'Images/jk.jpg'
import './ContentDetail.scss'
import ReviewSection from './ReviewSection'
import RecommendationSection from './RecommendationSection'
import HeroSection from './HeroSection'
import AuthorCard from './AuthorCard'
import VerticalContentList from './VerticalContentList'
import TableOfContent from './TableOfContent'
import SeriesComponent from './SeriesComponent'
import MobileReviewSection from './MobileReviewSection'
import { ArrowLeft } from 'react-feather'
import { Button } from 'mtrd'

const ContentDetailsPage = ({
  link,
  type,
  id,
  state,
  likedAction,
  libraryAction,
  userToken,
  handleInputChange,
  addComment,
  dataList1,
  dataList2
}: {
  link: any
  type: string
  id: string
  state: any
  likedAction: () => void
  libraryAction: () => any
  userToken: string | null
  handleInputChange: (event: any) => void
  addComment: () => void
  dataList1: any[]
  dataList2: any[]
}) => {
  const { description, author, comments } = state.data

  // for top bar
  const hidingTopFunc = useCallback(() => {
    let prevScrollVal = 0
    const hidingTop = document.getElementsByClassName('TopBar')[0]

    if (window.innerWidth < 992) {
      if (
        document.body.scrollTop > prevScrollVal ||
        document.documentElement.scrollTop > prevScrollVal
      ) {
        hidingTop?.classList.add('scrolled')
      } else {
        hidingTop?.classList.remove('scrolled')
      }
      prevScrollVal =
        document.body.scrollTop || document.documentElement.scrollTop
    }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', hidingTopFunc)

    return () => {
      window.removeEventListener('scroll', hidingTopFunc)
    }
  }, [hidingTopFunc])

  return (
    <Fragment>
      <div className="content">
        <MediaQuery minDeviceWidth={0} maxDeviceWidth={991}>
          <Row>
            <Col xs="12 p-0">
              <div className="TopBar position-fixed">
                <div className="container-fluid">
                  <div
                    className="backButton"
                    onClick={() => window.history.back()}
                  >
                    <ArrowLeft />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </MediaQuery>
      </div>

      <div className="m-auto NovelDetailWrapper">
        <HeroSection
          type={type}
          state={state}
          link={link}
          id={id}
          libraryAction={libraryAction}
          likedAction={likedAction}
        />
        {type === 'Series' && <SeriesComponent />}

        <Row className="mt-5 d-flex justify-content-between">
          <Col lg="6">
            <MediaQuery minDeviceWidth={992}>
              {type !== 'Series' && (
                <div className="aboutNovel">
                  <h3>About the {type}</h3>
                  <p>{description}</p>
                </div>
              )}
            </MediaQuery>
            {type === 'Novel' && <TableOfContent />}
            <MediaQuery minDeviceWidth={992}>
              <ReviewSection
                addComment={addComment}
                comments={comments}
                userToken={userToken}
                state={state}
                handleInputChange={handleInputChange}
                placeholder={JK}
              />
            </MediaQuery>
            <MediaQuery minDeviceWidth={0} maxDeviceWidth={991}>
              <hr className="mobileSectionDivider" />
              <MobileReviewSection
                addComment={addComment}
                comments={comments}
                userToken={userToken}
                state={state}
                handleInputChange={handleInputChange}
                placeholder={JK}
              />
              <hr className="mobileSectionDivider" />
              <div className="aboutNovel">
                <h3>About the {type}</h3>
                <p>{description}</p>
              </div>
              <hr className="mobileSectionDivider" />
            </MediaQuery>
          </Col>
          <Col lg="5">
            <AuthorCard author={author} />
            {type === 'Novel' && (
              <VerticalContentList
                title="More from author"
                data={dataList1.slice(0, 4)}
                url={'/morefromauthor'}
              />
            )}
            <VerticalContentList
              title="Based on your likes"
              data={dataList1.slice(0, 4)}
              url={'/basedonlikes'}
            />
          </Col>
        </Row>
        <RecommendationSection data={dataList2} />
      </div>
      <MediaQuery minDeviceWidth={0} maxDeviceWidth={991}>
        <div className="bottomNav">
          <div className="d-flex justify-content-center my-3">
            {state.isUser && (
              <Button
                themeType="outlined"
                onClick={libraryAction}
                disabled={state.loading}
                isLoading={state.loading}
                className="NovelAdd me-3"
              >
                {state.library.in ? 'Remove from' : 'Add to'} Library
              </Button>
            )}
            <Button
              className="NovelStart ms-3"
              href={'/reader/' + type + '/' + id}
            >
              Start Reading
            </Button>
          </div>
        </div>
      </MediaQuery>
    </Fragment>
  )
}

ContentDetailsPage.propTypes = {
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  type: PropTypes.string,
  id: PropTypes.string,
  state: PropTypes.object,
  likedAction: PropTypes.func,
  libraryAction: PropTypes.func,
  userToken: PropTypes.string,
  handleInputChange: PropTypes.func,
  addComment: PropTypes.func
}

export default ContentDetailsPage
