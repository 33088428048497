import { Fragment, useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Container,
  ModalHeader,
  Modal,
  ModalFooter,
  ModalBody,
  TabContent,
  TabPane,
  Badge
} from 'reactstrap'
import Footer from 'components/Common/Footer'
import CardImage from 'Images/book1.png'
import MenuDot from 'Icons/menu-dot.svg'
import MobileNavTop from 'components/Common/MobileNavTop'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Navbar from 'components/Common/Navbar'
import { getImage, NOVEL, POEM, STORY, writeupId } from 'utils'
import {
  deleteContentByUser,
  getContentByUser
} from 'NetworkCall/RepoHandler/contentDataService'
import WriteModalInner from 'components/Modal/WriteModalInner'
import 'Style/writerSection.scss'
import Image from 'components/Image'
import { Button, Loader } from 'mtrd'
import PropTypes from 'prop-types'

const WriterSection = () => {
  const [state, setState] = useState({
    stories: null,
    poems: null,
    novels: null,
    searchResults: null,
    isDeleteModalOpen: false,
    deleteItemDetails: {
      id: null,
      type: null
    },
    activeId: 1,
    isDeleting: false
  })

  const toggleDeleteModal = () => {
    setState(s => ({ ...s, isDeleteModalOpen: !state.isDeleteModalOpen }))
  }

  const draftCard = (item, pub) => {
    let link
    if (item.cover?.url) {
      link = getImage(item.cover?.url, 's', NOVEL)
    }

    return (
      <div
        className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3"
        key={writeupId(item, item.isA)}
      >
        <Card className="storyCard text-center mb-3 border-0 h-100 container-fluid">
          <Row className="h-100">
            <div className="col-12">
              <div className="d-flex flex-column justify-content-around h-100">
                <div className="imgAndText">
                  <div className="align-items-center d-flex flex-column storyCardLeft">
                    {pub && (
                      <Badge color="success" className="mb-3">
                        Published
                      </Badge>
                    )}
                    <Image
                      className="img-fluid storyImg"
                      srcs={link}
                      fallback={CardImage}
                      alt="Book1"
                    />
                  </div>
                  <div className="storyCardRight mt-3 mb-3">
                    <div className="storyTitleDate">
                      <h5 className="storyTitle">{item.name}</h5>
                      <span className="text-muted h6">
                        Last Updated:{' '}
                        {moment(item.contentUpdatedAt).format(
                          'MMM Do YYYY, h:mm a'
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mt-3 buttons">
                  <Link
                    to={`/typewriter/${item.isA}/${writeupId(item, item.isA)}`}
                  >
                    <button className="upanyas-primary mb-0">
                      Continue Writing
                    </button>
                  </Link>
                </div>
              </div>

              <div
                className="display-flex position-absolute float-right ms-auto"
                style={{ top: '10px', right: '7px' }}
              >
                <div className="float-right m-auto">
                  <button
                    className="menuDotBtn btn"
                    type="button"
                    id="menuDotDropdown"
                    data-bs-toggle="dropdown"
                  >
                    <img src={MenuDot} alt="menu-dot" width="22px" />
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="menuDotDropdown"
                  >
                    <span
                      className="dropdown-item"
                      onClick={() => {
                        setState(s => ({
                          ...s,
                          deleteItemDetails: {
                            type: item.isA,
                            id: writeupId(item, item.isA)
                          }
                        }))
                        toggleDeleteModal()
                      }}
                    >
                      Delete
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Card>
      </div>
    )
  }

  const loadStoryWriteup = () => {
    if (state.stories?.length > 0) {
      return state.stories.map(({ story }) => {
        const item = { ...story, isA: STORY }
        return draftCard(item, item.isPublished)
      })
    }
    return <p>No Stories Found</p>
  }

  const loadPoemWriteup = () => {
    if (state.poems?.length > 0) {
      return state.poems.map(({ poem }) => {
        const item = { ...poem, isA: POEM }
        return draftCard(item, item.isPublished)
      })
    }
    return <p>No Poem Found</p>
  }

  const loadNovelWriteup = () => {
    if (state.novels?.length > 0) {
      return state.novels.map(({ novel }) => {
        const item = { ...novel, isA: NOVEL }
        return draftCard(item, item.isPublished)
      })
    }
    return <p>No Novel Found</p>
  }

  const deleteWriteup = () => {
    setState(s => ({ ...s, isDeleting: true }))
    const { type, id } = state.deleteItemDetails
    if ([NOVEL, STORY, POEM].includes(type)) {
      deleteContentByUser(id, type)
        .then(() => {
          toast.success('Successfully Deleted!', {
            position: toast.POSITION.BOTTOM_LEFT
          })
          setState(s => ({ ...s, isDeleting: false }))

          getStoryWriteup()
          getPoemWriteup()
          getNovelWriteup()
          toggleDeleteModal()
        })
        .catch(error => {
          toast.error('Some Error Occured!', {
            position: toast.POSITION.BOTTOM_LEFT
          })
          console.log(error)
        })
    }
  }

  const getPoemWriteup = () => {
    getContentByUser(POEM)
      .then(result => {
        return setState(s => ({ ...s, poems: result.data }))
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getStoryWriteup = () => {
    getContentByUser(STORY)
      .then(result => {
        return setState(s => ({ ...s, stories: result.data }))
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getNovelWriteup = () => {
    getContentByUser(NOVEL)
      .then(result => {
        return setState(s => ({ ...s, novels: result.data }))
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    getPoemWriteup()
    getStoryWriteup()
    getNovelWriteup()
  }, [])

  const NavTabItem = ({ activeId, name }) => (
    <div
      className={`nav-tab-item${state.activeId === activeId ? ' active' : ''}`}
      onClick={() => setState(s => ({ ...s, activeId }))}
    >
      {name}
    </div>
  )

  NavTabItem.propTypes = {
    activeId: PropTypes.number.isRequired,
    name: PropTypes.string
  }
  return (
    <Fragment>
      <Navbar search placeholder="Search your content" />
      <MobileNavTop title={'Writer'} />
      <div className="content"></div>
      <Container fluid className="p-0 m-auto">
        <div className="row">
          <Col>
            <WriteModalInner />
            <div className="myStories">
              <Container fluid>
                <h3 className="font-weight-bold mb-4">Continue Writing</h3>
                <div className="mtrd-navs">
                  <NavTabItem activeId={1} name={'Short Stories'} />
                  <NavTabItem activeId={2} name={'Poems'} />
                  <NavTabItem activeId={3} name={'Novels'} />
                </div>
                <TabContent activeTab={state.activeId}>
                  <TabPane tabId={1}>
                    {state.stories === null ? (
                      <Loader fullScreen={false} />
                    ) : (
                      <Row>{loadStoryWriteup()}</Row>
                    )}
                  </TabPane>
                  <TabPane tabId={2}>
                    {state.poems === null ? (
                      <Loader fullScreen={false} />
                    ) : (
                      <Row>{loadPoemWriteup()}</Row>
                    )}
                  </TabPane>
                  <TabPane tabId={3}>
                    {state.novels === null ? (
                      <Loader fullScreen={false} />
                    ) : (
                      <Row>{loadNovelWriteup()}</Row>
                    )}
                  </TabPane>
                </TabContent>
              </Container>
            </div>
          </Col>
        </div>
      </Container>

      <Modal
        isOpen={state.isDeleteModalOpen}
        toggle={toggleDeleteModal}
        className="mtrd-modal"
      >
        <ModalHeader>Warning!</ModalHeader>
        <ModalBody>Are you sure you want to delete this write-up?</ModalBody>
        <ModalFooter>
          <Button themeType="secondary" onClick={toggleDeleteModal}>
            Close
          </Button>
          <Button
            onClick={deleteWriteup}
            disabled={state.isDeleting}
            isLoading={state.isDeleting}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>

      <Footer />
      {state.searchResults !== null && (
        <div className="searchSugg backdrop">
          <div className="searchSuggCard">{state.searchResults}</div>
        </div>
      )}
    </Fragment>
  )
  // }
}

export default WriterSection
