import { Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import ProgressBar from '../Common/ProgressBar'
import 'Style/libraryCard.scss'

const LibraryCard = ({
  lastRead: list,
  heading
}: {
  lastRead: any
  heading: string
}) => {
  if (list.length <= 0) return <></>

  return (
    <>
      <h3 className="mb-4" style={{ fontWeight: '600' }}>
        {heading}
      </h3>
      <Row>
        {list.map((item: any, index: number) => (
          <Col lg="12" xl="6" key={index} className="mb-4">
            <div className="LibCard d-flex">
              <div className="cover-holder me-3 position-relative">
                <img
                  className="align-self-center LibImg"
                  src={item.value.cover + '_s.png'}
                  alt="Generic placeholder"
                />
              </div>
              <div className="media-body w-100">
                <p className="LibTitle">{item.value.name}</p>
                <p className="LibAuthor">{item.value.author.name}</p>

                <div className="display-flex mt-13 mb-13">
                  <ProgressBar completed={item.Progress} />
                  <div className="RowBar">
                    {' '}
                    {`${Math.ceil(item.Progress)}%`}
                  </div>
                </div>
                <div
                  className="button-container"
                  style={{ paddingBottom: '10px' }}
                >
                  <Link
                    className="upanyas-secondary"
                    to={'/reader/' + item.value.isA + '/' + item.value._id}
                  >
                    Resume Reading
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </>
  )
}

export default LibraryCard
