import RequestHandler from '../Connetion/RequestBulider'
import endpoint from '../Connetion/endpoints'
import Network from '../Connetion/Connection'
import { Promise } from 'bluebird'
import { getPublishedContentById } from './contentDataService'
import { upperFirst } from 'lodash'
import { contentTypes } from '../../utils'

export const rejectResolveNetwork = (request, reject, resolve) =>
  Network(request, (response, error) => {
    if (error) {
      reject(error)
    }
    resolve(response)
  })

export const getCategory = () => {
  let request = RequestHandler.getRequest(
    endpoint.allCategory,
    null,
    RequestHandler.getMethod.get,
    null
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const getLanguage = () => {
  let request = RequestHandler.getRequest(
    endpoint.language,
    null,
    RequestHandler.getMethod.get,
    true
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const getGenres = (genreFor = 'content', language = 'english') => {
  let request = RequestHandler.getRequest(
    endpoint.listCategories,
    [
      ['for', genreFor],
      ['language', language]
    ],
    RequestHandler.getMethod.get,
    null
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const getDetail = (type, id) =>
  new Promise((resolve, reject) => {
    if (contentTypes.includes(type))
      getPublishedContentById(id, type).then(resolve).catch(reject)
    else reject()
  })

export const uploadImage = (type, id, image) => {
  const data = new FormData()
  data.append('imageFor', upperFirst(type))
  data.append('id', id)
  data.append('image', image)
  let request = RequestHandler.postRequest(
    endpoint.addImage,
    data,
    RequestHandler.postMethod.put,
    true
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const removeImageRequest = (type, id) => {
  const data = {
    imageFor: upperFirst(type),
    id
  }
  let request = RequestHandler.postRequest(
    endpoint.removeImage,
    data,
    RequestHandler.postMethod.put,
    false
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}

export const getListing = type => {
  const request = RequestHandler.getRequest(
    endpoint.listing + type,
    null,
    RequestHandler.getMethod.get,
    false
  )

  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}
