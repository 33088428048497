import Back from 'Icons/leftarrow.svg'
import { typeName } from 'utils'

const FormTopBar = ({ type }: { type: string }) => {
  return (
    <div className="container-fluid NavMain fixed-top">
      <div className="NavRow d-flex">
        <div className="btnLogoDiv d-flex align-items-center">
          <div className="text-center">
            <img
              onClick={() => window.history.back()}
              className="btn ms-4"
              src={Back}
              alt="logo"
              width="37px"
            />
          </div>
          <h5 className="ms-4 mb-0 font-weight-bold">
            Create A New {typeName(type)}
          </h5>
        </div>
      </div>
    </div>
  )
}

export default FormTopBar
