import moment from 'moment'
import { toast } from 'react-toastify'
import { POEM, STORY, writeupId } from 'utils'
import {
  createContent,
  updateContent
} from 'NetworkCall/RepoHandler/contentDataService'
import { Dispatch, SetStateAction } from 'react'

export const SaveContent = async (
  contentInfo: {
    name: string
    writeupId: null
    lastSaved: string
  },
  wtType: string,
  setContentInfo: Dispatch<
    SetStateAction<{
      name: string
      writeupId: null
      lastSaved: string
    }>
  >,
  previewContent: string,
  callback: any
) => {
  const onSuccess = (result: any) => {
    setContentInfo({
      ...contentInfo,
      writeupId: writeupId(result.data, wtType),
      lastSaved: 'Last Saved: ' + moment().format('MMMM Do YYYY, h:mm:ss a')
    })
    if (callback) callback(writeupId(result.data, wtType))
  }

  const onFail = (error: any) => {
    console.log(error)
    toast.error('Some Error Occurred!', {
      position: toast.POSITION.BOTTOM_LEFT
    })
  }

  // when creating
  if (contentInfo.writeupId === null) {
    if (wtType === STORY || wtType === POEM) {
      try {
        const result = await createContent(
          contentInfo.name,
          previewContent,
          wtType
        )

        onSuccess(result)
      } catch (error) {
        onFail(error)
      }
    }
  } else {
    // if updating a writeup
    if (wtType === STORY || wtType === POEM) {
      try {
        const result = await updateContent(contentInfo, previewContent, wtType)
        onSuccess(result)
      } catch (error) {
        onFail(error)
      }
    }
  }
}
