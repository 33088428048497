import { FC } from 'react'
import Button, { ButtonProps } from '../Button'

const Tag: FC<ButtonProps> = ({ className, ...props }) => {
  return (
    <Button isTag uppercase className={`mtrd-tag ${className}`} {...props} />
  )
}

export default Tag
