import {
  ChangeEventHandler,
  Dispatch,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import FormTopBar from './FormTopBar'
import ImagePreview from 'Images/imagePreview.svg'
import {
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap'
import { Form, Formik, useFormikContext } from 'formik'
import {
  capitalize,
  getImage,
  NOVEL,
  POEM,
  STORY,
  toastWarn,
  typeName
} from 'utils'
import './PublishForm.scss'
import { X } from 'react-feather'
import {
  getContentById,
  publishContent,
  saveContentDetails
} from 'NetworkCall/RepoHandler/contentDataService'
import {
  getGenres,
  removeImageRequest,
  uploadImage
} from 'NetworkCall/RepoHandler/commonDataService'
import * as yup from 'yup'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import { Button, Input, Loader, Select, Tag } from 'mtrd'
import { withRouter } from 'react-router-dom'
import * as H from 'history'

const PublishForm = ({
  match: {
    params: { type, id }
  },
  history
}: {
  match: {
    params: { type: string; id: string }
  }
  history: H.History
}) => {
  const [selectedGenres, setSelectedGenres] = useState<any>([])
  const [selectedTags, setSelectedTags] = useState<any>([])
  const [genreModal, setGenreModal] = useState(false)
  const [tagInputValue, setTagInputValue] = useState('')
  const [genres, setGenres] = useState<any>([])
  const [formData, setFormData] = useState<any>({})
  const [removeImageModal, setRemoveImageModal] = useState(false)
  const [coverImage, setCoverImage] = useState<{
    file: string
    coverImg: File | null
  }>({
    file: ImagePreview,
    coverImg: null
  })
  const inputReference = useRef<HTMLInputElement>(null)

  const genreModalToggle = () => setGenreModal(!genreModal)

  const removeModalToggle = () => setRemoveImageModal(!removeImageModal)

  const selectedGenreToggle = (id: string) => {
    const exisitingGenre = selectedGenres.find((genre: any) => genre.id === id)
    if (!exisitingGenre) {
      const selectedGenre = genres.find((genre: any) => genre.id === id)
      setSelectedGenres([...selectedGenres, selectedGenre])
    } else {
      const updatedGenres = selectedGenres.filter(
        (genre: any) => genre.id !== id
      )
      setSelectedGenres(updatedGenres)
    }
  }

  const removeTag = (tagToRemove: string) => {
    const updatedGenres = selectedTags.filter(
      (tag: string) => tag.toLowerCase() !== tagToRemove.toLowerCase()
    )
    return setSelectedTags(updatedGenres)
  }

  const handleTagsInput: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const existingTag = selectedTags.find(
        (tag: string) =>
          tag.toLowerCase() ===
          (e.target as HTMLInputElement).value.toLowerCase()
      )
      if (!existingTag && tagInputValue.length > 0) {
        setSelectedTags([...selectedTags, tagInputValue])
        ;(e.target as HTMLInputElement).value = ''
        return setTagInputValue('')
      } else if (existingTag) {
        return toastWarn('Tag already exists!')
      } else if (tagInputValue.length === 0) {
        return toastWarn('Tag cannot be empty!')
      }
    }
  }

  const loadGenres = useCallback(
    () =>
      getGenres().then(({ data }: { data: any }) => {
        const listOfCategory = data.map(
          ({ categoryID, name }: { categoryID: string; name: string }) => ({
            id: categoryID,
            title: name
          })
        )
        return setGenres(listOfCategory)
      }),
    []
  )

  const loadDetails = useCallback(() => {
    if ([NOVEL, POEM, STORY].includes(type)) {
      getContentById(id, type)
        .then(({ data }: { data: any }) => {
          setFormData({
            name: data.name || '',
            description: data.description || '',
            language: data.language || 'English',
            leadCharacterName: data.leadCharacterName || '',
            leadCharacterGender: data.leadCharacterGender || 'Male',
            contentWarning: data.contentWarning || 'implicit',
            genre: data.genre,
            tags: data.tags,
            cover: data.cover,
            isPublished: data.isPublished
          })
        })
        .catch((error: any) => {
          console.log(error)
          history.push('/writer')
        })
    }
  }, [id, type, history])

  const fileUploadAction = () => {
    if (inputReference.current) inputReference.current.click()
  }

  const handleImageChange: ChangeEventHandler = event => {
    const target = event?.target as HTMLInputElement
    if (target?.files![0]) {
      setCoverImage({
        file: URL.createObjectURL(target.files[0]),
        coverImg: target.files[0]
      })
    }
  }

  const getImageUrl = async () => {
    const {
      data: {
        cover: { url }
      }
    } = await getContentById(id, type)
    return url
  }

  const removeImage = async () => {
    let link
    removeModalToggle()
    const toastMsg = toast.loading(`Hang on while we remove the image.`, {
      position: 'bottom-center'
    })
    let imageUrl = await getImageUrl()

    if (!coverImage.coverImg && imageUrl) {
      await removeImageRequest(type, id)
      imageUrl = await getImageUrl()
    }
    toast.update(toastMsg, {
      render: `Image removed successfully!`,
      type: 'success',
      isLoading: false,
      position: 'bottom-center',
      autoClose: 4000,
      closeOnClick: true
    })
    if (imageUrl) {
      link = getImage(formData.cover?.url, 's', NOVEL, 'png')
    }
    if (inputReference.current) inputReference.current.value = ''
    setCoverImage({
      file: link || ImagePreview,
      coverImg: null
    })
  }

  useEffect(() => {
    if (!isEmpty(formData.genre)) {
      const filteredGenres = genres.filter(({ id }: { id: string }) =>
        formData.genre.includes(id)
      )
      setSelectedGenres(filteredGenres)
    }

    if (!isEmpty(formData.tags)) {
      setSelectedTags(formData.tags)
    }

    if (!isEmpty(formData.cover?.url)) {
      const link = getImage(formData.cover?.url, 's', NOVEL, 'png')
      setCoverImage({ file: link, coverImg: null })
    }
  }, [formData, genres])

  useEffect(() => {
    if (id !== undefined) {
      loadGenres()
      loadDetails()
    }
  }, [loadDetails, id, type, loadGenres])

  const getGenreIds = selectedGenres.map((genre: any) => genre.id)

  const validationSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    description: yup.string(),
    language: yup.string().required('Language is required'),
    leadCharacterName: yup.string(),
    leadCharacterGender: yup.string(),
    contentWarning: yup.string().required('Content Warning is required'),
    genre: yup
      .array()
      .of(yup.string().required())
      .min(2, 'Select atleast 2 genre'),
    tags: yup.array().of(yup.string().required())
  })

  const closeBtn = (close: () => void) => (
    <button className="bg-transparent border-0" onClick={close}>
      <X size={20} />
    </button>
  )

  const TagsLabel = () => (
    <>
      Tags{' '}
      <span className="small fw-500 text-black-50">
        (Stories with appropriate tags have a higher chance of appearing in
        searches)
      </span>
    </>
  )

  const UpdateGenre = () => {
    const { setFieldValue } = useFormikContext()
    useEffect(() => {
      if (!isEmpty(getGenreIds)) {
        setFieldValue('genre', getGenreIds)
      }
    }, [setFieldValue])
    return null
  }

  const addImage = async () => {
    if (coverImage.coverImg) {
      try {
        await uploadImage(type, id, coverImage.coverImg)
      } catch (error) {
        toast.error('Some error occured while uploading image', {
          position: 'bottom-center'
        })
        console.log(error)
      }
    }
  }

  const updateContentDetails = async (
    values: any,
    setSubmitting: Dispatch<any>,
    action: string
  ) => {
    const formData = { ...values, tags: [...selectedTags] }
    const toastMsg = toast.loading(
      `Hang on while we ${action} your amazing ${typeName(type)}...`,
      {
        position: 'bottom-center'
      }
    )
    try {
      await saveContentDetails(id, formData, type)
      await addImage()
      if (action === 'publish' && !formData.isPublished) {
        await publishContent(id, type)
      }
      const actionType = action === 'save' ? 'Saved' : 'Published'
      toast.update(toastMsg, {
        render: `${typeName(type)} ${actionType} Successfully!`,
        type: 'success',
        isLoading: false,
        position: 'bottom-center',
        autoClose: 4000,
        closeOnClick: true
      })
      setSubmitting(false)
      if (action === 'publish' || formData.isPublished) {
        return history.push(`/detail/${capitalize(type)}/${id}`)
      }
      // loadDetails();
    } catch (error) {
      toast.update(toastMsg, {
        render: `Some error occured!`,
        type: 'error',
        isLoading: false,
        position: 'bottom-center',
        autoClose: 4000
      })
      console.log(error)
    }
  }

  const languageOptions: {
    label: string
    value: string
    type?: 'option' | 'heading'
  }[] = [
    { label: 'Preferred languages', value: '', type: 'heading' },
    {
      label: 'English',
      value: 'English'
    },
    {
      label: 'Hindi',
      value: 'Hindi'
    },
    {
      label: 'More languages',
      value: '',
      type: 'heading'
    },
    {
      label: 'Tamil',
      value: 'Tamil'
    }
  ]

  const genderOptions = [
    {
      label: 'Male',
      value: 'male'
    },
    {
      label: 'Female',
      value: 'female'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ]

  const contentWarningOptions = [
    {
      label: '18+',
      value: 'explicit'
    },
    {
      label: 'Below 18',
      value: 'implicit'
    }
  ]

  if (isEmpty(formData) || isEmpty(genres)) {
    return <Loader />
  }

  return (
    <>
      <FormTopBar type={type} />
      <div className="content"></div>
      <Container className="pb-5 pt-4 pt-lg-0">
        <Row>
          <Col xs="12" md="4" className="d-md-flex justify-content-md-center">
            <div className="PublishFormImgHolder py-0 d-flex flex-column align-items-center mt-5">
              <div className="img-and-remove-button-holder position-relative">
                <img
                  className="PublishFormImgPreview"
                  src={coverImage.file}
                  alt="Default Preview"
                />
                {coverImage.file && coverImage.file !== ImagePreview && (
                  <Button
                    onClick={removeModalToggle}
                    className="remove-image position-absolute"
                    startIcon="X"
                  ></Button>
                )}
              </div>

              <Button
                themeType="secondary"
                className="mt-3"
                startIcon="Upload"
                onClick={fileUploadAction}
              >
                Image Upload
              </Button>
              <input
                type="file"
                accept="image/*"
                hidden
                ref={inputReference}
                onChange={handleImageChange}
              />
            </div>
          </Col>
          <Col xs="12" md="7">
            <Formik
              initialValues={formData}
              enableReinitialize
              validateOnBlur={false}
              onSubmit={() => {}}
              validationSchema={validationSchema}
            >
              {formikProps => {
                const {
                  values,
                  errors,
                  handleChange,
                  isSubmitting,
                  isValid,
                  setSubmitting,
                  validateForm,
                  setFieldValue
                } = formikProps
                return (
                  <Form
                    onSubmit={e => e.preventDefault()}
                    autoComplete="off"
                    className="px-4 px-md-2 px-lg-0"
                  >
                    <div className="mb-4">
                      <Input
                        name="name"
                        value={values.name}
                        label="Name"
                        placeholder="Enter the name"
                        error={errors.name?.toString()}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="description"
                        className="mtrd-input-label mb-2"
                      >
                        Description
                      </label>
                      <textarea
                        value={values.description}
                        className="mtrd-input"
                        name="description"
                        placeholder="Enter the description"
                        rows={4}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="mb-4">
                      <Select
                        label="Language"
                        placeholder="Select a language"
                        options={languageOptions}
                        value={values.language}
                        name="language"
                        setFieldValue={val => setFieldValue('language', val)}
                      />
                      {errors.language && (
                        <div className="error-message small text-danger fw-500">
                          {errors.language}
                        </div>
                      )}
                    </div>
                    {type !== POEM && (
                      <>
                        <div className="mb-4">
                          <Input
                            name="leadCharacterName"
                            value={values.leadCharacterName}
                            label="Leader Character Name"
                            placeholder="Enter the lead character's name"
                            error={errors.leadCharacterName?.toString()}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mb-4">
                          <Select
                            label="Lead Character Gender"
                            placeholder="Select a gender"
                            options={genderOptions}
                            value={values.leadCharacterGender}
                            name="leadCharacterGender"
                            setFieldValue={val =>
                              setFieldValue('leadCharacterGender', val)
                            }
                          />
                        </div>
                      </>
                    )}
                    <div className="mb-4">
                      <Select
                        label="Content Warning"
                        placeholder="Select Content Warning"
                        options={contentWarningOptions}
                        value={values.contentWarning}
                        name="contentWarning"
                        setFieldValue={val =>
                          setFieldValue('contentWarning', val)
                        }
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="genre" className="mtrd-input-label mb-2">
                        Genre
                      </label>
                      <div className="genres-container d-flex align-items-center flex-wrap">
                        {selectedGenres.map(
                          ({ id, title }: { id: string; title: string }) => (
                            <Tag
                              onKeyUp={e => e.preventDefault()}
                              className="me-2 mb-2 genre-tag"
                              onClick={() => selectedGenreToggle(id)}
                              key={id}
                              endIcon="X"
                              iconCustomSize="14"
                            >
                              {title}
                            </Tag>
                          )
                        )}
                        <Tag
                          themeType="secondary"
                          startIcon="Plus"
                          className="mb-2"
                          onClick={genreModalToggle}
                        >
                          Add genre
                        </Tag>
                        <UpdateGenre />
                      </div>
                      {errors.genre && (
                        <div className="error-message small text-danger fw-500">
                          {errors.genre}
                        </div>
                      )}
                    </div>
                    <div className="mb-4">
                      <Input
                        name="tagsField"
                        onChange={e => setTagInputValue(e.target.value)}
                        onKeyUp={handleTagsInput}
                        label={<TagsLabel />}
                        placeholder="Separate tags by Enter"
                        error={errors.tags?.toString()}
                      />
                      <div className="genres-container d-flex align-items-center flex-wrap mt-3">
                        {selectedTags.map((tag: string) => (
                          <Tag
                            className="me-2 mb-2"
                            themeType="secondary"
                            onClick={() => {
                              removeTag(tag)
                            }}
                            key={tag}
                            endIcon="XCircle"
                          >
                            {tag}
                          </Tag>
                        ))}
                      </div>
                    </div>
                    <Button
                      themeType="outlined"
                      className="me-3"
                      disabled={!isValid}
                      onClick={() => {
                        validateForm().then(value => {
                          if (isEmpty(value)) {
                            updateContentDetails(values, setSubmitting, 'save')
                          }
                        })
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      disabled={!isValid}
                      onClick={() => {
                        validateForm().then(value => {
                          if (isEmpty(value)) {
                            updateContentDetails(
                              values,
                              setSubmitting,
                              'publish'
                            )
                          }
                        })
                      }}
                      isLoading={isSubmitting}
                    >
                      Publish
                    </Button>
                  </Form>
                )
              }}
            </Formik>
          </Col>
        </Row>
      </Container>

      <Modal
        isOpen={removeImageModal}
        toggle={removeModalToggle}
        className="mtrd-modal genre-modal"
      >
        <ModalHeader
          toggle={removeModalToggle}
          close={closeBtn(removeModalToggle)}
        >
          Remove Image <span className=""></span>
        </ModalHeader>
        <ModalBody className="pb-1">
          Are you sure you want to remove this image?
        </ModalBody>
        <ModalFooter>
          <Button themeType="secondary" onClick={removeModalToggle}>
            No
          </Button>
          <Button onClick={removeImage}>Yes, Delete</Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={genreModal}
        toggle={genreModalToggle}
        className="mtrd-modal genre-modal"
      >
        <ModalHeader
          toggle={genreModalToggle}
          close={closeBtn(genreModalToggle)}
        >
          Select Genres <span className=""></span>
        </ModalHeader>
        <ModalBody className="pb-1">
          <div className="d-flex flex-wrap justify-content-start">
            {genres.map(({ id, title }: { id: string; title: string }) => (
              <Tag
                key={id}
                onClick={() => selectedGenreToggle(id)}
                themeType={
                  selectedGenres.find((genre: any) => genre.id === id)
                    ? 'secondary'
                    : 'primary'
                }
                className="mb-2 me-2"
              >
                {title}
              </Tag>
            ))}
          </div>
        </ModalBody>
        <ModalFooter className="pt-0">
          <Button onClick={genreModalToggle}>Done</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default withRouter(PublishForm)
