import { Link } from 'react-router-dom'

const ShowMore = ({
  heading,
  link,
  text
}: {
  heading: string
  link?: string
  text?: string
}) => (
  <div className="d-flex justify-content-between align-items-center">
    <h3 className="font-weight-bold">{heading}</h3>
    {text && link && (
      <Link to={link}>
        <span className="show-more">{text}</span>
      </Link>
    )}
  </div>
)

export default ShowMore
