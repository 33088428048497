import { ChangeEvent, useState } from 'react'
import { ForgotPasword } from 'NetworkCall/RepoHandler/userDataService'
import { toast } from 'react-toastify'
import FadeIn from 'react-fade-in'
import { Button, Input } from 'mtrd'

const ForgotPassword = ({
  navigtionAction
}: {
  navigtionAction: () => void
}) => {
  const [state, setState] = useState({
    email: '',
    isDisabled: false
  })

  const emailValidation = (email: string) => {
    if (email === '') {
      return false
    }
    const lastAtPos = email.lastIndexOf('@')
    const lastDotPos = email.lastIndexOf('.')
    return (
      lastAtPos < lastDotPos &&
      lastAtPos > 0 &&
      email.indexOf('@@') === -1 &&
      lastDotPos > 2 &&
      email.length - lastDotPos > 2
    )
  }

  const handleInputChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement
    const value = target.value
    setState({
      ...state,
      email: value
    })
  }

  const onSubmit = () => {
    setState({ ...state, isDisabled: true })
    const { email } = state
    if (!emailValidation(email)) {
      toast.warn('Enter an valid email', {
        position: 'top-right'
      })
      setState({ ...state, isDisabled: false })
      return
    }
    ForgotPasword(email)
      .then((result: any) => {
        setState({ ...state, isDisabled: false })
        if (result.error === false) {
          toast.success(
            'verification email has been sent to the given email address',
            {
              position: 'top-right'
            }
          )
        }
      })
      .catch((error: any) => {
        setState({ ...state, isDisabled: false })
        toast.error(error.message.response, { position: 'top-right' })
      })
  }

  const { isDisabled } = state
  return (
    <FadeIn>
      <div className="block">
        <div className="text mb-3">Forgot Password?</div>
        <div className="form">
          <p>
            Fill your Registered email and we will sent you a link to change
            your password
          </p>
          <div>
            <Input
              name={'Email'}
              type={'Email'}
              id={'Email'}
              placeholder={'Enter Email'}
              className="mt-3"
              onChange={handleInputChange}
            />
            <Button
              onClick={onSubmit}
              isLoading={isDisabled}
              className="w-100 mt-4"
            >
              Get your New Password
            </Button>
            <div className="login">
              <li style={{ listStyle: 'none' }}>
                <h6>
                  Don’t have an Account?
                  <button
                    className="btn ms-1"
                    style={{ color: '#fa7925', fontWeight: 'bold' }}
                    onClick={navigtionAction}
                  >
                    {' '}
                    Sign Up
                  </button>
                </h6>
              </li>
            </div>
          </div>
        </div>
      </div>
    </FadeIn>
  )
}

export default ForgotPassword
