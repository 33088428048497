import PropTypes from 'prop-types'
import placeholder from 'Images/CategoryPlaceholder.svg'
import Image from '../Image'
import { getImage } from 'utils'

const CategoryHeading = ({ image }: { image?: string }) => {
  const link = getImage(image, 'm', 'category', 'png')
  return (
    <>
      <div className="content"></div>
      <Image className="catgoryPage" fallback={placeholder} srcs={[link]} />
    </>
  )
}

CategoryHeading.propTypes = {
  heading: PropTypes.string,
  image: PropTypes.string
}

export default CategoryHeading
