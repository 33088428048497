import FacebookButton from '../SocialButton/FacebookLogin'
import GoogleButton from '../SocialButton/GoogleButton'
import { toast } from 'react-toastify'
import { userRegister } from 'NetworkCall/RepoHandler/userDataService'
import FadeIn from 'react-fade-in'
import { Button, Input } from 'mtrd'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import { EMAIL_REGEX } from 'utils'
import { KeyboardEventHandler } from 'react'

const SignUpForm = ({
  urlParam,
  navigtionAction
}: {
  urlParam?: string
  navigtionAction: () => void
}) => {
  const fields = [
    { name: 'Full Name', value: 'fullName', type: 'name' },
    { name: 'Email', value: 'email', type: 'email' },
    { name: 'Password', value: 'password', type: 'password' },
    { name: 'Confirm Password', value: 'confirmPassword', type: 'password' }
  ]

  const validationSchema = yup.object().shape({
    fullName: yup.string().required('Full Name is required'),
    email: yup
      .string()
      .email('Enter a valid email')
      .required('Required Field')
      .matches(EMAIL_REGEX, 'Enter a valid email'),
    password: yup
      .string()
      .min(6, 'Password should be greater than 6 characters')
      .required('Password is required'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required')
  })

  const userLogin = ({
    data: { token, refreshToken }
  }: {
    data: { token: string; refreshToken: string }
  }) => {
    localStorage.setItem('auth-token', token)
    localStorage.setItem('RfToken', refreshToken)
    if (urlParam) window.close()
    else {
      window.open(
        window.location.protocol + '//' + window.location.host + '/',
        '_self'
      )
    }
  }

  const sendDataAction = async ({
    fullName,
    email,
    password
  }: {
    fullName: string
    email: string
    password: string
  }) => {
    const request = userRegister(fullName, email, password, null, null)
      .then(userLogin)
      .catch((error: any) => {
        request.cancel()
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  const onKeyDown: KeyboardEventHandler<HTMLFormElement> = keyEvent => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13)
      keyEvent.preventDefault()
  }

  return (
    <FadeIn>
      <div className="text mb-4">Sign Up</div>
      <Formik
        initialValues={{
          fullName: '',
          email: '',
          password: '',
          confirmPassword: ''
        }}
        enableReinitialize
        validateOnBlur={false}
        onSubmit={async (values, { setSubmitting }) => {
          await sendDataAction(values)
          setSubmitting(false)
        }}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {({ values, errors, handleChange, isSubmitting, isValid }) => (
          <Form
            onKeyDown={onKeyDown}
            autoComplete="off"
            className="px-4 px-md-2 px-lg-0"
          >
            {fields.map(({ value, type, name }) => (
              <Input
                key={value}
                name={value}
                type={type}
                value={(values as any)[value]}
                placeholder={'Enter ' + name}
                onChange={handleChange}
                className="mt-3"
                error={(errors as any)[value]}
              />
            ))}
            <Button
              disabled={!isValid}
              type="submit"
              className="w-100 mt-4"
              isLoading={isSubmitting}
            >
              Sign Up
            </Button>
          </Form>
        )}
      </Formik>

      <div className="login">
        <h6>
          Already have a Account?
          <button
            className="btn ms-1"
            style={{ color: '#fa7925', fontWeight: 'bold' }}
            onClick={navigtionAction}
          >
            Login
          </button>
        </h6>
      </div>
      <h5 style={{ textAlign: 'center', color: 'grey' }}>or</h5>
      <div style={{ marginTop: '30px', display: 'flex' }}>
        <GoogleButton action="Sign up" urlParam={urlParam} />
        <FacebookButton action="Sign up" urlParam={urlParam} />
      </div>
    </FadeIn>
  )
}

export default SignUpForm
