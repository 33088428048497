import { ComponentProps, FC, Fragment } from 'react'
import { Link } from 'react-router-dom'
import * as Icon from 'react-feather'
import '../MTRD.scss'
import { ClipLoader } from 'react-spinners'

export interface ButtonProps extends ComponentProps<'button'> {
  href?: string
  themeType?: 'primary' | 'secondary' | 'outlined' | 'transparent'
  uppercase?: boolean
  isTag?: boolean
  startIcon?: keyof typeof Icon
  endIcon?: keyof typeof Icon
  isLoading?: boolean
  small?: boolean
  iconCustomSize?: string
}

const Button: FC<ButtonProps> = ({
  children,
  href,
  type,
  themeType,
  className,
  isTag,
  uppercase,
  startIcon,
  endIcon,
  isLoading,
  small,
  iconCustomSize,
  ...props
}) => {
  const iconSize = isTag ? 18 : 20
  let FIcon: FC<Icon.IconProps>
  if (startIcon) {
    FIcon = Icon[startIcon]
  } else if (endIcon) {
    FIcon = Icon[endIcon]
  }
  let loadingColor = '#ffffff'
  let buttonThemeType = 'mtrd-secondary'
  if (themeType === 'secondary') {
    buttonThemeType = 'mtrd-secondary'
    loadingColor = '#d34949'
  } else if (themeType === 'outlined') {
    buttonThemeType = 'mtrd-outlined'
    loadingColor = '#181818'
  } else if (themeType === 'transparent') {
    buttonThemeType = 'mtrd-transparent'
  } else if (themeType === 'primary') {
    buttonThemeType = 'mtrd-primary'
  }

  const CustomButton = () => (
    <button
      className={`mtrd-button ${buttonThemeType} ${
        uppercase ? 'text-uppercase' : 'text-capitalize'
      } ${className} ${!children && 'only-icon'} ${small ? 'small' : ''}`}
      type={type}
      disabled={isLoading}
      {...props}
    >
      <div className="d-flex align-items-center justify-content-center">
        {isLoading ? (
          <ClipLoader size={15} color={loadingColor} loading={isLoading} />
        ) : (
          <Fragment>
            {startIcon && (
              <FIcon
                className={`${children && 'me-1'}`}
                size={iconCustomSize || iconSize}
              />
            )}
            <span>{children}</span>
            {endIcon && (
              <FIcon
                className={`${children && 'ms-1'}`}
                size={iconCustomSize || iconSize}
              />
            )}
          </Fragment>
        )}
      </div>
    </button>
  )
  return (
    <Fragment>
      {href ? (
        <Link to={href}>
          <CustomButton />
        </Link>
      ) : (
        <CustomButton />
      )}
    </Fragment>
  )
}

Button.defaultProps = {
  type: 'button',
  isTag: false,
  className: '',
  uppercase: false,
  themeType: 'primary',
  isLoading: false,
  small: false
}

export default Button
