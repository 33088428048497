import WriteModalInner from './WriteModalInner'
import { Link } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'

const WriteModal = ({
  modal,
  toggle
}: {
  modal: boolean
  toggle: () => void
}) => (
  <Modal className="writeNew" isOpen={modal} toggle={toggle} size="lg">
    <ModalBody>
      <p className="wtModalHeading text-center font-weight-bold mt-4">
        Pen down the next best...
      </p>
      <WriteModalInner height="150px" />
      <p className="writeContinue text-muted text-center mb-4">
        or{' '}
        <Link to="/writer">
          <span data-dismiss="modal" className="font-weight-bold">
            continue
          </span>
        </Link>{' '}
        from where you left.
      </p>
    </ModalBody>
  </Modal>
)

export default WriteModal
