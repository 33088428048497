import { Fragment } from 'react'
import './ContentDetail.scss'
import Slider from '../Layouts/Slider'

const RecommendationSection = ({ data }: { data: any }) => {
  return (
    <Fragment>
      {data && (
        <Slider
          list={data}
          heading="Recommended Short Stories"
          subComponet="textbesideMedia"
        />
      )}
    </Fragment>
  )
}

export default RecommendationSection
