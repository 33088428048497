import { Fragment } from 'react'
import { Row, Col, Container } from 'reactstrap'
import Navbar from 'components/Common/Navbar'
import Footer from 'components/Common/Footer'
import MobileNavTop from 'components/Common/MobileNavTop'
import NotificationCard from 'components/Notification/NotificationCard'
import 'Style/notification.scss'

const Notification = () => {
  const notifData = [
    {
      id: 'a',
      title: 'My bride shall only be death',
      content:
        "This was Bhagat Singh's response to his parents trying to get him married. He left home for Kanpur and said that he would never marry in 'slave India'."
    },
    {
      id: 'b',
      title: 'Amazing Indian Innovations',
      content:
        "Did you know that Amul is India's oldest start-up? The stories behind some of these stunning homegrown innovations will inspire you."
    }
  ]

  return (
    <Fragment>
      <Navbar />

      <MobileNavTop title={'Notifications'} />
      <div className="content"></div>
      <Container>
        <Row className="mt-4 m-auto">
          <Col>
            <Row className="mainCardRowNotif">
              <Col xs="12">
                <h2 style={{ fontWeight: '600' }}>Notifications</h2>
              </Col>
              <Col xs="12">
                <div className="notifMain mt-4">
                  {notifData.map(notif => (
                    <NotificationCard
                      title={notif.title}
                      content={notif.content}
                      key={notif.id}
                    />
                  ))}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Footer />
    </Fragment>
  )
}

export default Notification
