import PropTypes from 'prop-types'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Row, Col, Container } from 'reactstrap'
import { getActiveChapterContent, NOVEL, toolbarConfig } from 'utils'
import { X } from 'react-feather'
import RichTextEditor, { getTextAlignClassName } from 'react-rte'
import ManageChaptersModal from './ManageChaptersModal'
import RenameChapterModal from './RenameChapterModal'
import TypewriterNavBar from './TypewriterNavBar'
import TypewriterSidebar from './TypewriterSidebar'

const Typewriter = ({
  setContentInfo,
  contentInfo,
  editorContent,
  saveDraftBtn,
  publishDraft,
  type,
  setEditorContent,
  chapters,
  GiveEditToc,
  setData,
  addChapterToNovel,
  isAddingChapter,
  setChapterContentToEditor,
  activeChapterID,
  updateChapterData,
  updateChapterArrangement,
  details
}) => {
  const [isChaptersModalOpen, setIsChaptersModalOpen] = useState(false)
  const [oldChaptersOrder, setOldChaptersOrder] = useState([])
  const [renameChapter, setRenameChapter] = useState({
    open: false,
    id: null,
    name: ''
  })
  const chaptersListRef = useRef({})

  const chaptersModalToggle = () => {
    setIsChaptersModalOpen(!isChaptersModalOpen)
  }

  const renameChapterModalToggle = (id, name) => {
    setRenameChapter({
      open: !!id,
      id,
      name
    })
  }

  const tableofc = () => {
    document.querySelector('.sideChapterbar').style.transform = 'translateX(0%)'
  }

  const cancelDragorder = () => {
    chaptersModalToggle()
    const items = [
      ...oldChaptersOrder.filter(oldChap =>
        chapters.find(
          chap => chap.chapter.chapterID === oldChap.chapter.chapterID
        )
      )
    ]
    for (var i = 0; i < items.length; i++) {
      items[i].index = `${i + 1}`
    }
    setData(items)
  }

  const openChaptersModal = () => {
    chaptersModalToggle()
    setOldChaptersOrder(chapters)
  }

  const closeBtn = close => (
    <button className="bg-transparent border-0" onClick={close}>
      <X size={20} />
    </button>
  )

  const updateContent = value => {
    if (type === NOVEL) {
      let chaptersCopy = [...chapters]
      const content = getActiveChapterContent(chapters, activeChapterID)
      if (content !== value) {
        chaptersCopy = chaptersCopy.map(chapter => {
          if (activeChapterID === chapter.chapter.chapterID) {
            return {
              ...chapter,
              chapter: {
                ...chapter.chapter,
                content: value
              }
            }
          }
          return { ...chapter }
        })
        setData(chaptersCopy)
      }
    } else setEditorContent(value)
  }

  const onEditorBlur = e => {
    e.stopPropagation()
    const { relatedTarget } = e
    if (relatedTarget) relatedTarget.click()
  }

  useEffect(() => {
    if (type === NOVEL) {
      const content = getActiveChapterContent(chapters, activeChapterID)
      setEditorContent(content)
    }
  }, [chapters, activeChapterID, type])

  // useEffect(() => {
  //   if (chaptersListRef.current && document.querySelector(".sideChapterbar")) {
  //     const sideChapterbarHeight =
  //       document.querySelector(".sideChapterbar").clientHeight;
  //     chaptersListRef.current.style.height = `${
  //       sideChapterbarHeight - 100 - 60
  //     }px`;
  //   }
  // }, [chaptersListRef]);

  return (
    <Fragment>
      <TypewriterNavBar
        contentInfo={contentInfo}
        publishDraft={publishDraft}
        saveDraftBtn={saveDraftBtn}
        setContentInfo={setContentInfo}
        tableofc={tableofc}
      />
      <div className="sidebar-and-editor-container">
        <div className="mainRowWriter d-flex">
          <TypewriterSidebar
            activeChapterID={activeChapterID}
            addChapterToNovel={addChapterToNovel}
            chapters={chapters}
            chaptersListRef={chaptersListRef}
            type={type}
            details={details}
            contentInfo={contentInfo}
            isAddingChapter={isAddingChapter}
            openChaptersModal={openChaptersModal}
            renameChapterModalToggle={renameChapterModalToggle}
            setChapterContentToEditor={setChapterContentToEditor}
          />
          <div className="editorRowCol w-100 mx-auto pe-0">
            <Container fluid className="pe-0 mt-4">
              <Row className="editorRow">
                <Col xs="12" lg="10" className="mx-auto">
                  <div className="editor poem position-relative">
                    <RichTextEditor
                      value={editorContent}
                      onChange={updateContent}
                      toolbarConfig={toolbarConfig}
                      blockStyleFn={getTextAlignClassName}
                      placeholder="Start writing from here..."
                      className="upanyas-rte"
                      toolbarClassName="upanyas-rte-toolbar"
                      editorClassName="upanyas-rte-editor"
                      onBlur={onEditorBlur}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <ManageChaptersModal
        isOpen={isChaptersModalOpen}
        toggle={chaptersModalToggle}
        closeBtn={closeBtn(cancelDragorder)}
        GiveEditToc={GiveEditToc}
        cancelDragorder={cancelDragorder}
        updateChapterArrangement={updateChapterArrangement}
        chaptersModalToggle={chaptersModalToggle}
      />
      {renameChapter.open && (
        <RenameChapterModal
          toggle={() => renameChapterModalToggle()}
          closeBtn={closeBtn}
          renameChapter={renameChapter}
          updateChapterData={updateChapterData}
          renameChapterModalToggle={renameChapterModalToggle}
        />
      )}
    </Fragment>
  )
}

Typewriter.propTypes = {
  setContentInfo: PropTypes.func.isRequired,
  contentInfo: PropTypes.object.isRequired,
  editorContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  saveDraftBtn: PropTypes.func.isRequired,
  publishDraft: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  chapters: PropTypes.array,
  setEditorContent: PropTypes.func.isRequired,
  GiveEditToc: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  setData: PropTypes.func.isRequired,
  addChapterToNovel: PropTypes.func.isRequired,
  isAddingChapter: PropTypes.bool.isRequired,
  setChapterContentToEditor: PropTypes.func.isRequired,
  activeChapterID: PropTypes.string,
  updateChapterData: PropTypes.func.isRequired,
  updateChapterArrangement: PropTypes.func.isRequired,
  details: PropTypes.object
}

export default Typewriter
