import { Link } from 'react-router-dom'
import ContentDetailsCard from './ContentDetailsCard'
import './VerticalContentList.scss'

const VerticalContentList = ({
  title,
  data,
  url
}: {
  title: string
  data: any[]
  url: string
}) => (
  <div className="basedOnLikesSection">
    <div className="d-flex justify-content-between">
      <h3>{title}</h3>
      <Link to={url} className="showMore">
        Show More
      </Link>
    </div>
    {data?.map((item, index) => (
      <ContentDetailsCard key={index} item={item} />
    ))}
    <hr
      className={window.innerWidth < 992 ? 'mobileSectionDivider' : 'd-none'}
    />
  </div>
)

export default VerticalContentList
