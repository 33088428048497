import { Col, Card } from 'reactstrap'

const DashboardCard = ({
  title,
  numbers
}: {
  title: string
  numbers: string
}) => (
  <Col xs="12" sm="6" xl="3">
    <Card className="active text-center mainCardCard">
      <div className="m-auto">
        <span className="mainCardTitle">{title}</span>
        <span className="mainCardNumber">{numbers}</span>
      </div>
    </Card>
  </Col>
)

export default DashboardCard
