import { Fragment, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import Back from 'Icons/leftarrow.svg'
import BackW from 'Icons/leftarrowW.svg'
import BackY from 'Icons/leftarrowY.svg'
import Dot from 'Icons/menu-dotOrange.svg'
import 'Style/reader.scss'
import tocIcon from 'Icons/toc.svg'
import tocOIcon from 'Icons/tocO.svg'
import tocWIcon from 'Icons/tocW.svg'
import tocYIcon from 'Icons/tocY.svg'
import readSet from 'Icons/readSet.svg'
import readSetO from 'Icons/readSetO.svg'
import readSetW from 'Icons/readSetW.svg'
import readSetY from 'Icons/readSetY.svg'
import Down from 'Icons/downarrow.svg'
import DownW from 'Icons/downarrowW.svg'
import DownY from 'Icons/downarrowY.svg'
import Notif from 'Icons/notifN.svg'
import NotifW from 'Icons/notifW.svg'
import NotifY from 'Icons/notifY.svg'
import account from 'Icons/account.svg'
import dashboard from 'Icons/library.svg'
import logout from 'Icons/logout.svg'
import fav from 'Icons/heart.svg'
import heart from 'Icons/likeHeart.svg'
import favW from 'Icons/heartW.svg'
import favY from 'Icons/heartY.svg'
import share from 'Icons/share.svg'
import shareW from 'Icons/shareW.svg'
import shareY from 'Icons/shareY.svg'
import comment from 'Icons/comment-text.svg'
import commentW from 'Icons/comment-textW.svg'
import commentY from 'Icons/comment-textY.svg'
import Slider from 'react-input-slider'
import { Button } from 'mtrd'
import { toast } from 'react-toastify'
import { debounce } from 'lodash'
import { getDetail } from 'NetworkCall/RepoHandler/commonDataService'
import { likeContent } from 'NetworkCall/RepoHandler/userDataService'
import {
  addTolibary,
  getLibrary,
  removeToLibary,
  updateProgress
} from 'NetworkCall/RepoHandler/LibraryDataService'
import { wordSuggestions } from 'NetworkCall/ThirdPartyApi/wordDictationary'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import Gravatar from 'react-gravatar'
import { UserContext } from 'context/UserDetailsContext'

var $ = document.querySelector.bind(document)
const Reader = ({ match, history }) => {
  const [state, setState] = useState({
    content: null,
    x: 5,
    title: '',
    chapterTitle: '',
    inLibrary: {
      id: '',
      in: false
    },
    loading: false,
    isFav: false,
    readSetBool: false,
    tocBool: false,
    bgColor: 'white',
    progHeight: 0,
    readPercent: 0,
    wordMeaning: 'Loading...',
    word: null,
    posX: null,
    posY: null
  })

  const likedMethod = debounce(() => {
    let { type, id } = match.params

    likeContent(type, id)
      .then(() => {})
      .catch(error => {
        toast.error(error.message, { position: toast.POSITION.BOTTOM_LEFT })
      })
  }, 150)

  const user = useContext(UserContext)

  //for table of content====================================================================
  const toctoggle = () => {
    if (state.tocBool === false) {
      if (state.bgColor === 'dark') {
        $('.tocIconParent').style.background = '#121212'
      } else if (state.bgColor === 'white') {
        $('.tocIconParent').style.background = '#f6f6f6'
      } else {
        $('.tocIconParent').style.background = '#f1e5ca'
      }
      $('.tocIcon').src = tocOIcon
      $('.tocParent').style.transform = 'translateX(0%)'
      setState({ ...state, tocBool: true })
      if (state.readSetBool) {
        readSettoggle()
      }
    } else {
      $('.tocIconParent').style.removeProperty('background')
      $('.tocIcon').src = tocIcon

      $('.tocParent').style.removeProperty('transform')
      if (state.bgColor === 'dark') {
        $('.tocIcon').src = tocWIcon
      } else if (state.bgColor === 'yellow') {
        $('.tocIcon').src = tocYIcon
      } else {
        $('.tocIcon').src = tocIcon
      }
      setState({ ...state, tocBool: false })
    }
  }

  //for toggling settings menu===========================================================================
  const readSettoggle = () => {
    if (state.readSetBool === false) {
      if (state.bgColor === 'dark') {
        $('.readSetIconParent').style.background = '#121212'
      } else if (state.bgColor === 'white') {
        $('.readSetIconParent').style.background = '#f6f6f6'
      } else {
        $('.readSetIconParent').style.background = '#f1e5ca'
      }

      $('.readSetIcon').src = readSetO
      $('.settingsMenu').style.display = 'block'
      setState({ ...state, readSetBool: true })
      if (state.tocBool) {
        toctoggle()
      }
    } else {
      $('.readSetIconParent').style.removeProperty('background')
      $('.settingsMenu').style.removeProperty('display')
      if (state.bgColor === 'dark') {
        $('.readSetIcon').src = readSetW
      } else if (state.bgColor === 'yellow') {
        $('.readSetIcon').src = readSetY
      } else {
        $('.readSetIcon').src = readSet
      }

      setState({ ...state, readSetBool: false })
    }
  }

  //for loading novels==============================================================================================
  const reading = () => {
    let { type, id } = match.params
    getDetail(type, id)
      .then(data => {
        if (data.isA === 'Novel') {
          // $(".readingMaterial").innerHTML = state.content;
          console.log(data)
        } else {
          setState({
            ...state,
            content: data.data.content,
            title: data.data.name,
            isFav: data.data.likedByUser
          })
          // $(".readingMaterial").innerHTML = state.content;
        }
      })
      .catch(error => {
        toast.error(error.message, { position: toast.POSITION.BOTTOM_LEFT })
      })
    let userToken = localStorage.getItem('auth-token')
    if (userToken) {
      getLibrary()
        .then(data => {
          libraryMapper(data, id)
        })
        .catch(error => {
          toast.error(error.message, { position: toast.POSITION.BOTTOM_LEFT })
        })
    }
  }

  const libraryMapper = (data, id) => {
    const libData = data instanceof Array ? data : data.data
    libData.map(res => {
      if (res.id) {
        let value = res.id._id === id ? true : false
        setState({ ...state, inLibrary: { id: res._id, in: value } })
      }
      return null
    })
  }

  const likedAction = () => {
    let userToken = localStorage.getItem('auth-token')
    if (userToken) {
      let like = state.isFav
      setState({ ...state, isFav: !like })
      likedMethod()
    }
  }

  const addTolibaryFunc = () => {
    setState({ ...state, loading: true })
    let { type, id } = match.params

    addTolibary(id, type)
      .then(data => {
        console.log(data.library)
        libraryMapper(data.library, id)
        setState({ ...state, loading: false })
      })
      .catch(error => {
        toast.error(error.message, { position: toast.POSITION.BOTTOM_LEFT })
        setState({ ...state, loading: false })
      })
  }
  const removeToLibaryFunc = () => {
    let { id } = state.library
    setState({ ...state, loading: true })

    removeToLibary(id)
      .then(data => {
        console.log(data)
        setState({
          ...state,
          inLibrary: { id: '', in: false },
          loading: false
        })
      })
      .catch(error => {
        toast.error(error.message, { position: toast.POSITION.BOTTOM_LEFT })
        setState({ ...state, loading: false })
      })
  }

  const libraryAction = () => {
    console.log('this ')
    if (state.inLibrary.in) {
      removeToLibaryFunc()
    } else {
      addTolibaryFunc()
    }
  }

  const haveMeaning = () => {
    return state.word && state.wordMeaning && state.posX && state.posY
  }
  //for selected text meaning======================================================================
  const textSelection = async event => {
    // If there is already a meaning dialog, remove it
    if (haveMeaning()) {
      setState({
        ...state,
        word: null,
        wordMeaning: null,
        posX: null,
        posY: null
      })
    }
    var text = ''
    if (window.getSelection) {
      text = window.getSelection().toString()
    } else if (document.selection && document.selection.type !== 'Control') {
      text = document.selection.createRange().text
    }
    text = text.trim()
    // Check if any text was selected
    if (text.length > 1 && !text.includes(' ')) {
      // Get selected text and encode it
      // const selection = encodeURIComponent(text).replace(/[!'()*]/g, escape);

      // Find out how much (if any) user has scrolled
      var scrollTop =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop
      var curposX = event.clientX
      var posX = curposX - 150
      var posY = 0
      // Get cursor position
      if (window.innerWidth - curposX < 180) {
        curposX = curposX - (window.innerWidth - curposX)
        posX = curposX - 150
      } else if (curposX < 180) {
        posX = 30
      }

      posY = event.clientY + 25 + scrollTop

      const log = (result, err) => {
        console.log(err)
        setState({
          ...state,
          wordMeaning:
            result?.length > 0
              ? result[0]?.meanings[0]?.definitions[0].definition
              : 'No Definition Found',
          word: text,
          posX,
          posY
        })
      }

      await wordSuggestions(text, log)
    }
  }

  //to change font size==================================================================================
  const fontSizeChange = size => {
    if (size > state.x) {
      fontIncrease($('.readingMaterial'), size)
    } else if (size < state.x) {
      fontDecrease($('.readingMaterial'), size)
    }
    setState({ ...state, x: size })
  }

  //to increase font size==============================================================================
  const fontIncrease = (inc, size) => {
    var c = inc.children
    var i
    var fs = 0
    if (c.length >= 0) {
      for (i = 0; i < c.length; i++) {
        fontIncrease(c[i], size)
        fs = parseInt(
          window.getComputedStyle(c[i], null).getPropertyValue('font-size')
        )
        fs = fs + (size - state.x)
        c[i].style.fontSize = fs + 'px'
      }
    }
  }

  //to decrease font size====================================================================================
  const fontDecrease = (dec, size) => {
    var c = dec.children
    var i
    var fs = 0
    if (c.length >= 0) {
      for (i = 0; i < c.length; i++) {
        fontDecrease(c[i], size)
        fs = parseInt(
          window.getComputedStyle(c[i], null).getPropertyValue('font-size')
        )
        fs = fs - (state.x - size)
        c[i].style.fontSize = fs + 'px'
      }
    }
  }

  // reading progress=====================================

  const readProg = () => {
    var winHeight = window.innerHeight,
      docHeight = document.body.scrollHeight,
      winWidth = window.innerWidth,
      doc = document.documentElement,
      max,
      value

    max = docHeight - winHeight

    value = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
    var percent = (value / max) * 100

    console.log({ value, max, winHeight, docHeight, winWidth, percent })
    setState({ ...state, readPercent: percent })
    if (value + winHeight === docHeight) {
      value = max
    }
    if (winWidth > 1500) {
      document.querySelector('.reading-progress').style.width =
        1500 * (value / max) + 'px'
    } else {
      document.querySelector('.reading-progress').style.width =
        (value / max) * 100 + '%'
    }
  }

  //to change reading content================================================================================
  const changeContent = () => {
    setState({ ...state, content: '<strong>Nice</strong>' })
    reading()
  }

  // reading percentage resume=================================================================
  // readPercent() {

  //   let winHeight = window.innerHeight,
  //     docHeight = document.body.scrollHeight,
  //     max;
  //   max = docHeight - winHeight;
  //   // console.log(max);
  //   // jq("html").animate({ scrollTop: (max / 100) * 25 }, 1500);
  // }

  //to change bg color=========================================================================================
  const bgChange = bg => {
    var classOne = $('.readingSection')
    var classTwo = $('.readingCanvas')
    var classThree = $('.sidemenuMain')
    var classFour = $('.tocParent')
    var classFive = $('.settingsMenu')
    var classSix = $('.container-fluid.NavMain.fixed-top.readersec')
    var classSeven = $('button.profileDropdown')
    var classEight = $('span.NovalName')
    var classNine = $('span.ChapterName')
    var classTen = $('button.upanyas-secondary.me-4.addToLib')
    var classEleven = $(
      '.sizeSliderParent>.css-yvszuv-Slider>div:first-of-type'
    )
    var classEleven2 = document.querySelectorAll(
      '.sizeSliderParent>.css-yvszuv-Slider>div:first-of-type'
    )[1]
    var classTwelve = $('.col-12.sidemenuBottom')
    var classThirteen = $('.readsetmodal')
    var classFourteen = $('.sideBartypeClose.close')
    var readset = $('.readSetIcon')
    var readsetM = $('.readSetIconParentMobile>.readSetIcon')
    var toc = $('.tocIcon')
    var tocM = $('.tocIconParentMobile>.tocIcon')
    var favv = $('.favIcon')
    var favvM = $('.favIconParentMobile>.favIcon')
    var sharee = $('.shareIcon')
    var commentt = $('.commentIcon')
    var commenttM = $('.commentIconParentMobile>.commentIcon')
    var notifIcon = $('.notifIcon')
    var downIcon = $('.downIcon')
    var backIcon = $('.backIcon')
    var allClasses = [
      classOne,
      classTwo,
      classThree,
      classFour,
      classFive,
      classSix,
      classSeven,
      backIcon,
      classEight,
      classNine,
      classTen,
      classEleven,
      classEleven2,
      classTwelve,
      classThirteen,
      classFourteen
    ]

    if (state.bgColor !== 'white' && bg === 'white') {
      allClasses.forEach(function (el) {
        el.classList.add(bg)
        el.classList.remove('dark', 'yellow')
      })
      readset.src = readSet
      readsetM.src = readSet
      toc.src = tocIcon
      tocM.src = tocIcon
      favv.src = fav
      favvM.src = fav
      sharee.src = share
      commentt.src = comment
      commenttM.src = comment
      notifIcon.src = Notif
      downIcon.src = Down
      backIcon.src = Back
      if (state.readSetBool) {
        $('.readSetIconParent').style.background = '#f6f6f6'
        readset.src = readSetO
      }
      setState({ ...state, bgColor: 'white' })
    } else if (state.bgColor !== 'dark' && bg === 'dark') {
      allClasses.forEach(function (el) {
        el.classList.add(bg)
        el.classList.remove('white', 'yellow')
      })
      readset.src = readSetW
      readsetM.src = readSetW
      toc.src = tocWIcon
      tocM.src = tocWIcon
      favv.src = favW
      favvM.src = favW
      sharee.src = shareW
      commentt.src = commentW
      commenttM.src = commentW
      notifIcon.src = NotifW
      downIcon.src = DownW
      backIcon.src = BackW
      if (state.readSetBool) {
        $('.readSetIconParent').style.background = '#121212'
        readset.src = readSetO
      }
      setState({ ...state, bgColor: 'dark' })
    } else if (state.bgColor !== 'yellow' && bg === 'yellow') {
      allClasses.forEach(function (el) {
        el.classList.add(bg)
        el.classList.remove('dark', 'white')
      })
      readset.src = readSetY
      readsetM.src = readSetY
      toc.src = tocYIcon
      tocM.src = tocYIcon
      favv.src = favY
      favvM.src = favY
      sharee.src = shareY
      commentt.src = commentY
      commenttM.src = commentY
      notifIcon.src = NotifY
      downIcon.src = DownY
      backIcon.src = BackY
      if (state.readSetBool) {
        $('.readSetIconParent').style.background = '#f1e5ca'
        readset.src = readSetO
      }
      setState({ ...state, bgColor: 'yellow' })
    }
  }

  const meaningViewer = () => {
    return (
      <div
        id="word-meaning"
        style={{
          position: 'absolute',
          top: state.posY,
          left: state.posX
        }}
      >
        <div className="speech-bubble">
          <span className="wmheading">Meaning</span>
        </div>
        <div className="d-flex flex-column">
          <span className="mainword">{state.word}</span>
          <span className="meaningOfWord">{state.wordMeaning}</span>
        </div>
      </div>
    )
  }

  const goBackAction = () => {
    let lib = state.inLibrary
    if (lib.in) {
      let { type } = match.params
      let percentProg = state.readPercent
      console.log(percentProg)
      if (type === 'Novel') {
        console.log('to do')
      } else {
        updateProgress(lib.id, percentProg, percentProg)
          .then(res => {
            console.log(res)
          })
          .catch(error => {
            console.log(error)
          })
      }
    }
    history.goBack()
  }

  //after component first renders============================================================================
  useEffect(() => {
    reading()

    const ref = $('.readingMaterial')

    ref.addEventListener('mouseup', textSelection, false)
    document.addEventListener(
      'mousedown',
      () => {
        // If there is already a share dialog, remove it
        if (haveMeaning()) {
          setState({
            ...state,
            word: null,
            wordMeaning: null,
            posX: null,
            posY: null
          })
        }
      },
      false
    )

    window.addEventListener('scroll', readProg)
    // window.scrollTo(0, 500);
    // window.addEventListener("load", readPercent);

    return () => {
      ref.removeEventListener('mouseup', textSelection, false)
      document.removeEventListener(
        'mousedown',
        () => {
          // If there is already a share dialog, remove it
          if (haveMeaning()) {
            setState({
              ...state,
              word: null,
              wordMeaning: null,
              posX: null,
              posY: null
            })
          }
        },
        false
      )
      window.removeEventListener('scroll', readProg)
      // window.scrollTo(0, 500);
      // window.removeEventListener("load", readPercent);
    }
  }, [])

  let title = state.title
  let chapter = state.chapterTitle
  let liked = state.isFav
  let library = state.inLibrary.in
  let isloading = state.loading

  return (
    <Fragment>
      <div className="container-fluid NavMain fixed-top readersec">
        <div className="NavRow readersec d-flex">
          <div className="titleParent col-7 d-flex align-items-center">
            <img
              onClick={goBackAction}
              className="btn backIcon"
              src={Back}
              alt="logo"
              width="40px"
            />
            <div className="container-fluid h-100">
              <div className="h-100 d-flex flex-column justify-content-center">
                <span
                  className="NovalName p-0"
                  style={{ background: 'none', border: 'none' }}
                >
                  {title}
                </span>
                <span className="ChapterName" style={{ lineHeight: '1.8rem' }}>
                  {chapter}
                </span>
              </div>
            </div>
          </div>
          <div
            className="m-auto d-flex align-items-center col-5 justify-content-end"
            style={{
              margin: '10px'
            }}
          >
            <Button
              className="me-4"
              isLoading={isloading}
              onClick={libraryAction}
            >
              {library ? 'Remove from' : 'Add to'} Library
            </Button>
            <Link to="/notifications">
              <img
                src={Notif}
                className="pe-3 display-inline notifIcon"
                alt="logo"
              />
            </Link>
            {user && (
              <Fragment>
                <Gravatar
                  className="profile rounded-circle"
                  email={user?.email}
                />
                <span className="user-name ms-2">{user?.firstName}</span>
              </Fragment>
            )}
            <Button
              type="button"
              className="ms-3"
              id="profileDropdownn"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              themeType="secondary"
              startIcon="ChevronDown"
            />
            <div
              className="dropdown-menu"
              aria-labelledby="profileDropdownn"
              id="profileDropdown"
            >
              <Link className="dropdown-item" to="/dashboard">
                <img alt="dashboard" src={dashboard} />
                Dashboard
              </Link>
              <Link className="dropdown-item" to="#">
                <img alt="profile" src={account} />
                Profile
              </Link>
              <Link className="dropdown-item" to="#">
                <img alt="logout" src={logout} />
                Logout
              </Link>
            </div>
          </div>
          <div className="dropdown mobileDropDown">
            <img
              src={Dot}
              className="menuDotType readsecdropdown"
              alt="menuDot"
              id="dropdownType"
              data-bs-toggle="dropdown"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownType">
              <Link className="dropdown-item" to="#">
                Add to library
              </Link>
              <Link className="dropdown-item" to="#">
                Dashboard
              </Link>
              <Link className="dropdown-item" to="#">
                Profile
              </Link>
              <Link className="dropdown-item" to="#">
                Logout
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative">
        <div className="reading-progress"></div>
      </div>
      <Row className="readingSection">
        <Col lg="1 sidemenu" xs="12">
          <div className="sidemenuMain">
            {chapter !== '' ? (
              <span className="tocIconParent sidemenuIcons" onClick={toctoggle}>
                <img className="iconwidth tocIcon" src={tocIcon} alt="toc" />
              </span>
            ) : (
              <Fragment></Fragment>
            )}
            <span
              className="readSetIconParent sidemenuIcons"
              onClick={() => {
                readSettoggle()
              }}
            >
              <img className="iconwidth readSetIcon" src={readSet} alt="set" />
            </span>
            <div className="settingsMenu pt-4 ps-4 pe-4">
              <h5 className="font-weight-bold text-center"> Options </h5>
              <div className="sizeSliderParent">
                <label htmlFor="sizeSlider"> Text Size </label>
                <Slider
                  axis="x"
                  xstep={1}
                  xmin={1}
                  xmax={10}
                  x={state.x}
                  onChange={({ x }) => fontSizeChange(x)}
                />
              </div>
              <div className="bgParent">
                <label> Background Color </label>
                <Row>
                  <Col className="text-center">
                    <button
                      className="whitebg"
                      onClick={() => {
                        bgChange('white')
                      }}
                    ></button>
                  </Col>
                  <Col className="text-center">
                    <button
                      className="darkbg"
                      onClick={() => {
                        bgChange('dark')
                      }}
                    ></button>
                  </Col>
                  <Col className="text-center">
                    <button
                      className="yellowbg"
                      onClick={() => {
                        bgChange('yellow')
                      }}
                    ></button>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="float-bottom">
              <span
                className="favIconParent sidemenuIcons"
                onClick={likedAction}
              >
                <img
                  className="iconwidth favIcon"
                  src={liked ? heart : fav}
                  alt="set"
                />
              </span>
              <span className="shareIconParent sidemenuIcons">
                <img className="iconwidth shareIcon" src={share} alt="set" />
              </span>
              <span className="commentIconParent sidemenuIcons">
                <img
                  className="iconwidth commentIcon"
                  src={comment}
                  alt="set"
                />
              </span>
            </div>
          </div>
        </Col>
        <Col lg="4" xl="3" xs="11" className="tocParent">
          <div className="tocTable">
            <span className="tocheading"> Table of Content </span>
            <div className="tocitems">
              <span className="tocitem active"> 1. The Plot </span>
              <span className="tocitem" onClick={changeContent}>
                2. The Plot
              </span>
            </div>
            <button
              type="button"
              className="close sideBartypeClose"
              aria-label="Close"
              onClick={toctoggle}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </Col>
        <Col lg="11 offset-lg-1" xs="12">
          <Row className="readingCanvasRow">
            <Col lg="10 offset-lg-1 readingCanvas position-relative" xs="12">
              <div
                className="readingMaterial"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(state.content)
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col xs="12 sidemenuBottom">
          <div className="container-fluid h-100">
            <Row className="h-100">
              <Col className="h-100" onClick={toctoggle}>
                <span className="tocIconParentMobile">
                  <img className="iconwidth tocIcon" src={tocIcon} alt="toc" />
                </span>
              </Col>
              <Col data-bs-toggle="modal" data-target="#exampleModal">
                <span className="readSetIconParentMobile">
                  <img
                    className="iconwidth readSetIcon"
                    src={readSet}
                    alt="set"
                  />
                </span>
              </Col>
              <Col>
                <span className="favIconParentMobile">
                  <img className="iconwidth favIcon" src={fav} alt="set" />
                </span>
              </Col>
              <Col>
                <span className="commentIconParentMobile">
                  <img
                    className="iconwidth commentIcon"
                    src={comment}
                    alt="set"
                  />
                </span>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {haveMeaning() && meaningViewer()}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content readsetmodal">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Options
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="sizeSliderParent">
                <label htmlFor="sizeSlider" className="sizeSliderLabel">
                  Text Size
                </label>
                <Slider
                  axis="x"
                  xstep={1}
                  xmin={1}
                  xmax={10}
                  x={state.x}
                  onChange={({ x }) => fontSizeChange(x)}
                />
              </div>
              <div className="bgParent">
                <label className="bgLabel"> Background Color </label>
                <Row>
                  <Col className="text-center">
                    <button
                      className="whitebg"
                      onClick={() => {
                        bgChange('white')
                      }}
                    ></button>
                  </Col>
                  <Col className="text-center">
                    <button
                      className="darkbg"
                      onClick={() => {
                        bgChange('dark')
                      }}
                    ></button>
                  </Col>
                  <Col className="text-center">
                    <button
                      className="yellowbg"
                      onClick={() => {
                        bgChange('yellow')
                      }}
                    ></button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

Reader.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
}

export default Reader
