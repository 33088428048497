import axios from 'axios'

function NetworkCall(request, callback) {
  axios(request)
    .then(result => {
      callback(result.data, null)
    })
    .catch(err => {
      switch (err.response.status) {
        case 404:
          console.log('Not Found')
          break
        case 403 || 401:
          console.log('Need to login')
          break
        case 500:
          console.log('enternal server error')
          break
        default:
          break
      }
      if (err.response) {
        callback(null, err.response.data)
      } else {
        callback(null, err)
      }
    })
}

export default NetworkCall
