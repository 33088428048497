import { Button, Input, Select, Tabs } from 'mtrd'
import { useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import ContentDetailsCard from 'components/ContentDetails/ContentDetailsCard'
import Cross from 'Icons/x-circle.svg'

const Search = () => {
  const tabs = [
    {
      id: '1',
      label: 'All',
      value: 'all'
    },
    {
      id: '2',
      label: 'Novels',
      value: 'novel'
    },
    {
      id: '3',
      label: 'Poems',
      value: 'poem'
    },
    {
      id: '4',
      label: 'Stories',
      value: 'story'
    }
  ]
  const [activeTab, setActiveTab] = useState(tabs[0])
  const [selectedFilter, setSelectedFilter] = useState('popular')
  const books = [
    {
      id: 1,
      link: '#',
      cover: '',
      name: "Harry Potter and the Philosopher's Stone",
      author: 'JK Rowling',
      info: "It is a story about Harry Potter, an orphan brought up by his aunt and uncle because his parents were killed when he was a baby. Harry is unloved by his uncle and aunt but everything changes when he is invited to join Hogwarts School of Witchcraft and Wizardry and he finds out he's a wizard.",
      reads: {
        length: 3
      },
      ratings: 4,
      type: 'novel'
    },
    {
      id: 2,
      link: '#',
      cover: '',
      name: "Harry Potter and the Philosopher's Stone",
      author: 'JK Rowling',
      info: "It is a story about Harry Potter, an orphan brought up by his aunt and uncle because his parents were killed when he was a baby. Harry is unloved by his uncle and aunt but everything changes when he is invited to join Hogwarts School of Witchcraft and Wizardry and he finds out he's a wizard.",
      reads: {
        length: 3
      },
      ratings: 4,
      type: 'poem'
    }
  ]

  const authors = [
    {
      id: 1,
      name: 'JK Rowling',
      username: 'jkrowling',
      avatar: 'https://via.placeholder.com/54'
    },
    {
      id: 2,
      name: 'JK Rowling',
      username: 'jkrowling',
      avatar: 'https://via.placeholder.com/54'
    }
  ]

  return (
    <>
      <div className="content"></div>
      <div className="max-width mx-auto">
        <div className="mx-auto search-page-bar-parent">
          <Input className="search-page-bar mx-auto" />
          <img src={Cross} alt="Clear search bar" />
        </div>
        <div className="search-page-title-tabs text-center mt-5">
          <h5>Search result for &quot;dad&quot;</h5>
          <h6>88 results found</h6>
          <Tabs
            tabs={tabs}
            tabClassName={' mx-auto mt-5'}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
        <Container fluid className="mt-5">
          <Row>
            <Col xs="12" lg="8">
              <div className="books-header d-flex justify-content-between align-items-center pb-3">
                <p className="mb-0">BOOKS</p>
                <Select
                  className="search-filter"
                  placeholder="Select a language"
                  options={[
                    {
                      type: 'option',
                      label: 'Popular',
                      value: 'popular'
                    },
                    {
                      type: 'option',
                      label: 'Trending',
                      value: 'trending'
                    }
                  ]}
                  value={selectedFilter}
                  name="popular"
                  setFieldValue={setSelectedFilter}
                />
              </div>
              <div className="">
                {books.length > 0 ? (
                  books
                    .filter(book =>
                      activeTab.value === 'all'
                        ? true
                        : activeTab.value === book.type
                    )
                    .map(book => (
                      <ContentDetailsCard key={book.id} item={book} />
                    ))
                ) : (
                  <div className="mt-5 no-books text-center">
                    <h4 fw-bold>No results found</h4>
                    <p className="text-black-50 small">
                      Sorry, we couldn&apos;t find any content
                    </p>
                  </div>
                )}
              </div>
            </Col>
            <Col xs="12" lg="4" className="px-5">
              <div className="books-header d-flex justify-content-between align-items-center pb-3">
                <p className="my-2">AUTHORS</p>
              </div>
              <div className="authors-list mt-4">
                {authors.length > 0 ? (
                  authors.map(author => (
                    <div
                      className="py-3 px-2 author-card d-flex justify-content-between align-items-center"
                      key={author.id}
                    >
                      <div className="author-info d-flex align-items-center">
                        <img
                          className="me-2"
                          src={author.avatar}
                          alt="Author avatar"
                        />
                        <div className="author-name-and-username">
                          <p className="author-name">{author.name}</p>
                          <p className="author-username">@{author.username}</p>
                        </div>
                      </div>
                      <div className="follow-button">
                        <Button
                          className="px-0 py-2"
                          startIcon="Plus"
                          themeType="secondary"
                        >
                          Follow
                        </Button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-black-50 small no-author text-center ">
                    There is no author with this name.
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Search
