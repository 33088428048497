import { isEmpty } from 'lodash'
import { ComponentProps, FC, useState } from 'react'

interface ImageProps extends ComponentProps<'img'> {
  srcs: string[] | string
  fallback?: string
}

const Image: FC<ImageProps> = ({ srcs, fallback, ...props }) => {
  let imageSrcs = srcs
  let hasFallback = false

  if (isEmpty(srcs)) imageSrcs = []
  else if (!Array.isArray(srcs)) imageSrcs = [srcs]

  if (!hasFallback && Array.isArray(imageSrcs) && fallback) {
    imageSrcs.push(fallback)
    hasFallback = true
  }

  const [currentIdx, setCurrentIdx] = useState(0)
  const [imgSrc, setImgSrc] = useState(imageSrcs[currentIdx])

  const changeSrc = () => {
    if (imageSrcs.length >= currentIdx + 1) {
      setImgSrc(imageSrcs[currentIdx + 1])
      setCurrentIdx(currentIdx + 1)
    }
  }

  return (
    <img
      alt={props.alt}
      src={imgSrc}
      onError={changeSrc}
      key={imgSrc}
      {...props}
    />
  )
}

export default Image
