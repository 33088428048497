import logo from 'Images/logoWtext.svg'
import { Col, Container, Row } from 'reactstrap'
import SidebarAdmin from './SidebarAdmin'
import { Button, Input } from 'mtrd'
import 'Style/admin/category.scss'

const Category = () => (
  <Container fluid className="adminside category">
    <Row className="h-100">
      <Col xs="12" lg="3" xl="2" className="adminsidebar">
        <div className="logo">
          <img src={logo} alt="Upanyas" />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <SidebarAdmin />
      </Col>
      <Col xs="12" lg="9" xl="10" className="bg-light">
        <div className="pageHead mb-4">
          <h4>Category</h4>
        </div>
        <Row>
          <Col xs="12" lg="4">
            <div className="card border-0 py-3 px-4">
              <div className="cardHead">
                <h5>Add New Category</h5>
              </div>
              <form action="#" className="catUp mt-3">
                <div className="mb-3">
                  <Input required placeholder="Name of Category" name="name" />
                </div>
                <div className="mb-3">
                  <Input placeholder="Content Type" name="for" />
                </div>
                <div className="mb-3">
                  <Input required placeholder="Language" name="language" />
                </div>
                <div className="mb-3">
                  <Input required type="file" name="image" />
                </div>
                <div className="mb-3">
                  <Button type="submit">Submit </Button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
)

export default Category
