import Dot from 'Icons/menu-dotOrange.svg'
import { Row, Col } from 'reactstrap'
import { Button } from 'mtrd'
import { Dispatch, ReactText, SetStateAction } from 'react'

const TypewriterNavBar = ({
  setContentInfo,
  contentInfo,
  saveDraftBtn,
  publishDraft,
  tableofc
}: {
  setContentInfo: Dispatch<
    SetStateAction<{
      name: string
      writeupId: null
      lastSaved: string
    }>
  >
  contentInfo: {
    name: string
    writeupId: null
    lastSaved: string
  }
  saveDraftBtn: () => Promise<ReactText | undefined> | undefined
  publishDraft: () => void
  tableofc: () => void
}) => {
  return (
    <div className="container-fluid NavMain fixed-top">
      <Row className="NavRow typewriter">
        <Col xs="1 m-auto text-center backbtntype">
          <Button
            className="bg-white text-black"
            startIcon="ChevronLeft"
            onClick={() => window.history.back()}
            iconCustomSize="30"
          />
        </Col>
        <Col xs="11" lg="5" className="col1011 h-100">
          <div className="container-fluid h-100">
            <div className="h-100 d-flex flex-column justify-content-center">
              <input
                className="NovalName w-100"
                id="writeuptitle"
                onChange={e =>
                  setContentInfo({ ...contentInfo, name: e.target.value })
                }
                autoComplete="off"
                value={contentInfo.name}
              />
              <span
                className="lastSaved ChapterName"
                style={{ lineHeight: '1.8rem' }}
              >
                {contentInfo.lastSaved}
              </span>
            </div>
          </div>
        </Col>
        <Col xs="12" lg="6">
          <div className="pubPreParent display-flex">
            <Button
              themeType="outlined"
              className="saveDraftBtn"
              onClick={saveDraftBtn}
            >
              Save
            </Button>
            <Button onClick={publishDraft}>Publish</Button>
          </div>
        </Col>
        <img
          src={Dot}
          className="menuDotType"
          alt="menuDot"
          id="dropdownType"
          data-bs-toggle="dropdown"
        />
        <div className="dropdown-menu" aria-labelledby="dropdownType">
          <button className="dropdown-item" onClick={tableofc}>
            Table of Content
          </button>
          <button className="dropdown-item">
            <span className="SaveSpan" onClick={saveDraftBtn}>
              Save
            </span>
          </button>
          <button className="dropdown-item">
            <span className="publishSpan" onClick={publishDraft}>
              Publish
            </span>
          </button>
        </div>
      </Row>
    </div>
  )
}

export default TypewriterNavBar
