import { AlertCircle, FileText } from 'react-feather'
import { MutableRefObject, useState } from 'react'
import { nanoid } from 'nanoid'
import ChaptersList from './ChaptersList'
import { NOVEL } from 'utils'
import DetailsSection from './DetailsSection'

const TypewriterSidebar = ({
  chaptersListRef,
  chapters,
  activeChapterID,
  setChapterContentToEditor,
  renameChapterModalToggle,
  addChapterToNovel,
  isAddingChapter,
  type,
  details,
  contentInfo
}: {
  chaptersListRef: MutableRefObject<any>
  chapters: any
  activeChapterID: string
  setChapterContentToEditor: (id: any) => void
  renameChapterModalToggle: (id: any, name: any) => void
  addChapterToNovel: () => void
  isAddingChapter: boolean
  type: string
  details: any
  contentInfo: {
    name: string
    writeupId: null
    lastSaved: string
  }
}) => {
  const [options] = useState([
    {
      id: nanoid(),
      Icon: FileText,
      name: 'chapter',
      hidden: type !== NOVEL
    },
    {
      id: nanoid(),
      Icon: AlertCircle,
      name: 'details'
    }
  ])

  const tableofcClose = () => {
    document.querySelector<HTMLElement>('.sideChapterbar')!.style.transform =
      'translateX(-100%)'
  }

  const [optionToggle, setOptionToggle] = useState({
    name: type === NOVEL ? 'chapter' : 'details',
    isOpen: true
  })

  const handleOptionToggle = (name: string) =>
    setOptionToggle({
      name: optionToggle.name === name ? '' : name,
      isOpen: optionToggle.name === name ? !optionToggle.isOpen : true
    })

  const contentToShowOnSideBar = (optionName: string) => {
    switch (optionName) {
      case 'chapter':
        return (
          <ChaptersList
            activeChapterID={activeChapterID}
            chapters={chapters}
            chaptersListRef={chaptersListRef}
            renameChapterModalToggle={renameChapterModalToggle}
            setChapterContentToEditor={setChapterContentToEditor}
            addChapterToNovel={addChapterToNovel}
            isAddingChapter={isAddingChapter}
          />
        )
      case 'details':
        return (
          <DetailsSection
            details={details}
            contentInfo={contentInfo}
            // chaptersList={chapters}
          />
        )

      default:
        break
    }
  }

  return (
    <div
      className={`sidebar w-100 h-100 d-flex ${
        optionToggle.isOpen ? 'open' : 'close'
      }`}
    >
      <div className="sidebarOptions bg-white">
        {options
          .filter(({ hidden }) => !hidden)
          .map(({ name, id, Icon }) => (
            <div
              key={id}
              onClick={() => handleOptionToggle(name)}
              className={`option ${
                optionToggle.name === name ? 'active' : 'inactive'
              } d-flex align-items-center justify-content-around`}
            >
              <Icon />
            </div>
          ))}
      </div>
      <div
        className={`sidebarContent bg-white  ${
          optionToggle.isOpen ? 'open' : 'close'
        }`}
      >
        <button
          type="button"
          className="close sideBartypeClose"
          aria-label="Close"
          onClick={tableofcClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        {contentToShowOnSideBar(optionToggle.name)}
      </div>
    </div>
  )
}

export default TypewriterSidebar
