const Endpoints = {
  register: '/api/user/register',
  login: '/api/user/login',
  authManger: '/api/user/auth-service',
  OTPrestPassword: '/api/user/reset-password',
  ForgotPassword: '/api/user/forgot-password',
  CreateNovel: '/api/novel/create',
  getAllNovel: '/api/novel/getAll',
  getNovelsByUser: '/api/user/getAllNovels',
  getNovelById: '/api/novel/get/{id}',
  CreateChapter: '/api/novel/addChapter/{id}',
  updateChapter: '/api/chapter/update/{id}',
  removeChapter: '/api/novel/removeChapter/{id}',
  updateChapterOrder: '/api/novel/updateArrangement/{id}',
  publishNovel: '/api/novel/publish/{id}',
  deleteNovelByUser: '/api/novel/softDelete/{id}',
  CreateVolume: '/api/novel/volume/create/5f23b78ac59d7011096208ac',
  getAllVolume: '/api/novel/volume/getAll',
  getAllChapter: '/api/novel/volume/chapter/getAll',
  getPublishedNovelById: '/api/novel/getPublished/{id}',
  forgotPasword: '/api/user/forgot-password',
  page: '/api/page/v1',
  allCategory: '/api/category/getAll',
  listCategories: '/api/category/list',
  restPassword: '/api/user/reset-password',
  restPasswordVerifyToken: '/forgot-password/verify/{id}',
  requestUserByToken: '/api/user/user-details',
  refreshToken: '/api/user/refresh_token',
  logout: '/api/user/logout',
  uploadCoverImage: '/api/novel/uploadCover',
  createStory: '/api/story/create',
  updateStory: '/api/story/updateContent/{id}',
  getStoriesByUser: '/api/user/getAllStories',
  savePoemDetails: '/api/poem/update/{id}',
  getStoryById: '/api/story/get/{id}',
  getPublishedStoryById: '/api/story/getPublished/{id}',
  saveStoryDetails: '/api/story/update/{id}',
  deleteStoryByUser: '/api/story/softDelete/{id}',
  publishStory: '/api/story/publish/{id}',
  createPoem: '/api/poem/create',
  updatePoem: '/api/poem/updateContent/{id}',
  getPoemsByUser: '/api/user/getAllPoems',
  addToLibrary: '/api/library/add',
  removeFromLibrary: '/api/library/remove/{id}',
  getLibrary: '/api/library/getBooks',
  getLastRead: '/api/library/getLastRead',
  getPoemById: '/api/poem/get/{id}',
  getPublishedPoemById: '/api/poem/getPublished/{id}',
  deletePoemByUser: '/api/poem/softDelete/{id}',
  publishPoem: '/api/poem/publish/{id}',
  updateProgerss: '/api/library/saveProgress/{id}',
  comment: '/api/comments/create',
  like: '/api/like/handle',
  addImage: '/api/image/add',
  removeImage: '/api/image/remove',
  language: '/api/language/list',
  listing: '/api/listing/'
}

export default Endpoints
