import { DownloadCloud, Edit2, MoreHorizontal, Trash2 } from 'react-feather'
import { Button } from 'mtrd'
import moment from 'moment'
import { Chapter } from 'types/Chapter'

const ChaptersList = ({
  chaptersListRef,
  chapters,
  activeChapterID,
  setChapterContentToEditor,
  renameChapterModalToggle,
  addChapterToNovel,
  isAddingChapter
}: {
  chaptersListRef: any
  chapters: {
    chapter: Chapter
  }[]
  activeChapterID: string
  setChapterContentToEditor: (id: any) => void
  renameChapterModalToggle: (id: any, name: any) => void
  addChapterToNovel: () => void
  isAddingChapter: boolean
}) => {
  return (
    <div
      className={`chapterNameParent  h-100 toggle d-flex flex-column justify-content-between`}
    >
      <div className="chapterListContainer">
        <div className="sidebarHeading d-flex align-items-center justify-content-between ps-3">
          <h5 className="font-weight-bold mb-0">Chapters</h5>
          <div className="btnGroup">
            <Button
              startIcon="Plus"
              className="fw-normal py-0"
              small
              onClick={addChapterToNovel}
              disabled={isAddingChapter}
            >
              Add
            </Button>

            <Button
              startIcon="MoreHorizontal"
              themeType="outlined"
              className="bg-transparent border-0 ms-2"
              small
              id="chapterDropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            ></Button>
            <div
              className="dropdown-menu py-1 border-0"
              aria-labelledby="chapterDropdown"
            >
              <div className="chapter-dropdown-item px-3 dropdown-item d-flex align-items-center fw-500 border-bottom">
                <Edit2 size={16} className="me-2" />
                Edit
              </div>
              <div className="chapter-dropdown-item px-3 dropdown-item d-flex align-items-center fw-500 border-bottom">
                <DownloadCloud size={16} className="me-2" />
                Unpublish
              </div>
              <div className="chapter-dropdown-item px-3 dropdown-item d-flex align-items-center fw-500 mtrd-text-primary">
                <Trash2 size={16} className="me-2" />
                Delete
              </div>
            </div>
          </div>
        </div>
        <div className="chaptersList" ref={chaptersListRef}>
          {chapters.map(
            ({ chapter: { chapterID, name, isPublished, updatedAt } }) => (
              <div
                data-key={chapterID}
                key={chapterID}
                className={
                  (activeChapterID === chapterID ? 'active' : '') +
                  ' chapterName drag-item py-3 d-flex px-3 flex-column justify-content-between'
                }
                onClick={() => setChapterContentToEditor(chapterID)}
              >
                <div className="d-flex flex-column">
                  <div className="chapter-name-and-options d-flex justify-content-between align-items-center w-100">
                    <div className="d-flex">
                      {/* <span className="number">{item.index}</span> */}
                      {name}
                    </div>

                    <MoreHorizontal
                      size={18}
                      color={'#222222'}
                      className="py-1 ps-2 more-chapter-button"
                      id={`more-chapter-${chapterID}`}
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    />
                    <div
                      className="dropdown-menu more-chapter-menu border-0"
                      aria-labelledby={`more-chapter-${chapterID}`}
                    >
                      <div
                        className="dropdown-item fw-500 more-chapter-menu-item border-bottom"
                        onClick={() =>
                          renameChapterModalToggle(chapterID, name)
                        }
                      >
                        Rename
                      </div>
                      <div className="dropdown-item fw-500 more-chapter-menu-item mtrd-text-primary">
                        Unpublish
                      </div>
                      <div className="delete-chapter-container px-3 mt-2">
                        <Button
                          themeType="secondary"
                          className="mtrd-tag fw-500 py-1 w-100"
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="content-excerpt mt-1">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Totam perferendis lab...
                  </div>
                </div>

                <div className="publish-status">
                  {isPublished ? (
                    <>Published - {moment(updatedAt).fromNow()}</>
                  ) : (
                    <>Not Published</>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>
      {/* <div className="infoAndButton d-flex justify-content-between">
              <button
                className="mtrd-button"
                onClick={addChapterToNovel}
                disabled={isAddingChapter}
              >
                <PlusCircle /> Add Chapter
              </button>
              <Button startIcon="Edit2" onClick={openChaptersModal}>
                Manage Chapters
              </Button>
            </div> */}
    </div>
  )
}

export default ChaptersList
