import { FC, useContext, useEffect, useState } from 'react'
import MediaQuery from 'react-responsive'
import NotifN from 'Icons/notifN.svg'
import NotifW from 'Icons/notifW.svg'
import { logoutUser } from 'NetworkCall/RepoHandler/userDataService'
import WriteModal from '../../Modal/WriteModal'
import WhiteImg from 'Icons/upanyas-new-logo.svg'
import DarkImg from 'Icons/upanyas-new-logo-dark-mode.svg'
import { Link, NavLink, withRouter } from 'react-router-dom'
import { Button } from 'mtrd'
import { UserContext } from 'context/UserDetailsContext'
import { ChevronDown } from 'react-feather'
import XCircleGray from 'Icons/x-circle-gray.svg'
import ProfileDropdown from './ProfileDropdown'
import { UserDetails } from 'types/User'
import * as H from 'history'
import 'Style/navbar.scss'

const ProfileSection: FC<{
  value: UserDetails | null
  logoutAction: () => void
  navTheme: string
}> = ({ value, logoutAction, navTheme }) =>
  value ? (
    <>
      <Link to="/notifications">
        <img
          src={navTheme === '' ? NotifN : NotifW}
          className="pe-3 display-inline"
          alt="logo"
          width="36px"
        />
      </Link>
      <ProfileDropdown value={value} logoutAction={logoutAction} />
    </>
  ) : (
    <Button href="/user?page=login">Sign In</Button>
  )

const Navbar: FC<{
  location: H.Location
}> = ({ location }) => {
  const [navTheme, setNavTheme] = useState('bg-transparent')
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const user = useContext(UserContext)
  const [isSearchBarFocused, setIsSearchBarFocused] = useState(false)

  useEffect(() => {
    const changeNavTheme = () => {
      const doc = document.documentElement
      const st = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
      if (location.pathname === '/') {
        if (document.querySelector('.heading-box')) {
          if (
            st <=
            document.querySelector<HTMLElement>('.heading-box')!.offsetHeight -
              80
          )
            return setNavTheme('bg-transparent')
          return setNavTheme('')
        }
        return setNavTheme('bg-transparent')
      }
      return setNavTheme('')
    }
    changeNavTheme()

    window.addEventListener('scroll', changeNavTheme)

    return () => window.removeEventListener('scroll', changeNavTheme)
  }, [location.pathname])

  const logout = () => {
    localStorage.removeItem('auth-token')
    localStorage.removeItem('RfToken')
    const request = logoutUser()
      .then(() => {
        window.open(
          window.location.protocol + '//' + window.location.host + '/',
          '_self'
        )
      })
      .catch((error: any) => {
        request.cancel()
        console.log(error)
      })
  }

  useEffect(() => {
    window.addEventListener('click', (e: MouseEvent) => {
      !e.composedPath().find(p => {
        if (p instanceof HTMLElement)
          return (
            p.className?.includes('search-suggestions') ||
            p.className?.includes('seacrhbox')
          )
        return false
      }) && setIsSearchBarFocused(false)
    })
  }, [])

  const LogoAndSearch = () => {
    const [searchValue, setSearchValue] = useState('')

    return (
      <div className="logo-and-search-bar-container d-flex align-items-center">
        <div className="logo-and-search-bar-container-item"></div>
        <div className="NavLogo">
          <Link to="/">
            <img src={navTheme === '' ? WhiteImg : DarkImg} alt="logo" />
          </Link>
        </div>
        <div className="search-bar-container position-relative">
          <input
            type="text"
            name="value"
            autoFocus={isSearchBarFocused}
            onChange={e => {
              setSearchValue(e.target.value)
            }}
            onFocus={() => setIsSearchBarFocused(true)}
            placeholder={'Search Novels, Stories, Poems'}
            className="seacrhbox w-100 h-100"
            autoComplete="off"
            value={searchValue}
          />

          {isSearchBarFocused && (
            <div
              className={`search-suggestions w-100 bg-white pt-2 pb-3 position-absolute show`}
            >
              <p className="mb-0 heading px-3 py-2 mb-3">Recent Searches</p>
              <div className="recent-searches-item d-flex mx-3 justify-content-between rounded">
                Harry Potter <img src={XCircleGray} alt="close" />
              </div>
              <div className="recent-searches-item d-flex mx-3 justify-content-between rounded">
                Harry Potter <img src={XCircleGray} alt="close" />
              </div>
              <div className="recent-searches-item d-flex mx-3 justify-content-between rounded">
                Harry Potter <img src={XCircleGray} alt="close" />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  const LinksAndDropdown = () => {
    return (
      <div className="d-flex m-0 align-items-center">
        <div className="nav-menu-items me-5">
          <MediaQuery minDeviceWidth={769}>
            <ul className="NavUl">
              <li className="NavLink">
                <NavLink to="/category">
                  Categories <ChevronDown size={18} />
                </NavLink>
              </li>
              <li className="NavLink writeModalTrigger ms-5" onClick={toggle}>
                Write
              </li>
            </ul>
          </MediaQuery>
        </div>
        <div className="display-flex align-items-center">
          <ProfileSection
            value={user}
            logoutAction={logout}
            navTheme={navTheme}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <div
        className={`container-fluid NavMain fixed-top laptopview ${navTheme}`}
      >
        <div className="NavRow d-flex">
          <LogoAndSearch />
          <LinksAndDropdown />
        </div>
      </div>

      <WriteModal modal={modal} toggle={toggle} />

      {isSearchBarFocused && <div className="search-backdrop"></div>}
    </>
  )
}

export default withRouter(Navbar)
