import RequestHandler from '../Connetion/RequestBulider'
import endpoint from '../Connetion/endpoints'
import { Promise } from 'bluebird'
import { rejectResolveNetwork } from './commonDataService'

Promise.config({ cancellation: true })

export const pagesApi = pathName => {
  let request = RequestHandler.getRequest(
    endpoint.page,
    [['path', pathName]],
    RequestHandler.getMethod.get,
    false
  )
  return new Promise((resolve, reject) =>
    rejectResolveNetwork(request, reject, resolve)
  )
}
