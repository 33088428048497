import { Fragment } from 'react'
import PrivateRoute from './components/Auth/PrivateRoute'
import Typewriter from './components/Typewriter'
import Login from './screen/Login'
import Home from './screen/Home'
import Library from './screen/Library'
import Navbar from './components/Common/Navbar'
import RestPassword from './screen/RestPassword'
import Dashboard from './screen/Dashboard'
import Notification from './screen/Notification'
import Reader from './screen/Reader'
import PublishForm from './components/PublishForm'
import Profile from './screen/Profile'
import WriterSection from './screen/WriterSection'
import Category from './screen/admin/Category/Category'
import ContentDetails from './components/ContentDetails'
import Search from './screen/Search'
import { Route, Switch, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { animated, useTransition } from 'react-spring'
import './Style/navbar.scss'
import './Style/footer.scss'
import './App.scss'
import 'react-toastify/dist/ReactToastify.css'
import './Style/searchbar.scss'

const PageRouter = () => {
  const location = useLocation()
  const transitions = useTransition(location, location => location.pathname, {
    from: {
      opacity: 0
    },
    enter: {
      opacity: 1
    },
    leave: {
      opacity: 0
    }
  })

  return transitions.map(({ item, props, key }) => (
    <animated.div style={props} key={key}>
      <ToastContainer closeOnClick />
      <Switch location={item}>
        <Route exact path="/user" component={Login} />
        <Route exact path="/typewriter/:type/:id" component={Typewriter} />
        <PrivateRoute component={Dashboard} path="/dashboard" exact />
        <Route exact path="/typewriter/:type" component={Typewriter} />
        <Route exact path="/restpassword/:id" component={RestPassword} />
        <PrivateRoute component={Library} path="/library" exact />
        <PrivateRoute component={WriterSection} path="/writer" exact />
        <Route exact path="/reader/:type/:id" component={Reader} />
        <PrivateRoute component={Notification} path="/notifications" exact />
        <Route exact path="/detail/:type/:id" component={ContentDetails} />
        <PrivateRoute component={Profile} path="/Profile" exact />
        <Route exact path="/publishform/:type/:id" component={PublishForm} />
        <Route exact path="/admin/category" component={Category} />
        <Route exact path="/search" component={Search} />
        <Route
          exact
          path={['/:path', '/:path/:subpath', '/']}
          component={Home}
        />
      </Switch>
    </animated.div>
    // );
  ))
}

const App = () => {
  const { pathname } = useLocation()

  return (
    <Fragment>
      <div className={`containers ${pathname.split('/')[1]}`}>
        <Switch>
          <Route
            exact
            path={[
              '/user',
              '/typewriter/:type',
              '/restpassword/:id',
              '/reader',
              '/Publishform',
              '/writer',
              '/publishForm/:type',
              '/admin/category'
            ]}
          ></Route>
          <Route
            exact
            path={[
              '/library',
              '/search',
              '/writer ',
              '/detail/:type/:id',
              '/',
              '/:path/:subpath',
              '/:path'
            ]}
          >
            <Navbar />
          </Route>
        </Switch>
        <PageRouter />
      </div>
    </Fragment>
  )
}

export default App
