import { Row, Col } from 'reactstrap'
import './JumboSection.scss'

const HeadingBanner = () => (
  <div className="heading-box">
    <Row className="max-width h-100 m-auto">
      <Col lg="6">
        <div className="jumboContent d-flex h-100 flex-column justify-content-center">
          <p>The culture of a region is empowered by the lingual texts.</p>
        </div>
      </Col>
    </Row>
  </div>
)

export default HeadingBanner
