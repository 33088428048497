import { Link, NavLink } from 'react-router-dom'

const SidebarAdmin = () => (
  <div className="sideMenu">
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link className="nav-link" to="/admin/dashboard">
            Dashboard
          </Link>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link"
            to="/admin/category"
            activeClassName="active"
          >
            Category
          </NavLink>
        </li>
      </ul>
    </div>
  </div>
)

export default SidebarAdmin
