const NotificationCard = ({
  title,
  content
}: {
  title: string
  content: string
}) => (
  <div className="notifCard mb-4">
    <div className="card alert w-100 fade show" role="alert">
      <button
        type="button"
        className="close notifClose"
        aria-label="Close"
        data-dismiss="alert"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <p style={{ marginRight: '35px' }}>
        <strong className="notifTitle">&apos;{title}&apos; </strong>
        <span className="notifDetail">{content}</span>
      </p>
      <span className="notifDate mt-3">about 21 hours ago</span>
    </div>
  </div>
)

export default NotificationCard
